import { z } from "zod";


export const postApiTicketingTableTierDeleteHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTicketingTableTierDelete200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not avaible
 */
export const postApiTicketingTableTierDelete403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTicketingTableTierDeleteMutationRequestSchema = z.object({ "team_id": z.number(), "id": z.number() });

 /**
 * @description Success
 */
export const postApiTicketingTableTierDeleteMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const getApiBackofficeActivationGetActivableActivePathParamsSchema = z.object({ "activable": z.string().describe("Activable_type to search for"), "active": z.string().describe("Optional parameter. bool optional Activable state to search for") });


export const getApiBackofficeActivationGetActivableActiveHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiBackofficeActivationGetActivableActive200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const getApiBackofficeActivationGetActivableActive403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiBackofficeActivationGetActivableActiveQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiTransportVehicleAvailabilityHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportVehicleAvailability200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const postApiTransportVehicleAvailability403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportVehicleAvailabilityMutationRequestSchema = z.object({ "id": z.number(), "ncc_team_id": z.number(), "available": z.boolean() });

 /**
 * @description Success
 */
export const postApiTransportVehicleAvailabilityMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { z } from "zod";


export const postApiBackofficeTagCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeTagCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeTagCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeTagCreateMutationRequestSchema = z.object({ "name": z.string().describe("Must not be greater than 32 characters."), "taggable_type": z.string().describe("Must be one of <code>Event</code> Must not be greater than 32 characters.") });

 /**
 * @description Success
 */
export const postApiBackofficeTagCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiTransportRideEditHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportRideEdit200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).optional() });

 /**
 * @description Forbidden
 */
export const postApiTransportRideEdit403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "error": z.string().optional() });

 /**
 * @description Real Distance Calculation Failed
 */
export const postApiTransportRideEdit404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "error": z.string().optional() });


export const postApiTransportRideEditMutationRequestSchema = z.object({ "id": z.number(), "to_address": z.string().describe("Must not be greater than 100 characters."), "to_latitude": z.string().describe("To latitude"), "to_longitude": z.string().describe("To longitude"), "used_seats": z.number().describe("Must be between 1 and 128.") });

 /**
 * @description Success
 */
export const postApiTransportRideEditMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).optional() });
import { type QueryKey, type QueryObserverOptions, type UseQueryResult, useQuery } from "@tanstack/react-query";
import axiosClient from "api/templatesSDK/client";
import type { GetApiTransportNccTeamRideListFiltered } from "./_types";

const getApiTransportNccTeamRideListFilteredQueryKey = () => ["/api/transport/ncc-team/ride/list/filtered"] as const;
type GetApiTransportNccTeamRideListFilteredQueryKey = ReturnType<typeof getApiTransportNccTeamRideListFilteredQueryKey>;

export function useGetApiTransportNccTeamRideListFilteredHook<
	TData = GetApiTransportNccTeamRideListFiltered["response"],
	TQueryData = GetApiTransportNccTeamRideListFiltered["response"],
	TQueryKey extends QueryKey = GetApiTransportNccTeamRideListFilteredQueryKey,
>(
	ncc_team_id: GetApiTransportNccTeamRideListFiltered["pathParams"]["ncc_team_id"],
	states: GetApiTransportNccTeamRideListFiltered["pathParams"]["states"],
	options: {
		query?: Partial<
			QueryObserverOptions<
				GetApiTransportNccTeamRideListFiltered["response"],
				GetApiTransportNccTeamRideListFiltered["error"],
				TData,
				TQueryData,
				TQueryKey
			>
		>;
		client?: GetApiTransportNccTeamRideListFiltered["client"]["parameters"];
	} = {},
): UseQueryResult<TData, GetApiTransportNccTeamRideListFiltered["error"]> & {
	queryKey: TQueryKey;
} {
	const finalStateString = states.map(state => `states[]=${state}`).join("&");
	const { query: queryOptions, client: clientOptions = {} } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getApiTransportNccTeamRideListFilteredQueryKey();
	const query = useQuery({
		queryFn: async () => {
			const res = await axiosClient({
				method: "get",
				url: `/api/transport/ncc-team/ride/list/filtered?${finalStateString}&ncc_team_id=${ncc_team_id}&page=${clientOptions.params?.page}`,
			});
			return res.data;
		},
		enabled: !!ncc_team_id,
		queryKey,
		...clientOptions,
		...(queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">),
	}) as UseQueryResult<TData, GetApiTransportNccTeamRideListFiltered["error"]> & {
		queryKey: TQueryKey;
	};
	query.queryKey = queryKey as TQueryKey;
	return query;
}

import client from "../../templatesSDK/client";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { GetApiUserMeQueryResponse, GetApiUserMeHeaderParams, GetApiUserMe404 } from "../models/GetApiUserMe";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type GetApiUserMeClient = typeof client<GetApiUserMeQueryResponse, GetApiUserMe404, never>;
type GetApiUserMe = {
    data: GetApiUserMeQueryResponse;
    error: GetApiUserMe404;
    request: never;
    pathParams: never;
    queryParams: never;
    headerParams: GetApiUserMeHeaderParams;
    response: GetApiUserMeQueryResponse;
    client: {
        parameters: Partial<Parameters<GetApiUserMeClient>[0]>;
        return: Awaited<ReturnType<GetApiUserMeClient>>;
    };
};
export const getApiUserMeQueryKey = () => ["/api/user/me"] as const;
export type GetApiUserMeQueryKey = ReturnType<typeof getApiUserMeQueryKey>;
export function getApiUserMeQueryOptions(headers?: GetApiUserMe["headerParams"], options: GetApiUserMe["client"]["parameters"] = {}) {
    const queryKey = getApiUserMeQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiUserMe["data"], GetApiUserMe["error"]>({
                method: "get",
                url: `/api/user/me`,
                headers: { ...headers, ...options.headers },
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Gets user dataAbort operation
 * @summary Me
 * @link /api/user/me
 */
export function useGetApiUserMeHook<TData = GetApiUserMe["response"], TQueryData = GetApiUserMe["response"], TQueryKey extends QueryKey = GetApiUserMeQueryKey>(headers?: GetApiUserMe["headerParams"], options: {
    query?: Partial<QueryObserverOptions<GetApiUserMe["response"], GetApiUserMe["error"], TData, TQueryData, TQueryKey>>;
    client?: GetApiUserMe["client"]["parameters"];
} = {}): UseQueryResult<TData, GetApiUserMe["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiUserMeQueryKey();
    const query = useQuery({
        ...getApiUserMeQueryOptions(headers, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, GetApiUserMe["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const getApiUserMeSuspenseQueryKey = () => ["/api/user/me"] as const;
export type GetApiUserMeSuspenseQueryKey = ReturnType<typeof getApiUserMeSuspenseQueryKey>;
export function getApiUserMeSuspenseQueryOptions(headers?: GetApiUserMe["headerParams"], options: GetApiUserMe["client"]["parameters"] = {}) {
    const queryKey = getApiUserMeSuspenseQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiUserMe["data"], GetApiUserMe["error"]>({
                method: "get",
                url: `/api/user/me`,
                headers: { ...headers, ...options.headers },
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Gets user dataAbort operation
 * @summary Me
 * @link /api/user/me
 */
export function useGetApiUserMeHookSuspense<TData = GetApiUserMe["response"], TQueryKey extends QueryKey = GetApiUserMeSuspenseQueryKey>(headers?: GetApiUserMe["headerParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<GetApiUserMe["response"], GetApiUserMe["error"], TData, TQueryKey>>;
    client?: GetApiUserMe["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, GetApiUserMe["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiUserMeSuspenseQueryKey();
    const query = useSuspenseQuery({
        ...getApiUserMeSuspenseQueryOptions(headers, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, GetApiUserMe["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
import { z } from "zod";


export const postApiBackofficeTransportRideUnclaimHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeTransportRideUnclaim200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeTransportRideUnclaim403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeTransportRideUnclaimMutationRequestSchema = z.object({ "id": z.number() });

 /**
 * @description Success
 */
export const postApiBackofficeTransportRideUnclaimMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { cn } from "@/lib/utils";
import type { HTMLProps } from "react";

/**
 * Represents a page component.
 *
 * @description This is a full-height and full-width flex container in column direction with padding, vertically centered items, horizontal alignment at start, and hidden overflow.
 * @param {HTMLProps<HTMLDivElement>} props - The props for the component.
 * @returns {JSX.Element} The rendered page component.
 */
const Page = (props: HTMLProps<HTMLDivElement>) => (
	<div
		className={cn({
			inputs: ["overflow-hidden p-4 pt-10 w-full h-full flex items-center justify-start flex-col", props.className],
		})}
	>
		{props.children}
	</div>
);

export default Page;

import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiNccTeamEditMutationRequest, PostApiNccTeamEditMutationResponse, PostApiNccTeamEditHeaderParams, PostApiNccTeamEdit403 } from "../models/PostApiNccteamEdit";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiNccTeamEditClient = typeof client<PostApiNccTeamEditMutationResponse, PostApiNccTeamEdit403, PostApiNccTeamEditMutationRequest>;
type PostApiNccTeamEdit = {
    data: PostApiNccTeamEditMutationResponse;
    error: PostApiNccTeamEdit403;
    request: PostApiNccTeamEditMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiNccTeamEditHeaderParams;
    response: PostApiNccTeamEditMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiNccTeamEditClient>[0]>;
        return: Awaited<ReturnType<PostApiNccTeamEditClient>>;
    };
};
/**
 * @description Edites a NccTeam. Calling user must be Owner of this NccTeamAbort operation
 * @summary EditNccTeam
 * @link /api/ncc-team/edit
 */
export function usePostApiNccTeamEditHook(headers?: PostApiNccTeamEdit["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiNccTeamEdit["response"], PostApiNccTeamEdit["error"], PostApiNccTeamEdit["request"]>;
    client?: PostApiNccTeamEdit["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiNccTeamEditHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiNccTeamEdit["data"], PostApiNccTeamEdit["error"], PostApiNccTeamEdit["request"]>({
                method: "post",
                url: `/api/ncc-team/edit`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
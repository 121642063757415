import { Button } from "@/components/ui/button";
import { queryClient } from "@/main";
import type { GetMeData } from "@/types";
import { useRouterState } from "@tanstack/react-router";
import type { Row } from "@tanstack/react-table";
import { usePostApiTransportNccTeamRideCancelHook, usePostApiTransportNccTeamRideConfirmHook } from "api/gen";
import toast from "react-hot-toast";
import type { AvailableVehicles, AvaildableDrivers, Rides } from "./_types";

export const ColumnsConfirm = ({
	row,
	availableDriversLoader,
	availableVehiclesLoader,
	availableVehiclesData,
	availableDriversData,
	vehicleType,
	getMeData,
}: {
	row: Row<Rides>;
	availableDriversLoader: boolean;
	availableVehiclesLoader: boolean;
	availableVehiclesData: AvailableVehicles | undefined;
	availableDriversData: AvaildableDrivers | undefined;
	vehicleType: {
		id: number;
		name: string;
		title: string;
	};
	getMeData: GetMeData | undefined;
}) => {
	const currentUrlLocation = useRouterState();

	const { mutate: ConfirmRide } = usePostApiTransportNccTeamRideConfirmHook(1);
	const { mutate: CancelRide } = usePostApiTransportNccTeamRideCancelHook(1);

	return (
		<div className="flex gap-2 -my-2">
			<Button
				size={"sm"}
				disabled={availableDriversLoader && availableVehiclesLoader && !availableVehiclesData && !availableDriversData}
				onClick={() =>
					ConfirmRide(
						{
							id: row.getValue("id"),
							confirm: true,
							rider_id: row.getValue("rider"),
							vehicle_id: row.getValue("vehicle"),
							ncc_team_id: (getMeData as GetMeData)?.data.nccteams[0]?.id as number,
						},
						{
							onSuccess() {
								queryClient.invalidateQueries({
									queryKey: [`/api/ncc-team/get/${(getMeData as GetMeData)?.data.nccteams[0]?.id as number}`],
								});
								queryClient.invalidateQueries({
									queryKey: [
										`/api/transport/ncc-team/ride/list/filtered?page=${currentUrlLocation.location.search.page}`,
									],
								});
								queryClient.invalidateQueries({
									queryKey: [
										`/api/transport/vehicle/list/${(getMeData as GetMeData)?.data.nccteams[0]?.id as number}/true`,
									],
								});
								toast.success("Corsa confermata con successo");
							},
							onError() {
								toast.error("Errore durante la conferma della corsa");
							},
						},
					)
				}
			>
				Conferma
			</Button>
			<Button
				size={"sm"}
				disabled={availableDriversLoader && availableVehiclesLoader && !availableVehiclesData && !availableDriversData}
				onClick={() =>
					CancelRide(
						{
							id: row.getValue("id"),
							ncc_team_id: (getMeData as GetMeData)?.data.nccteams[0]?.id as number,
						},
						{
							onSuccess() {
								queryClient.invalidateQueries({
									queryKey: [`/api/ncc-team/get/${(getMeData as GetMeData)?.data.nccteams[0]?.id as number}`],
								});
								queryClient.invalidateQueries({
									queryKey: [
										`/api/transport/ncc-team/ride/list/filtered?page=${currentUrlLocation.location.search.page}`,
									],
								});
								queryClient.invalidateQueries({
									queryKey: [
										`/api/transport/vehicle/list/${(getMeData as GetMeData)?.data.nccteams[0]?.id as number}/true`,
									],
								});
								toast.success("Corsa rifiutata con successo");
							},
							onError() {
								toast.error("Errore durante il rifiuto della corsa");
							},
						},
					)
				}
			>
				Rifiuta
			</Button>
		</div>
	);
};

import { z } from "zod";


export const getApiBackofficeTagListTagIdPathParamsSchema = z.object({ "tag_id": z.number().describe("Tag id to search for") });


export const getApiBackofficeTagListTagIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiBackofficeTagListTagId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const getApiBackofficeTagListTagId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiBackofficeTagListTagIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const getApiBackofficeUserGetUserIdPathParamsSchema = z.object({ "user_id": z.number().describe("Optional parameter. optional User id to look for") });


export const getApiBackofficeUserGetUserIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiBackofficeUserGetUserId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const getApiBackofficeUserGetUserId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiBackofficeUserGetUserIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
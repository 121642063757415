import { z } from "zod";


export const postApiNccTeamInvitesDeleteHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiNccTeamInvitesDelete200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiNccTeamInvitesDelete403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiNccTeamInvitesDeleteMutationRequestSchema = z.object({ "id": z.number(), "invite_id": z.number() });

 /**
 * @description Success
 */
export const postApiNccTeamInvitesDeleteMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiTransportNccTeamRideConfirmPathParamsSchema = z.object({ "ride_id": z.number().describe("Rider id for finding the ride") });


export const postApiTransportNccTeamRideConfirmHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportNccTeamRideConfirm200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiTransportNccTeamRideConfirm403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportNccTeamRideConfirmMutationRequestSchema = z.object({ "id": z.number(), "confirm": z.boolean(), "rider_id": z.number().optional(), "vehicle_id": z.number().optional(), "ncc_team_id": z.number() });

 /**
 * @description Success
 */
export const postApiTransportNccTeamRideConfirmMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiBackofficeTransportRideTierEditHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeTransportRideTierEdit200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeTransportRideTierEdit403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeTransportRideTierEditMutationRequestSchema = z.object({ "id": z.number(), "name": z.string().describe("Must not be greater than 32 characters."), "description": z.string().describe("Must not be greater than 100 characters."), "rules": z.string().describe("Must not be greater than 64 characters.").optional(), "activation_fee": z.string().describe("Activation fee"), "max_seats": z.number().optional(), "public": z.boolean(), "vehicle_type": z.string().describe("Must not be greater than 16 characters.").optional() });

 /**
 * @description Success
 */
export const postApiBackofficeTransportRideTierEditMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiFeedbackLikeAddHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiFeedbackLikeAdd200Schema = z.string();

 /**
 * @description Not available
 */
export const postApiFeedbackLikeAdd403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiFeedbackLikeAddMutationRequestSchema = z.object({ "likeable": z.string().describe("Must be one of <code>Event</code>."), "likeable_id": z.number().optional() });

 /**
 * @description Success
 */
export const postApiFeedbackLikeAddMutationResponseSchema = z.string();
import { z } from "zod";


export const getApiNccteamNcclocationViewPathParamsSchema = z.object({ "ncc_team_id": z.number().describe("The ID of the location.") });


export const getApiNccteamNcclocationViewHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiNccteamNcclocationView200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const getApiNccteamNcclocationView404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiNccteamNcclocationViewQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
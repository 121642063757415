import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import type { IPagination } from "@/types";
import { type ColumnDef, type Table as ITable, flexRender } from "@tanstack/react-table";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type ElementTypeFromArray<T> = T extends any[] ? T[number] : never;

interface DataTableProps<TDataTable, TValueColumn, TApiResponseData extends IPagination> {
	columns: ColumnDef<TDataTable, TValueColumn>[];
	dataTable: TDataTable[];
	table: ITable<ElementTypeFromArray<TDataTable[]>>;
}

/**
 * A reusable data table component.
 *
 * @template TDataTable - The type of data for the table.
 * @template TValueColumn - The type of values for the table columns.
 * @template TApiResponseData - The type of data returned from the API.
 *
 * @param {DataTableProps<TDataTable, TValueColumn, TApiResponseData>} props - The props for the DataTable component.
 * @returns {JSX.Element} - The rendered DataTable component.
 *
 * @external https://ui.shadcn.com/docs/components/data-table#reusable-components to learn more about the DataTable component and adding filtering or sorting.
 */
export function DataTable<TDataTable, TValueColumn, TApiResponseData extends IPagination>({
	columns,
	table,
}: DataTableProps<TDataTable, TValueColumn, TApiResponseData>) {
	return (
		<Table>
			<TableHeader>
				{table.getHeaderGroups().map(headerGroup => (
					<TableRow key={headerGroup.id}>
						{headerGroup.headers.map(header => {
							return (
								<TableHead
									key={header.id}
									colSpan={header.colSpan}
								>
									{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
								</TableHead>
							);
						})}
					</TableRow>
				))}
			</TableHeader>
			<TableBody>
				{table.getRowModel().rows?.length ? (
					table.getRowModel().rows.map(row => (
						<TableRow
							key={row.id}
							data-state={row.getIsSelected() && "selected"}
						>
							{row.getVisibleCells().map(cell => (
								<TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
							))}
						</TableRow>
					))
				) : (
					<TableRow>
						<TableCell
							colSpan={columns.length}
							className="h-24 text-center"
						>
							Nessun risultato trovato
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	);
}

import { z } from "zod";


export const getApiUserAuthResetPasswordTokenPathParamsSchema = z.object({ "token": z.string().describe("Challenge token to authorize password reset") });


export const getApiUserAuthResetPasswordTokenHeaderParamsSchema = z.object({ "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiUserAuthResetPasswordToken200Schema = z.object({ "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const getApiUserAuthResetPasswordToken404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiUserAuthResetPasswordTokenQueryResponseSchema = z.object({ "data": z.object({}).describe("Data").optional() });
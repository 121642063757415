import { z } from "zod";


export const postApiBackofficeTicketTicketTierValidatorExternalSetHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeTicketTicketTierValidatorExternalSet200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeTicketTicketTierValidatorExternalSet403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeTicketTicketTierValidatorExternalSetMutationRequestSchema = z.object({ "id": z.number(), "external_ticket_tier_id": z.number() });

 /**
 * @description Success
 */
export const postApiBackofficeTicketTicketTierValidatorExternalSetMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
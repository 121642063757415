import { z } from "zod";


export const getApiInvoiceViewbybillableBillableTypeBillableIdInvoiceIdPathParamsSchema = z.object({ "billable_type": z.string(), "billable_id": z.string().describe("The ID of the billable."), "invoice_id": z.number().describe("Invoice id to search for") });


export const getApiInvoiceViewbybillableBillableTypeBillableIdInvoiceIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiInvoiceViewbybillableBillableTypeBillableIdInvoiceId200Schema = z.string();

 /**
 * @description Not available
 */
export const getApiInvoiceViewbybillableBillableTypeBillableIdInvoiceId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiInvoiceViewbybillableBillableTypeBillableIdInvoiceIdQueryResponseSchema = z.string();
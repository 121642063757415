import { z } from "zod";


export const getApiPartyInvitesDeclineTokenPathParamsSchema = z.object({ "token": z.string().describe("Token id for finding the invite") });


export const getApiPartyInvitesDeclineTokenHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiPartyInvitesDeclineToken200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const getApiPartyInvitesDeclineToken403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiPartyInvitesDeclineTokenQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiNccTeamStatusChangeMutationRequest, PostApiNccTeamStatusChangeMutationResponse, PostApiNccTeamStatusChangeHeaderParams, PostApiNccTeamStatusChange403 } from "../models/PostApiNccTeamStatusChange";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiNccTeamStatusChangeClient = typeof client<PostApiNccTeamStatusChangeMutationResponse, PostApiNccTeamStatusChange403, PostApiNccTeamStatusChangeMutationRequest>;
type PostApiNccTeamStatusChange = {
    data: PostApiNccTeamStatusChangeMutationResponse;
    error: PostApiNccTeamStatusChange403;
    request: PostApiNccTeamStatusChangeMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiNccTeamStatusChangeHeaderParams;
    response: PostApiNccTeamStatusChangeMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiNccTeamStatusChangeClient>[0]>;
        return: Awaited<ReturnType<PostApiNccTeamStatusChangeClient>>;
    };
};
/**
 * @description Changes the current status of the NccTeam. Can only called by User with Owner or Manager role.Abort operation
 * @summary ChangeNccTeamStatus
 * @link /api/ncc-team/status/change
 */
export function usePostApiNccTeamStatusChangeHook(headers?: PostApiNccTeamStatusChange["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiNccTeamStatusChange["response"], PostApiNccTeamStatusChange["error"], PostApiNccTeamStatusChange["request"]>;
    client?: PostApiNccTeamStatusChange["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiNccTeamStatusChangeHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiNccTeamStatusChange["data"], PostApiNccTeamStatusChange["error"], PostApiNccTeamStatusChange["request"]>({
                method: "post",
                url: `/api/ncc-team/status/change`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
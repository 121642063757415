import { z } from "zod";


export const postApiLocationCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiLocationCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const postApiLocationCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiLocationCreateMutationRequestSchema = z.object({ "team_id": z.number(), "name": z.string().describe("Must not be greater than 64 characters."), "description": z.string().describe("Must not be greater than 512 characters.").optional(), "address": z.string().describe("Must not be greater than 100 characters."), "latitude": z.string().describe("Latitude"), "longitude": z.string().describe("Longitude"), "times": z.string().describe("Must not be greater than 80 characters."), "days": z.string(), "age_people": z.string().describe("Must not be greater than 8 characters."), "location_category": z.string().describe("Must not be greater than 100 characters."), "max_people": z.number().describe("Must be between 0 and 100000.") });

 /**
 * @description Success
 */
export const postApiLocationCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useNavigate, useRouterState } from "@tanstack/react-router";
import { type ReactNode, useState } from "react";
import { GoChevronLeft, GoChevronRight, GoKebabHorizontal } from "react-icons/go";

const PaginationButton = ({
	isCurrent,
	children,
	onClick,
	className,
}: { isCurrent?: boolean; onClick?: () => unknown; children: number | ReactNode; className?: string }) => (
	<Button
		variant={isCurrent ? "default" : "transparent"}
		size={"hidden"}
		onClick={onClick}
		className={cn({ inputs: ["px-2", children && +children >= 10 && "px-1", className] })}
	>
		{children}
	</Button>
);

const PaginationEllipsis = () => (
	<PaginationButton className="px-1">
		<GoKebabHorizontal />
	</PaginationButton>
);

type PaginationProps = {
	pageCount: number;
	currentPage: number;
}; /**
 * Renders a pagination component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.currentPage - Current active page.
 * @param {string} props.pageCount - Number of total pages.
 * @returns {JSX.Element} The rendered pagination component.
 *
 * @example
 * ```tsx
 * const App: React.FC = (loading) => {
 *  return (<>
 * 				{!loading && data ? (
 *					<Pagination
 *						currentPage={data.meta.current_page}
 *						pageCount={data.meta.last_page}
 *						key={data.meta.current_page}
 *					/>
 *				) : null}
 *		    </>
 *         );
 * };
 * ```
 */
const Pagination = ({ pageCount, currentPage }: PaginationProps): JSX.Element => {
	const SIZE = 7;

	const currentUrlLocation = useRouterState().location;
	const navigate = useNavigate({ from: currentUrlLocation.pathname });
	const goToPage = (page: number) => {
		navigate({ to: currentUrlLocation.pathname, search: { page } });
	};

	return (
		<div className="flex">
			<div className="flex flex-row gap-1 p-2 bg-background-content m-auto rounded">
				<Button
					onClick={() => goToPage(currentPage - 1)}
					disabled={currentPage === 1 || pageCount === 0}
					className={cn({
						inputs: [
							currentPage === 1 && "cursor-not-allowed",
							"bg-foreground border-foreground hover:bg-foreground hover:border-foreground text-muted",
						],
					})}
					size={"icon"}
				>
					<GoChevronLeft />
				</Button>
				{Array.from({ length: pageCount }, (_, index) => index + 1).map(index => {
					if (
						// Evita i numeri di pagina che sicuramente non vanno rimossi

						//  se ci sono più pagine degli slot disponibili
						pageCount > SIZE &&
						// - se non è la prima pagina, sempre visibile
						index !== 1 &&
						// - se non è l'ultima pagina, sempre visibile
						index !== pageCount &&
						/**
						 * - esclude anche l'intorno di numeri rispetto alla pagina corrente,
						 *   determinando il numero di slot disponibili, che sono SIZE - 4:
						 *   - 1 per il primo numero
						 *   - 1 per l'ellipsis sinistro
						 *   - 1 per l'ellipsis destro
						 *   - 1 per l'ultimo numero
						 *   / 2 per trovare la dimensione dell'intorno
						 */
						Math.abs(currentPage - index) > (SIZE - 4) / 2
					) {
						// Ellipsis sinistro
						// Se vero, il numero viene rimosso o sostituito con ellipsis
						if (
							/**
							 * Se la pagina corrente si trova prima della metà,
							 * potrebbe non essere necessario avere l'ellipsis sinistro.
							 * Se la pagina corrente è compresa tra 1 e la metà della SIZE,
							 * trovandosi quindi al centro del paginatore, allora non è
							 * necessario eliminare numeri e la condizione sarà falsa.
							 * Se invece la pagina corrente è oltre la metà del paginatore
							 * (e quindi maggiore di SIZE / 2, +1 perché gli indici
							 * iniziano da 1), i numeri saranno rimossi.
							 * Nota: tutti i numeri attorno alla pagina corrente
							 * sono già preservati dall'if superiore.
							 */
							(index <= pageCount / 2 && currentPage > SIZE / 2 + 1) ||
							/**
							 * Se la pagina corrente si trova oltre la metà,
							 * ignora tutti i numeri che vanno troppo a sinistra:
							 * il numero pagina [index] viene ignorato se è a più
							 * di SIZE - 2 (1 per il primo numero + 1 per ellipsis)
							 * di distanza (+1 perché gli indici cominciano da 1) da destra
							 */
							(currentPage > pageCount / 2 && pageCount - index + 1 > SIZE - 2)
						) {
							if (index === 2) return <PaginationEllipsis />;
							return null;
						}
						// Ellipsis destro
						// Se vero, il numero viene rimosso o sostituito con ellipsis
						if (
							/**
							 * Se la pagina corrente si trova prima della metà,
							 * tutti i numeri troppo a destra vanno eliminati:
							 * il numero pagina [index] viene ignorato se va oltre
							 * SIZE - 2 (1 per ellipsis + 1 per l'ultimo numero).
							 */
							(currentPage <= pageCount / 2 && index > SIZE - 2) ||
							/**
							 * Se la pagina corrente si trova oltre della metà,
							 * potrebbe non essere necessario avere l'ellipsis destro.
							 * Se la pagina corrente è quasi alla fine del paginatore,
							 * precisamente se manca metà paginatore di distanza
							 * o meno dalla fine, allora la condizione sarà falsa
							 * e non verranno eliminati numeri. La distanza dalla fine
							 * è espressa come pageCount - currentPage.
							 * Se invece la distanza dalla fine è più di metà SIZE
							 * (e quindi maggiore di SIZE / 2), i numeri saranno rimossi.
							 * Nota: tutti i numeri attorno alla pagina corrente
							 * sono già preservati dall'if superiore.
							 */
							(index > pageCount / 2 && pageCount - currentPage > SIZE / 2)
						) {
							if (index === pageCount - 1) return <PaginationEllipsis />;
							return null;
						}
					}
					return (
						<PaginationButton
							key={index}
							isCurrent={index === currentPage}
							onClick={() => goToPage(index)}
						>
							{index}
						</PaginationButton>
					);
				})}
				<Button
					onClick={() => goToPage(currentPage + 1)}
					disabled={currentPage === pageCount || pageCount === 0}
					className={cn({
						inputs: [
							currentPage === pageCount && "cursor-not-allowed",
							"bg-foreground border-foreground hover:bg-foreground hover:border-foreground text-background-base",
						],
					})}
					size={"icon"}
				>
					<GoChevronRight />
				</Button>
			</div>
		</div>
	);
};

export default Pagination;

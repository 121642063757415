import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiNccTeamNccLocationCreateMutationRequest, PostApiNccTeamNccLocationCreateMutationResponse, PostApiNccTeamNccLocationCreateHeaderParams, PostApiNccTeamNccLocationCreate403 } from "../models/PostApiNccteamNcclocationCreate";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiNccTeamNccLocationCreateClient = typeof client<PostApiNccTeamNccLocationCreateMutationResponse, PostApiNccTeamNccLocationCreate403, PostApiNccTeamNccLocationCreateMutationRequest>;
type PostApiNccTeamNccLocationCreate = {
    data: PostApiNccTeamNccLocationCreateMutationResponse;
    error: PostApiNccTeamNccLocationCreate403;
    request: PostApiNccTeamNccLocationCreateMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiNccTeamNccLocationCreateHeaderParams;
    response: PostApiNccTeamNccLocationCreateMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiNccTeamNccLocationCreateClient>[0]>;
        return: Awaited<ReturnType<PostApiNccTeamNccLocationCreateClient>>;
    };
};
/**
 * @description Creates a new NccLocation and assigns it to the calling NccTeamAbort operation
 * @summary CreateNccLocation
 * @link /api/ncc-team/ncc-location/create
 */
export function usePostApiNccTeamNccLocationCreateHook(headers?: PostApiNccTeamNccLocationCreate["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiNccTeamNccLocationCreate["response"], PostApiNccTeamNccLocationCreate["error"], PostApiNccTeamNccLocationCreate["request"]>;
    client?: PostApiNccTeamNccLocationCreate["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiNccTeamNccLocationCreateHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiNccTeamNccLocationCreate["data"], PostApiNccTeamNccLocationCreate["error"], PostApiNccTeamNccLocationCreate["request"]>({
                method: "post",
                url: `/api/ncc-team/ncc-location/create`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
import { z } from "zod";


export const postApiFeedbackReportCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiFeedbackReportCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const postApiFeedbackReportCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiFeedbackReportCreateMutationRequestSchema = z.object({ "reason": z.string().describe("Must not be greater than 255 characters."), "reason_comment": z.string().describe("Must not be greater than 255 characters."), "reporter": z.string().describe("Must be one of <code>User</code>, <code>Rider</code>, or <code>Team</code>."), "reporter_id": z.number(), "reportable_entity": z.string().describe("Must be one of <code>User</code>, <code>Rider</code>, <code>Team</code>, or <code>Party</code>."), "reportable_entity_id": z.number(), "reportable_act": z.string().describe("Must be one of <code>Ride</code>, <code>Event</code>, <code>Location</code>, <code>Team</code>, or <code>Party</code>.").optional(), "reportable_act_id": z.number().optional() });

 /**
 * @description Success
 */
export const postApiFeedbackReportCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
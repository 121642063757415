import { z } from "zod";


export const getApiUserDeleteHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiUserDelete200Schema = z.string();

 /**
 * @description Not available
 */
export const getApiUserDelete404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiUserDeleteQueryResponseSchema = z.string();
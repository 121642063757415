import { z } from "zod";


export const getApiBackofficeTransportRideTierGetRidetierIdPathParamsSchema = z.object({ "rideTier_id": z.number().describe("The ID of the RideTier.") });


export const getApiBackofficeTransportRideTierGetRidetierIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description 0
 */
export const getApiBackofficeTransportRideTierGetRidetierId200Schema = z.object({ "data": z.object({}).optional() });

 /**
 * @description Not avaible
 */
export const getApiBackofficeTransportRideTierGetRidetierId404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description 0
 */
export const getApiBackofficeTransportRideTierGetRidetierIdQueryResponseSchema = z.object({ "data": z.object({}).optional() });
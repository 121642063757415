import { buttonVariants } from "@/components/ui/button";
import Dropdown from "@/components/ui/dropdown";
import { Skeleton } from "@/components/ui/skeleton";
import { AUTH_CRED } from "@/hooks/auth/constants";
import { useNccTeamIdStore } from "@/hooks/store/main";
import { cn } from "@/lib/utils";
import type { GetMeData } from "@/types";
import { Link } from "@tanstack/react-router";
import { useGetApiUserMeHook } from "api/gen";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { GoChevronDown, GoGear, GoSignOut } from "react-icons/go";
import LogoHeader from "../../assets/LogoHeader.png";

/**
 * Represents the navigation bar component.
 *
 * @returns The JSX element representing the navigation bar.
 */
export default function Navbar() {
	const { data, isPending: loadingUserData } = useGetApiUserMeHook();
	const getMeData = data as GetMeData | undefined;
	const { setNccTeamId, setNccTeamData } = useNccTeamIdStore();

	useEffect(() => {
		if (!loadingUserData && getMeData) {
			setNccTeamId(getMeData.data.nccteams[0]?.id);
			setNccTeamData(getMeData);
		}
	}, [loadingUserData, getMeData, setNccTeamId]);

	return (
		<header className="px-4 w-full flex justify-between items-center border-muted border-b-2">
			<Link
				to="/webpanel/veichles"
				search={{ page: 1 }}
			>
				<img
					src={LogoHeader}
					className="w-64 py-4"
					alt="logo-header"
				/>
			</Link>
			<div>
				<Dropdown
					backgroundBlur={true}
					trigger={
						<div
							className={cn({
								inputs: [
									buttonVariants({
										variant: "ghost",
									}),
									"gap-4",
								],
							})}
						>
							{!loadingUserData && getMeData ? (
								<>
									<div>
										<h3 className="font-medium">{getMeData.data.name}</h3>
										{getMeData.data.nccteams.map(el => (
											<p
												className="text-accent  text-sm"
												key={el.id}
											>
												{el.name}
											</p>
										))}
									</div>
									<GoChevronDown size={"24px"} />
								</>
							) : (
								<>
									<div>
										<Skeleton className="h-2 w-full" /> <Skeleton className="h-2 w-min" />
									</div>
									<GoChevronDown size={"24px"} />
								</>
							)}
						</div>
					}
				>
					<Link
						to="/webpanel/settings"
						className={cn({ inputs: [buttonVariants({ variant: "transparent", align: "left" })] })}
					>
						<GoGear className="mt-1" /> Impostazioni
					</Link>
					{/* TODO: if admin */}
					{/* <Link
					to="/webpanel/veichles"
					search={{ page: 1 }}
					className={cn(buttonVariants({ variant: "transparent", align: "left" }))}
				>
					<GoArrowSwitch className="mt-1" /> Passa ad admin
				</Link> */}
					<Link
						to="/signIn"
						onClick={() => {
							/* logout */
							Cookies.remove(AUTH_CRED);
						}}
						className={cn({ inputs: [buttonVariants({ variant: "transparent", align: "left" })] })}
					>
						<GoSignOut className="mt-1" /> Esci
					</Link>
				</Dropdown>
			</div>
		</header>
	);
}

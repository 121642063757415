import { type QueryClient } from "@tanstack/react-query";
import type { Invalidations } from "../gen/invalidations";
import { queryClient } from "@/main";

const getInvalidationForMutation = <T extends keyof Invalidations = keyof Invalidations>({
	mutationName,
	queryClient,
}: {
	mutationName: T;
	queryClient: QueryClient;
}) => {
	const invalidations: Partial<Invalidations> = {
		//non mettere query key con valori dinamici nella stringa / url
		usePostApiTransportNccTeamRideCancelHook: () => {
			queryClient.invalidateQueries({
				queryKey: ["/api/transport/ncc-team/ride/list/filtered"],
				
			});
		},
		usePostApiNccTeamStatusChangeHook: () => {
			queryClient.invalidateQueries({
				queryKey: ["/api/transport/ncc-team/ride/list/filtered","/api/user/me"],
			});
		},
		usePostApiNccTeamEditHook: () => {
			queryClient.invalidateQueries({
				queryKey: ["/api/user/me"],
			});
		}
	};

	return invalidations[mutationName];
};

export const useInvalidationForMutation = <T extends keyof Invalidations = keyof Invalidations>(mutationName: T) => {
	return getInvalidationForMutation({ mutationName, queryClient });
};

import { z } from "zod";


export const getApiBackofficeTeamGetTeamIdPathParamsSchema = z.object({ "team_id": z.number().describe("Team id to search for") });


export const getApiBackofficeTeamGetTeamIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiBackofficeTeamGetTeamId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const getApiBackofficeTeamGetTeamId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiBackofficeTeamGetTeamIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { z } from "zod";


export const getApiNccTeamInvitesIssuedTeamPathParamsSchema = z.object({ "team": z.string(), "team_id": z.number().describe("NccTeam id to search issued invites for") });


export const getApiNccTeamInvitesIssuedTeamHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiNccTeamInvitesIssuedTeam200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const getApiNccTeamInvitesIssuedTeam403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiNccTeamInvitesIssuedTeamQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { z } from "zod";


export const postApiBackofficeTransportVehicleDeleteHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeTransportVehicleDelete200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeTransportVehicleDelete403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const postApiBackofficeTransportVehicleDeleteMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
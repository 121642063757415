import { z } from "zod";


export const postApiBackofficeActivationLocationToggleHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeActivationLocationToggle200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeActivationLocationToggle403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeActivationLocationToggleMutationRequestSchema = z.object({ "id": z.number(), "active": z.boolean(), "external_location_id": z.number().optional() });

 /**
 * @description Success
 */
export const postApiBackofficeActivationLocationToggleMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
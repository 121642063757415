import { z } from "zod";


export const postApiTransportRiderRequestProcessHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportRiderRequestProcess200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not avaible
 */
export const postApiTransportRiderRequestProcess403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportRiderRequestProcessMutationRequestSchema = z.object({ "id": z.number(), "action": z.string().describe("Must be one of <code>Accept</code> or <code>Decline</code> Must not be greater than 10 characters.") });

 /**
 * @description Success
 */
export const postApiTransportRiderRequestProcessMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
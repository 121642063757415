import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiNccTeamInvitesInviteMutationRequest, PostApiNccTeamInvitesInviteMutationResponse, PostApiNccTeamInvitesInviteHeaderParams, PostApiNccTeamInvitesInvite403 } from "../models/PostApiNccteamInvitesInvite";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiNccTeamInvitesInviteClient = typeof client<PostApiNccTeamInvitesInviteMutationResponse, PostApiNccTeamInvitesInvite403, PostApiNccTeamInvitesInviteMutationRequest>;
type PostApiNccTeamInvitesInvite = {
    data: PostApiNccTeamInvitesInviteMutationResponse;
    error: PostApiNccTeamInvitesInvite403;
    request: PostApiNccTeamInvitesInviteMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiNccTeamInvitesInviteHeaderParams;
    response: PostApiNccTeamInvitesInviteMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiNccTeamInvitesInviteClient>[0]>;
        return: Awaited<ReturnType<PostApiNccTeamInvitesInviteClient>>;
    };
};
/**
 * @description Invites a user to this NccTeam with the specified role. Calling user must be Owner of this NccTeamAbort operation
 * @summary CreateNccTeamInvite
 * @link /api/ncc-team/invites/invite
 */
export function usePostApiNccTeamInvitesInviteHook(headers?: PostApiNccTeamInvitesInvite["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiNccTeamInvitesInvite["response"], PostApiNccTeamInvitesInvite["error"], PostApiNccTeamInvitesInvite["request"]>;
    client?: PostApiNccTeamInvitesInvite["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiNccTeamInvitesInviteHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiNccTeamInvitesInvite["data"], PostApiNccTeamInvitesInvite["error"], PostApiNccTeamInvitesInvite["request"]>({
                method: "post",
                url: `/api/ncc-team/invites/invite`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
import { z } from "zod";


export const postApiTransportTierPricingCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportTierPricingCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiTransportTierPricingCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportTierPricingCreateMutationRequestSchema = z.object({ "ride_tier_id": z.number(), "max_distance": z.number().optional(), "km_price": z.string().describe("KM price fee"), "km_price_reason": z.string().describe("Must not be greater than 128 characters."), "ncc_team_id": z.number() });

 /**
 * @description Success
 */
export const postApiTransportTierPricingCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const getApiUserAuthOnboardingTokenPathParamsSchema = z.object({ "token": z.string().describe("Socialite swap token to get login flow state data") });


export const getApiUserAuthOnboardingTokenHeaderParamsSchema = z.object({ "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiUserAuthOnboardingToken200Schema = z.object({});

 /**
 * @description Not avaible
 */
export const getApiUserAuthOnboardingToken404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiUserAuthOnboardingTokenQueryResponseSchema = z.object({});
import { z } from "zod";


export const postApiEventCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiEventCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const postApiEventCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiEventCreateMutationRequestSchema = z.object({ "team_id": z.number(), "location_id": z.number(), "name": z.string().describe("Must not be greater than 64 characters."), "description": z.string().describe("Must not be greater than 512 characters.").optional(), "date_begin": z.string().describe("Must be a valid date. Must be a date after <code>yesterday</code>.").optional(), "date_end": z.string().describe("Must be a valid date. Must be a date after <code>yesterday</code>.").optional(), "guest": z.string().describe("Must not be greater than 64 characters."), "tags": z.array(z.string()).describe("Must be one of <code>House music</code>, <code>Techno</code>, <code>Reggaeton</code>, <code>Trap</code>, <code>Hip hop</code>, <code>Live music</code>, <code>Rock music</code>, <code>Musica Italiana</code>, or <code>Pop</code> Must be at least 1 characters. Must not be greater than 64 characters.") });

 /**
 * @description Success
 */
export const postApiEventCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { z } from "zod";


export const getApiBackofficeReportGetReportIdPathParamsSchema = z.object({ "report_id": z.number().describe("Report id to search for") });


export const getApiBackofficeReportGetReportIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiBackofficeReportGetReportId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const getApiBackofficeReportGetReportId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiBackofficeReportGetReportIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
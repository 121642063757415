import { z } from "zod";


export const getApiBackofficeUserListHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiBackofficeUserList200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const getApiBackofficeUserList403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiBackofficeUserListQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiTransportNccTeamRideListFilteredHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportNccTeamRideListFiltered200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const postApiTransportNccTeamRideListFiltered403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportNccTeamRideListFilteredMutationRequestSchema = z.object({ "states": z.array(z.string()).describe("Must be one of <code>Draft</code>, <code>Finalized</code>, <code>Published</code>, <code>Ready</code>, <code>Started</code>, <code>Finished</code>, or <code>Cancelled</code> Must be at least 1 characters. Must not be greater than 64 characters."), "ncc_team_id": z.number() });

 /**
 * @description Success
 */
export const postApiTransportNccTeamRideListFilteredMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { Button } from "@/components/ui/button";
import Dialog from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import type { Table } from "@tanstack/react-table";
import { useState } from "react";
import { FaPlus as Plus } from "react-icons/fa";
import { VeichlesUtilsForm } from "./_VeichlesUtilsForm";
import type { Veichles } from "./_types";

export const VeichlesUtils = ({ table }: { table: Table<Veichles> }) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const openDialog = () => setIsDialogOpen(true);
	const closeDialog = () => setIsDialogOpen(false);

	return (
		<div className="flex shrink-0 justify-between gap-3 overflow-hidden items-center p-3 bg-background-content w-full mb-3 rounded-container">
			<Input
				placeholder="Cerca mezzo..."
				className="w-48"
				value={table.getColumn("plaque")?.getFilterValue() as string}
				onChange={event => table.getColumn("plaque")?.setFilterValue(event.target.value)}
				variant={"outline"}
			/>
			<div>
				<Button
					onClick={openDialog}
					className="px-4"
				>
					<Plus className="w-2" /> Crea nuovo mezzo
				</Button>
				<Dialog
					isOpen={isDialogOpen}
					onClose={closeDialog}
					title="Crea nuovo mezzo"
				>
					<VeichlesUtilsForm closeDialog={closeDialog} />
				</Dialog>
			</div>
		</div>
	);
};

import client from "../../templatesSDK/client";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { GetApiTransportTierListNccteamIdQueryResponse, GetApiTransportTierListNccteamIdPathParams, GetApiTransportTierListNccteamIdHeaderParams, GetApiTransportTierListNccteamId403 } from "../models/GetApiTransportTierListNccteamId";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

type GetApiTransportTierListNccteamIdClient = typeof client<GetApiTransportTierListNccteamIdQueryResponse, GetApiTransportTierListNccteamId403, never>;
type GetApiTransportTierListNccteamId = {
    data: GetApiTransportTierListNccteamIdQueryResponse;
    error: GetApiTransportTierListNccteamId403;
    request: never;
    pathParams: GetApiTransportTierListNccteamIdPathParams;
    queryParams: never;
    headerParams: GetApiTransportTierListNccteamIdHeaderParams;
    response: GetApiTransportTierListNccteamIdQueryResponse;
    client: {
        parameters: Partial<Parameters<GetApiTransportTierListNccteamIdClient>[0]>;
        return: Awaited<ReturnType<GetApiTransportTierListNccteamIdClient>>;
    };
};
export const getApiTransportTierListNccteamIdQueryKey = (nccTeamId: GetApiTransportTierListNccteamIdPathParams["nccTeam_id"]) => ["/api/transport/tier/list/:nccTeam_id"] as const;
export type GetApiTransportTierListNccteamIdQueryKey = ReturnType<typeof getApiTransportTierListNccteamIdQueryKey>;
export function getApiTransportTierListNccteamIdQueryOptions(nccTeamId: GetApiTransportTierListNccteamIdPathParams["nccTeam_id"], headers?: GetApiTransportTierListNccteamId["headerParams"], options: GetApiTransportTierListNccteamId["client"]["parameters"] = {}) {
    const queryKey = getApiTransportTierListNccteamIdQueryKey(nccTeamId);
    return queryOptions({
        queryKey,
        enabled: !!nccTeamId,
        queryFn: async () => {
            const res = await client<GetApiTransportTierListNccteamId["data"], GetApiTransportTierListNccteamId["error"]>({
                method: "get",
                url: `/api/transport/tier/list/${nccTeamId}`,
                headers: { ...headers, ...options.headers },
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Shows all available RideTiers of passed NccTeam. Calling user must be Owner of that Team.Abort operation
 * @summary GetRideTiers
 * @link /api/transport/tier/list/:nccTeam_id
 */
export function useGetApiTransportTierListNccteamIdHook<TData = GetApiTransportTierListNccteamId["response"], TQueryData = GetApiTransportTierListNccteamId["response"], TQueryKey extends QueryKey = GetApiTransportTierListNccteamIdQueryKey>(nccTeamId: GetApiTransportTierListNccteamIdPathParams["nccTeam_id"], headers?: GetApiTransportTierListNccteamId["headerParams"], options: {
    query?: Partial<QueryObserverOptions<GetApiTransportTierListNccteamId["response"], GetApiTransportTierListNccteamId["error"], TData, TQueryData, TQueryKey>>;
    client?: GetApiTransportTierListNccteamId["client"]["parameters"];
} = {}): UseQueryResult<TData, GetApiTransportTierListNccteamId["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiTransportTierListNccteamIdQueryKey(nccTeamId);
    const query = useQuery({
        ...getApiTransportTierListNccteamIdQueryOptions(nccTeamId, headers, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, GetApiTransportTierListNccteamId["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const getApiTransportTierListNccteamIdSuspenseQueryKey = (nccTeamId: GetApiTransportTierListNccteamIdPathParams["nccTeam_id"]) => ["/api/transport/tier/list/:nccTeam_id"] as const;
export type GetApiTransportTierListNccteamIdSuspenseQueryKey = ReturnType<typeof getApiTransportTierListNccteamIdSuspenseQueryKey>;
export function getApiTransportTierListNccteamIdSuspenseQueryOptions(nccTeamId: GetApiTransportTierListNccteamIdPathParams["nccTeam_id"], headers?: GetApiTransportTierListNccteamId["headerParams"], options: GetApiTransportTierListNccteamId["client"]["parameters"] = {}) {
    const queryKey = getApiTransportTierListNccteamIdSuspenseQueryKey(nccTeamId);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiTransportTierListNccteamId["data"], GetApiTransportTierListNccteamId["error"]>({
                method: "get",
                url: `/api/transport/tier/list/${nccTeamId}`,
                headers: { ...headers, ...options.headers },
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Shows all available RideTiers of passed NccTeam. Calling user must be Owner of that Team.Abort operation
 * @summary GetRideTiers
 * @link /api/transport/tier/list/:nccTeam_id
 */
export function useGetApiTransportTierListNccteamIdHookSuspense<TData = GetApiTransportTierListNccteamId["response"], TQueryKey extends QueryKey = GetApiTransportTierListNccteamIdSuspenseQueryKey>(nccTeamId: GetApiTransportTierListNccteamIdPathParams["nccTeam_id"], headers?: GetApiTransportTierListNccteamId["headerParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<GetApiTransportTierListNccteamId["response"], GetApiTransportTierListNccteamId["error"], TData, TQueryKey>>;
    client?: GetApiTransportTierListNccteamId["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, GetApiTransportTierListNccteamId["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiTransportTierListNccteamIdSuspenseQueryKey(nccTeamId);
    const query = useSuspenseQuery({
        ...getApiTransportTierListNccteamIdSuspenseQueryOptions(nccTeamId, headers, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, GetApiTransportTierListNccteamId["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
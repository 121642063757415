import { Button } from "@/components/ui/button";
import Dialog from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { queryClient } from "@/main";
import type { Drivers } from "@/routes/webpanel/drivers/_types";
import type { GetMeData } from "@/types";
import { zodResolver } from "@hookform/resolvers/zod";
import type { Table } from "@tanstack/react-table";
import {
	getApiUserMeQueryOptions,
	postApiNccTeamInvitesInviteMutationRequestSchema,
	type postApiNccTeamUserRoleAddMutationRequestSchema,
	usePostApiNccTeamInvitesInviteHook,
	usePostApiNccTeamUserRoleAddHook,
} from "api/gen";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import type { z } from "zod";

export const DriversUtils = ({
	table,
	currentUrlLocationPageState,
	getMeCache,
}: { table: Table<Drivers>; currentUrlLocationPageState: number | undefined; getMeCache: GetMeData | undefined }) => {
	const { mutate: CreateInvite } = usePostApiNccTeamInvitesInviteHook(undefined, {
		mutation: {
			onSettled: async () => {
				return await queryClient.invalidateQueries({
					queryKey: [
						`/api/transport/ncc-team/rider/list/${
							(getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number
						}`,
						`/api/transport/ncc-team/rider/list/${
							(getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number
						}?page=${currentUrlLocationPageState}`,
						currentUrlLocationPageState,
					],
				});
			},
		},
	});

	const { mutate: AddUserRole } = usePostApiNccTeamUserRoleAddHook(undefined, {
		mutation: {
			onSettled: async () => {
				return await queryClient.invalidateQueries({
					queryKey: [
						`/api/transport/ncc-team/rider/list/${
							(getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number
						}`,
						`/api/transport/ncc-team/rider/list/${
							(getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number
						}?page=${currentUrlLocationPageState}`,
						currentUrlLocationPageState,
					],
				});
			},
		},
	});

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const openDialog = () => setIsDialogOpen(true);
	const closeDialog = () => setIsDialogOpen(false);

	const form = useForm<z.infer<typeof postApiNccTeamInvitesInviteMutationRequestSchema>>({
		mode: "all",
		resolver: zodResolver(postApiNccTeamInvitesInviteMutationRequestSchema),
		defaultValues: {
			role: "Driver",
			id: (getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number,
		},
	});

	function onSubmit(data: z.infer<typeof postApiNccTeamInvitesInviteMutationRequestSchema>) {
		const dataProcessed = {
			...data,
			role: "Driver",
			id: (getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number,
		};
		CreateInvite(dataProcessed, {
			onError() {
				toast.error("Errore durante l'invio dell'invito");
				closeDialog();
			},
			onSuccess() {
				toast.success("Invito inviato con successo");
				closeDialog();
			},
		});
	}

	function addMySelfAsDriver() {
		const data: z.infer<typeof postApiNccTeamUserRoleAddMutationRequestSchema> = {
			id: (getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number,
			user_id: (getMeCache as GetMeData | undefined)?.data.id as number,
			role: "Driver",
		};
		AddUserRole(data, {
			onError() {
				toast.error("Errore durante l'aggiuta del tuo account al ruolo Driver");
				queryClient.invalidateQueries({ queryKey: ["/api/user/me"] }).then(r => null);
				closeDialog();
			},
			onSuccess() {
				toast.success("Il tuo account è stato aggiunto al ruolo Driver con successo");
				queryClient.invalidateQueries({ queryKey: ["/api/user/me"] }).then(r => null);
				closeDialog();
			},
		});
	}

	return (
		<div className="flex justify-between gap-3 overflow-hidden items-center p-3 bg-background-content w-full mb-3 rounded-container">
			<Input
				placeholder="Cerca driver..."
				value={table.getColumn("name")?.getFilterValue() as string}
				onChange={event => table.getColumn("name")?.setFilterValue(event.target.value)}
				variant={"outline"}
				className="w-48"
			/>
			<div>
				{(getMeCache as GetMeData | undefined)?.data.nccteams[0]?.roles.includes("Owner") &&
				!(getMeCache as GetMeData | undefined)?.data.nccteams[0]?.roles.includes("Driver") ? (
					<Button
						onClick={addMySelfAsDriver}
						className="px-4"
					>
						Diventa Driver
					</Button>
				) : null}
			</div>
			<div>
				<Button
					onClick={openDialog}
					className="px-4"
				>
					Invita driver
				</Button>
				<Dialog
					isOpen={isDialogOpen}
					onClose={closeDialog}
					title="Invita driver"
				>
					<Form {...form}>
						<form
							className="w-80"
							onSubmit={form.handleSubmit(onSubmit)}
						>
							<div className="flex flex-col gap-10 items-center justify-center ">
								<FormField
									control={form.control}
									name="email"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Email</FormLabel>
											<FormControl>
												<Input
													type="email"
													placeholder="inserisci l'email"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<div className="flex space-x-2">
									<Button
										type="submit"
										size={"xl"}
									>
										Salva
									</Button>
									<Button
										type="button"
										size={"xl"}
										variant={"outline"}
										onClick={closeDialog}
									>
										Annulla
									</Button>
								</div>
							</div>
						</form>
					</Form>
				</Dialog>
			</div>
		</div>
	);
};

import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiTransportTierPricingCreateMutationRequest, PostApiTransportTierPricingCreateMutationResponse, PostApiTransportTierPricingCreateHeaderParams, PostApiTransportTierPricingCreate403 } from "../models/PostApiTransportTierPricingCreate";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiTransportTierPricingCreateClient = typeof client<PostApiTransportTierPricingCreateMutationResponse, PostApiTransportTierPricingCreate403, PostApiTransportTierPricingCreateMutationRequest>;
type PostApiTransportTierPricingCreate = {
    data: PostApiTransportTierPricingCreateMutationResponse;
    error: PostApiTransportTierPricingCreate403;
    request: PostApiTransportTierPricingCreateMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiTransportTierPricingCreateHeaderParams;
    response: PostApiTransportTierPricingCreateMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiTransportTierPricingCreateClient>[0]>;
        return: Awaited<ReturnType<PostApiTransportTierPricingCreateClient>>;
    };
};
/**
 * @description Creates a RideTierPricing and assigns it to calling NccTeam user. Calling user must be Owner of the passed NccTeam.Abort operation
 * @summary CreateRideTierPricing
 * @link /api/transport/tier/pricing/create
 */
export function usePostApiTransportTierPricingCreateHook(headers?: PostApiTransportTierPricingCreate["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiTransportTierPricingCreate["response"], PostApiTransportTierPricingCreate["error"], PostApiTransportTierPricingCreate["request"]>;
    client?: PostApiTransportTierPricingCreate["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiTransportTierPricingCreateHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiTransportTierPricingCreate["data"], PostApiTransportTierPricingCreate["error"], PostApiTransportTierPricingCreate["request"]>({
                method: "post",
                url: `/api/transport/tier/pricing/create`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
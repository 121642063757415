import { z } from "zod";


export const getApiTeamInvitesDeclineTokenPathParamsSchema = z.object({ "token": z.string().describe("Token id for finding the invite") });


export const getApiTeamInvitesDeclineTokenHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiTeamInvitesDeclineToken200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const getApiTeamInvitesDeclineToken403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiTeamInvitesDeclineTokenQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiNccTeamUserRoleRemoveHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiNccTeamUserRoleRemove200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiNccTeamUserRoleRemove403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiNccTeamUserRoleRemoveMutationRequestSchema = z.object({ "id": z.number(), "user_id": z.number(), "role": z.string().describe("Must be one of <code>Driver</code>, <code>Manager</code>, <code>Owner</code>, or <code>User</code> Must not be greater than 64 characters.") });

 /**
 * @description Success
 */
export const postApiNccTeamUserRoleRemoveMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiPartyUserRemoveHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiPartyUserRemove200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiPartyUserRemove403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiPartyUserRemoveMutationRequestSchema = z.object({ "id": z.number(), "user_id": z.number() });

 /**
 * @description Success
 */
export const postApiPartyUserRemoveMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
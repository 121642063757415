import { z } from "zod";


export const getApiTransportVehicleOwnHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description 0
 */
export const getApiTransportVehicleOwn200Schema = z.object({ "data": z.array(z.object({})).optional() });

 /**
 * @description Not avaible
 */
export const getApiTransportVehicleOwn403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description 0
 */
export const getApiTransportVehicleOwnQueryResponseSchema = z.object({ "data": z.array(z.object({})).optional() });
import { z } from "zod";


export const postApiBackofficeConfigurationSetHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeConfigurationSet200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeConfigurationSet403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeConfigurationSetMutationRequestSchema = z.object({ "name": z.string().describe("Must not be greater than 64 characters."), "value": z.string().describe("Must not be greater than 128 characters."), "edit_reason": z.string() });

 /**
 * @description Success
 */
export const postApiBackofficeConfigurationSetMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
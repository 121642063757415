import { z } from "zod";


export const postApiTeamCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTeamCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const postApiTeamCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTeamCreateMutationRequestSchema = z.object({ "name": z.string().describe("Must contain only letters, numbers, dashes and underscores. Must not be greater than 64 characters."), "description": z.string().describe("Must not be greater than 255 characters.").optional() });

 /**
 * @description Success
 */
export const postApiTeamCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
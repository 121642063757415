import { z } from "zod";


export const getApiBackofficeTransportRideTierPricingListRidetierIdPathParamsSchema = z.object({ "rideTier_id": z.number().describe("The ID of the RideTier.") });


export const getApiBackofficeTransportRideTierPricingListRidetierIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description 0
 */
export const getApiBackofficeTransportRideTierPricingListRidetierId200Schema = z.object({ "data": z.array(z.object({})).optional() });

 /**
 * @description Not available
 */
export const getApiBackofficeTransportRideTierPricingListRidetierId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description 0
 */
export const getApiBackofficeTransportRideTierPricingListRidetierIdQueryResponseSchema = z.object({ "data": z.array(z.object({})).optional() });
import { env } from '@/env';
import { AUTH_CRED } from '@/hooks/auth/constants';
import axios, { AxiosError, type AxiosRequestConfig, type AxiosResponse, isAxiosError } from 'axios';
import Cookies from 'js-cookie';

/**
 * Subset of AxiosRequestConfig
 */
type RequestConfig<TData = unknown> = {
  url?: string
  method: 'get' | 'put' | 'patch' | 'post' | 'delete'
  params?: object & {page?:number}
  data?: TData
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream'
  signal?: AbortSignal
  headers?: AxiosRequestConfig['headers']
}
/**
 * Subset of AxiosResponse
 */
type ResponseConfig<TData = unknown> = {
  data: TData
  status: number
  statusText: string
  headers?: AxiosResponse['headers']
}


/**
 * Axios instance for making HTTP requests.
 */
const axiosInstance = axios.create({
	timeout: 20000,
  baseURL:env.VITE_BACKEND_URL,
	headers: {
		Accept: "application/json",
		"Access-Control-Allow-Origin": "*",
		"Content-Type": "application/json",
    "Accept-Language":"it-IT"
	},
})

/**
 * Sends a request using Axios and returns a promise that resolves to the response data.
 *
 * @param config - The request configuration.
 * @returns A promise that resolves to the response data.
 * @throws An AxiosError if the request fails.
 * @typeParam TData - The type of the response data.
 * @typeParam TError - The type of the error response data (if any).
 * @typeParam TVariables - The type of the request variables.
 */
const axiosClient = async <TData, TError = unknown, TVariables = unknown>(config: RequestConfig<TVariables>): Promise<ResponseConfig<TData>> => {
  const promise = axiosInstance.request<TVariables, ResponseConfig<TData>>({ ...config }).catch((e: AxiosError<TError>) => {
    throw e
  })

  return promise
}

axiosInstance.interceptors.request.use(
	config => {
    //Add the authorization header using the Bearer token from the BE
		config.headers.Authorization = `Bearer ${Cookies.get(AUTH_CRED)}`;
		return config;
	}
);

export { type RequestConfig, type ResponseConfig,  axiosInstance, axiosClient,axiosClient as default };

import { z } from "zod";


export const postApiPartyInvitesDeleteHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiPartyInvitesDelete200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiPartyInvitesDelete403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiPartyInvitesDeleteMutationRequestSchema = z.object({ "id": z.number(), "invite_id": z.number() });

 /**
 * @description Success
 */
export const postApiPartyInvitesDeleteMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
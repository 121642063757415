import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiTransportTierPricingDeleteMutationRequest, PostApiTransportTierPricingDeleteMutationResponse, PostApiTransportTierPricingDeleteHeaderParams, PostApiTransportTierPricingDelete403 } from "../models/PostApiTransportTierPricingDelete";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiTransportTierPricingDeleteClient = typeof client<PostApiTransportTierPricingDeleteMutationResponse, PostApiTransportTierPricingDelete403, PostApiTransportTierPricingDeleteMutationRequest>;
type PostApiTransportTierPricingDelete = {
    data: PostApiTransportTierPricingDeleteMutationResponse;
    error: PostApiTransportTierPricingDelete403;
    request: PostApiTransportTierPricingDeleteMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiTransportTierPricingDeleteHeaderParams;
    response: PostApiTransportTierPricingDeleteMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiTransportTierPricingDeleteClient>[0]>;
        return: Awaited<ReturnType<PostApiTransportTierPricingDeleteClient>>;
    };
};
/**
 * @description Deletes a RideTierPricing. Calling user must be Owner of the passed NccTeam.Abort operation
 * @summary DeleteRideTierPricing
 * @link /api/transport/tier/pricing/delete
 */
export function usePostApiTransportTierPricingDeleteHook(headers?: PostApiTransportTierPricingDelete["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiTransportTierPricingDelete["response"], PostApiTransportTierPricingDelete["error"], PostApiTransportTierPricingDelete["request"]>;
    client?: PostApiTransportTierPricingDelete["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiTransportTierPricingDeleteHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiTransportTierPricingDelete["data"], PostApiTransportTierPricingDelete["error"], PostApiTransportTierPricingDelete["request"]>({
                method: "post",
                url: `/api/transport/tier/pricing/delete`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
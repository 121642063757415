import { z } from "zod";


export const postApiPartyCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiPartyCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const postApiPartyCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiPartyCreateMutationRequestSchema = z.object({ "name": z.string().describe("Must not be greater than 64 characters."), "description": z.string().describe("Must not be greater than 128 characters.").optional(), "buying_item_type": z.string().describe("Must be one of <code>TableTier</code> or <code>Ride</code>."), "buying_item_id": z.number() });

 /**
 * @description Success
 */
export const postApiPartyCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
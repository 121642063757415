import { z } from "zod";


export const getApiTeamInvitesAcceptTokenPathParamsSchema = z.object({ "token": z.string().describe("Token id for finding the invite") });


export const getApiTeamInvitesAcceptTokenHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiTeamInvitesAcceptToken200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const getApiTeamInvitesAcceptToken403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiTeamInvitesAcceptTokenQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiTicketingTicketTierEditHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTicketingTicketTierEdit200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not avaible
 */
export const postApiTicketingTicketTierEdit403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTicketingTicketTierEditMutationRequestSchema = z.object({ "team_id": z.number(), "id": z.number(), "description": z.string().describe("Must not be greater than 100 characters."), "rules": z.string().describe("Must not be greater than 64 characters.").optional(), "quantity": z.number(), "expires_at": z.string().describe("Must be a valid date. Must be a date after <code>yesterday</code>.").optional() });

 /**
 * @description Success
 */
export const postApiTicketingTicketTierEditMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiTransportTierCreateMutationRequest, PostApiTransportTierCreateMutationResponse, PostApiTransportTierCreateHeaderParams, PostApiTransportTierCreate403 } from "../models/PostApiTransportTierCreate";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiTransportTierCreateClient = typeof client<PostApiTransportTierCreateMutationResponse, PostApiTransportTierCreate403, PostApiTransportTierCreateMutationRequest>;
type PostApiTransportTierCreate = {
    data: PostApiTransportTierCreateMutationResponse;
    error: PostApiTransportTierCreate403;
    request: PostApiTransportTierCreateMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiTransportTierCreateHeaderParams;
    response: PostApiTransportTierCreateMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiTransportTierCreateClient>[0]>;
        return: Awaited<ReturnType<PostApiTransportTierCreateClient>>;
    };
};
/**
 * @description Creates a RideTier for the passed NccTeam. Calling user must be Owner of that Team.Abort operation
 * @summary CreateRideTier
 * @link /api/transport/tier/create
 */
export function usePostApiTransportTierCreateHook(headers?: PostApiTransportTierCreate["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiTransportTierCreate["response"], PostApiTransportTierCreate["error"], PostApiTransportTierCreate["request"]>;
    client?: PostApiTransportTierCreate["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiTransportTierCreateHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiTransportTierCreate["data"], PostApiTransportTierCreate["error"], PostApiTransportTierCreate["request"]>({
                method: "post",
                url: `/api/transport/tier/create`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
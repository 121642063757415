// import { useUserFetch } from "@/api/hooks/auth/user.query";
import { Button, buttonVariants } from "@/components/ui/button";
import { useNccTeamIdStore } from "@/hooks/store/main";
import { cn } from "@/lib/utils";
import { Link, useRouterState } from "@tanstack/react-router";
import type { FileRoutesByPath } from "@tanstack/react-router";
import { useState } from "react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import type { SidebarLinkConfig } from "types/main";
import logo from "../../assets/MainLogo.png";

/**
 * Array of sidebar link configurations.
 * Each configuration object represents a link in the sidebar menu.
 */
const routes: SidebarLinkConfig[] = [
	{ role: null, to: "/webpanel/veichles/", label: "Veicoli" },
	{ role: null, to: "/webpanel/drivers/management", label: "Driver" },
	{ role: null, to: "/webpanel/rates/", label: "Tariffe" },
	{ role: null, to: "/webpanel/rides/", label: "Corse" },
	{ role: null, to: "/webpanel/garage/", label: "Autorimessa" },
];

/**
 * Sidebar component that displays navigation links and a toggle button.
 * @returns The Sidebar component.
 */
const Sidebar = () => {
	const location = useRouterState({ select: s => s.location });
	const currentUrlLocation = useRouterState().location.pathname;
	const [visible, setVisible] = useState(true);
	const width = "w-56 sm:w-40 lg:w-64";

	if (location.pathname === "/login") {
		return null;
	}

	return (
		<div
			className={cn({
				inputs: [
					"relative z-10 w-0 sm:block transition-all duration-700 ease-mini-bump",
					visible ? "sm:w-40 lg:w-64" : "w-0",
				],
			})}
		>
			<div className="absolute h-full">
				<div
					className={cn({
						inputs: [
							"bg-background-base h-full relative transition-all duration-700 ease-mini-bump",
							visible ? width : "w-0",
						],
					})}
				>
					<Button
						size={"icon"}
						className="absolute top-0 left-full shadow-md"
						onClick={() => setVisible(!visible)}
						key="toggle"
					>
						{visible ? <GoChevronLeft className="h-6 w-6" /> : <GoChevronRight className="h-6 w-6" />}
					</Button>
					<nav
						className={cn({
							inputs: [
								"h-full absolute top-0 right-0 bg-alt flex flex-col gap-5 p-5 border-muted border-r-2 duration-200",
								width,
							],
						})}
					>
						{routes.map(link => {
							if (link.to.endsWith("/")) {
								link.to = link.to.slice(0, -1) as keyof FileRoutesByPath;
							}
							return (
								<Link
									key={link.label}
									className={buttonVariants({
										size: "lg",
										variant: currentUrlLocation === (link.to as string) ? "default" : "outline",
									})}
									to={link.to}
								>
									{link.label}
								</Link>
							);
						})}
						<div className="absolute bottom-8 left-8 w-12">
							<img
								src={logo}
								alt="MainLogo"
							/>
						</div>
					</nav>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;

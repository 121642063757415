import { z } from "zod";


export const getApiTransportTierPricingPricingGetRidetierpricingIdPathParamsSchema = z.object({ "rideTierPricing_id": z.number().describe("The ID of the RideTierPricing.") });


export const getApiTransportTierPricingPricingGetRidetierpricingIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiTransportTierPricingPricingGetRidetierpricingId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const getApiTransportTierPricingPricingGetRidetierpricingId404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiTransportTierPricingPricingGetRidetierpricingIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
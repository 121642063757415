import client from "../../templatesSDK/client";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { GetApiNccTeamGetNccteamIdQueryResponse, GetApiNccTeamGetNccteamIdPathParams, GetApiNccTeamGetNccteamIdHeaderParams, GetApiNccTeamGetNccteamId403 } from "../models/GetApiNccTeamGetNccteamId";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type GetApiNccTeamGetNccteamIdClient = typeof client<GetApiNccTeamGetNccteamIdQueryResponse, GetApiNccTeamGetNccteamId403, never>;
type GetApiNccTeamGetNccteamId = {
    data: GetApiNccTeamGetNccteamIdQueryResponse;
    error: GetApiNccTeamGetNccteamId403;
    request: never;
    pathParams: GetApiNccTeamGetNccteamIdPathParams;
    queryParams: never;
    headerParams: GetApiNccTeamGetNccteamIdHeaderParams;
    response: GetApiNccTeamGetNccteamIdQueryResponse;
    client: {
        parameters: Partial<Parameters<GetApiNccTeamGetNccteamIdClient>[0]>;
        return: Awaited<ReturnType<GetApiNccTeamGetNccteamIdClient>>;
    };
};
export const getApiNccTeamGetNccteamIdQueryKey = (nccTeamId: GetApiNccTeamGetNccteamIdPathParams["nccTeam_id"]) => ["/api/ncc-team/get/:nccTeam_id"] as const;
export type GetApiNccTeamGetNccteamIdQueryKey = ReturnType<typeof getApiNccTeamGetNccteamIdQueryKey>;
export function getApiNccTeamGetNccteamIdQueryOptions(nccTeamId: GetApiNccTeamGetNccteamIdPathParams["nccTeam_id"], headers?: GetApiNccTeamGetNccteamId["headerParams"], options: GetApiNccTeamGetNccteamId["client"]["parameters"] = {}) {
    const queryKey = getApiNccTeamGetNccteamIdQueryKey(nccTeamId);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiNccTeamGetNccteamId["data"], GetApiNccTeamGetNccteamId["error"]>({
                method: "get",
                url: `/api/ncc-team/get/${nccTeamId}`,
                headers: { ...headers, ...options.headers },
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Gets the passed NccTeam. Calling User must be User of related NccTeamAbort operation
 * @summary GetNccTeam
 * @link /api/ncc-team/get/:nccTeam_id
 */
export function useGetApiNccTeamGetNccteamIdHook<TData = GetApiNccTeamGetNccteamId["response"], TQueryData = GetApiNccTeamGetNccteamId["response"], TQueryKey extends QueryKey = GetApiNccTeamGetNccteamIdQueryKey>(nccTeamId: GetApiNccTeamGetNccteamIdPathParams["nccTeam_id"], headers?: GetApiNccTeamGetNccteamId["headerParams"], options: {
    query?: Partial<QueryObserverOptions<GetApiNccTeamGetNccteamId["response"], GetApiNccTeamGetNccteamId["error"], TData, TQueryData, TQueryKey>>;
    client?: GetApiNccTeamGetNccteamId["client"]["parameters"];
} = {}): UseQueryResult<TData, GetApiNccTeamGetNccteamId["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiNccTeamGetNccteamIdQueryKey(nccTeamId);
    const query = useQuery({
        ...getApiNccTeamGetNccteamIdQueryOptions(nccTeamId, headers, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, GetApiNccTeamGetNccteamId["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const getApiNccTeamGetNccteamIdSuspenseQueryKey = (nccTeamId: GetApiNccTeamGetNccteamIdPathParams["nccTeam_id"]) => ["/api/ncc-team/get/:nccTeam_id"] as const;
export type GetApiNccTeamGetNccteamIdSuspenseQueryKey = ReturnType<typeof getApiNccTeamGetNccteamIdSuspenseQueryKey>;
export function getApiNccTeamGetNccteamIdSuspenseQueryOptions(nccTeamId: GetApiNccTeamGetNccteamIdPathParams["nccTeam_id"], headers?: GetApiNccTeamGetNccteamId["headerParams"], options: GetApiNccTeamGetNccteamId["client"]["parameters"] = {}) {
    const queryKey = getApiNccTeamGetNccteamIdSuspenseQueryKey(nccTeamId);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiNccTeamGetNccteamId["data"], GetApiNccTeamGetNccteamId["error"]>({
                method: "get",
                url: `/api/ncc-team/get/${nccTeamId}`,
                headers: { ...headers, ...options.headers },
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Gets the passed NccTeam. Calling User must be User of related NccTeamAbort operation
 * @summary GetNccTeam
 * @link /api/ncc-team/get/:nccTeam_id
 */
export function useGetApiNccTeamGetNccteamIdHookSuspense<TData = GetApiNccTeamGetNccteamId["response"], TQueryKey extends QueryKey = GetApiNccTeamGetNccteamIdSuspenseQueryKey>(nccTeamId: GetApiNccTeamGetNccteamIdPathParams["nccTeam_id"], headers?: GetApiNccTeamGetNccteamId["headerParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<GetApiNccTeamGetNccteamId["response"], GetApiNccTeamGetNccteamId["error"], TData, TQueryKey>>;
    client?: GetApiNccTeamGetNccteamId["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, GetApiNccTeamGetNccteamId["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiNccTeamGetNccteamIdSuspenseQueryKey(nccTeamId);
    const query = useSuspenseQuery({
        ...getApiNccTeamGetNccteamIdSuspenseQueryOptions(nccTeamId, headers, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, GetApiNccTeamGetNccteamId["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
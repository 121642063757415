import { z } from "zod";


export const postApiTransportVehicleCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportVehicleCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const postApiTransportVehicleCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportVehicleCreateMutationRequestSchema = z.object({ "plaque": z.string().describe("Must not be greater than 10 characters."), "seats": z.number().describe("Must be between 1 and 128."), "registered_at": z.string().describe("Must be a valid date."), "brand": z.string().describe("Must not be greater than 16 characters."), "model": z.string().describe("Must not be greater than 16 characters."), "vehicle_type_id": z.number(), "ncc_team_id": z.number(), "ncc_location_id": z.number() });

 /**
 * @description Success
 */
export const postApiTransportVehicleCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
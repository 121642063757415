import { useSetLocaleLanguageHook } from "@/components/auth/_SetLocaleLanguageHook";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { getAuthCredentials } from "@/hooks/auth/auth-utils";
import { AUTH_CRED } from "@/hooks/auth/constants";
import useDeviceDetection from "@/hooks/useDeviceDetection";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { usePostApiUserAuthLoginHook } from "api/gen";
import axiosClient from "api/templatesSDK/client";
import { AxiosError } from "axios";
import Cookie from "js-cookie";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { GoSync } from "react-icons/go";
import { z } from "zod";

type TLoginRes = {
	status: string;
	message: string;
	data: Data;
};

interface Data {
	token: string;
	roles: string;
}

/**
 * User authentication form component.
 * This component handles user login and authentication.
 */
export function UserAuthForm() {
	const { mutate: login, isPending: loading } = usePostApiUserAuthLoginHook();
	const { mutate: setLocaleLng } = useSetLocaleLanguageHook();
	const router = useNavigate();
	const device_name = useDeviceDetection();

	/**
	 * Schema for the authentication form.
	 */
	const AuthFormSchema = z.object({
		email: z.string().email({ message: "Email non valida" }),
		device_name: z.string(),
		password: z.string(),
	});

	/**
	 * Represents the form instance for user authentication.
	 *
	 * @remarks
	 * This form is used for handling user authentication data.
	 *
	 * @typeParam T - The type of the form data.
	 */
	const form = useForm<z.infer<typeof AuthFormSchema>>({
		mode: "all",
		resolver: zodResolver(AuthFormSchema),
		defaultValues: {
			password: "",
			device_name: device_name || "Desktop",
			email: "",
		},
	});

	/**
	 * Handles the form submission for user authentication.
	 *
	 * @param {Object} formData - The form data containing the password, email, and device name.
	 */
	function onSubmit({ password, email, device_name }: z.infer<typeof AuthFormSchema>) {
		login(
			{
				email,
				password,
				device_name: device_name || "Desktop",
			},
			{
				onSuccess: tokenRes => {
					const res = tokenRes.data as TLoginRes["data"];
					if (res.token) {
						Cookie.set(AUTH_CRED, res.token);
						if (getAuthCredentials().token) router({ to: "/webpanel/veichles", search: { page: 1 } });
						setLocaleLng();
						toast.success("Accesso effettuato con successo");
					}
				},
				onError: error => {
					let errorMessage = "Richiesta non valida, contatta il servizio clienti";
					if (error instanceof AxiosError && error.message !== "") {
						errorMessage = error.message;
					} else if (error instanceof AxiosError && error.response?.data.message !== "") {
						errorMessage = error.response?.data.message;
					}

					toast.error(errorMessage);
				},
			},
		);
	}

	return (
		<Form {...form}>
			<form
				onSubmit={form.handleSubmit(onSubmit)}
				className="w-full flex flex-col gap-3"
			>
				<FormField
					control={form.control}
					name="email"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Email</FormLabel>
							<FormControl>
								<Input
									className="w-full"
									id="email"
									placeholder="Email"
									type="email"
									autoCapitalize="none"
									autoComplete="email"
									autoCorrect="off"
									disabled={loading}
									{...field}
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="password"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Password</FormLabel>
							<FormControl>
								<Input
									className="w-full"
									id="password"
									placeholder="Password"
									type="password"
									autoCapitalize="none"
									autoComplete="password"
									autoCorrect="off"
									disabled={loading}
									{...field}
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<Button
					type="submit"
					disabled={loading}
					variant={"default"}
					className="items-center place-content-center justify-center w-full mt-7"
				>
					{loading && <GoSync className="mr-2 h-4 w-4 animate-spin direction-reverse" />} Accedi
				</Button>
			</form>
		</Form>
	);
}

import { z } from "zod";


export const postApiBackofficeTransportRideTierPricingCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeTransportRideTierPricingCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeTransportRideTierPricingCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeTransportRideTierPricingCreateMutationRequestSchema = z.object({ "ride_tier_id": z.number(), "max_distance": z.number().optional(), "km_price": z.string().describe("KM price fee") });

 /**
 * @description Success
 */
export const postApiBackofficeTransportRideTierPricingCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).optional() });
import { useEffect, useState } from "react";

/**
 * Custom hook to detect the device type (Mobile, Tablet, or Desktop).
 * @returns The detected device type as a string, or undefined if the device type cannot be determined.
 */
function useDeviceDetection(): string | undefined {
	const [device, setDevice] = useState("");

	useEffect(() => {
		/**
		 * Detects the type of device based on the user agent and sets the device state accordingly.
		 */
		function handleDeviceDetection() {
			const userAgent = navigator.userAgent.toLowerCase();
			const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
			const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

			if (isMobile) {
				setDevice("Mobile");
			} else if (isTablet) {
				setDevice("Tablet");
			} else {
				setDevice("Desktop");
			}
		}

		handleDeviceDetection();
		window.addEventListener("resize", handleDeviceDetection);

		return () => {
			window.removeEventListener("resize", handleDeviceDetection);
		};
	}, []);

	return device as string | undefined;
}

export default useDeviceDetection;

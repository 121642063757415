import { cn } from "@/lib/utils";
import type React from "react";

/**
 * Switch component props
 */
interface SwitchProps {
	/**
	 * Indicates whether the switch is checked or not
	 */
	checked?: boolean;

	className?: string;
	defaultChecked?: boolean;
	/**
	 * Event handler for when the switch is toggled
	 * @param checked - The new checked state of the switch
	 */
	onChange: (checked: boolean) => void;
}

/**
 * Switch component
 * @param checked - Indicates whether the switch is checked or not
 * @param onChange - Event handler for when the switch is toggled
 * @returns JSX element representing the switch component
 *
 * @example
 * ```tsx
 * const App: React.FC = () => {
 *   const [isSwitchOn, setIsSwitchOn] = useState(false);
 *
 *   // This function toggles the switch state
 *   const handleSwitchChange = (checked: boolean) => {
 *     setIsSwitchOn(checked); // This will toggle the state, moving the thumb to the opposite side
 *   };
 *
 *   return (
 *     <div className="flex justify-center items-center h-screen">
 *       <Switch checked={isSwitchOn} onChange={handleSwitchChange} />
 *     </div>
 *   );
 * };
 * ```
 */
const Switch: React.FC<SwitchProps> = ({ checked, onChange, defaultChecked }) => {
	return (
		<label className="inline-flex items-center cursor-pointer">
			<input
				type="checkbox"
				className="sr-only"
				// defaultChecked={defaultChecked}
				checked={checked}
				onChange={e => onChange(e.target.checked)}
			/>
			<div
				className={cn({
					inputs: [
						"relative w-14 h-7 transition duration-50 ease-linear rounded-full",
						checked ? "bg-accent" : "bg-foreground-muted",
					],
				})}
			>
				<span
					className={cn({
						inputs: [
							"absolute left-2 top-2 bottom-2 m-auto h-5 w-5 transform transition ease-out duration-50 rounded-full bg-foreground-50 shadow-md",
							checked ? "translate-x-6" : "-translate-x-1",
						],
					})}
				/>
			</div>
		</label>
	);
};

export default Switch;

import { z } from "zod";


export const postApiInvoicePayInvoiceIdPathParamsSchema = z.object({ "invoice_id": z.number().describe("Invoice id to search for") });


export const postApiInvoicePayInvoiceIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiInvoicePayInvoiceId200Schema = z.string();

 /**
 * @description Not available
 */
export const postApiInvoicePayInvoiceId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const postApiInvoicePayInvoiceIdMutationResponseSchema = z.string();
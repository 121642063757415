import { z } from "zod";


export const postApiBackofficeTagDeleteTagIdPathParamsSchema = z.object({ "tag_id": z.number().describe("Tag id to search for") });


export const postApiBackofficeTagDeleteTagIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeTagDeleteTagId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeTagDeleteTagId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const postApiBackofficeTagDeleteTagIdMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
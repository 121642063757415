import { z } from "zod";


export const postApiTransportRiderStatusChangeHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportRiderStatusChange200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not avaible
 */
export const postApiTransportRiderStatusChange403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportRiderStatusChangeMutationRequestSchema = z.object({ "status": z.string().describe("Must be one of <code>Offline</code> or <code>Online</code> Must not be greater than 8 characters.") });

 /**
 * @description Success
 */
export const postApiTransportRiderStatusChangeMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
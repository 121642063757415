import client from "../../templatesSDK/client";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { GetApiNccTeamNccLocationListNccTeamIdQueryResponse, GetApiNccTeamNccLocationListNccTeamIdPathParams, GetApiNccTeamNccLocationListNccTeamIdHeaderParams, GetApiNccTeamNccLocationListNccTeamId404 } from "../models/GetApiNccTeamNccLocationListNccteamId";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type GetApiNccTeamNccLocationListNccTeamIdClient = typeof client<GetApiNccTeamNccLocationListNccTeamIdQueryResponse, GetApiNccTeamNccLocationListNccTeamId404, never>;
type GetApiNccTeamNccLocationListNccTeamId = {
    data: GetApiNccTeamNccLocationListNccTeamIdQueryResponse;
    error: GetApiNccTeamNccLocationListNccTeamId404;
    request: never;
    pathParams: GetApiNccTeamNccLocationListNccTeamIdPathParams;
    queryParams: never;
    headerParams: GetApiNccTeamNccLocationListNccTeamIdHeaderParams;
    response: GetApiNccTeamNccLocationListNccTeamIdQueryResponse;
    client: {
        parameters: Partial<Parameters<GetApiNccTeamNccLocationListNccTeamIdClient>[0]>;
        return: Awaited<ReturnType<GetApiNccTeamNccLocationListNccTeamIdClient>>;
    };
};
export const getApiNccTeamNccLocationListNccTeamIdQueryKey = (nccTeamId: GetApiNccTeamNccLocationListNccTeamIdPathParams["ncc_team_id"]) => ["/api/ncc-team/ncc-location/list/:ncc_team_id"] as const;
export type GetApiNccTeamNccLocationListNccTeamIdQueryKey = ReturnType<typeof getApiNccTeamNccLocationListNccTeamIdQueryKey>;
export function getApiNccTeamNccLocationListNccTeamIdQueryOptions(nccTeamId: GetApiNccTeamNccLocationListNccTeamIdPathParams["ncc_team_id"], headers?: GetApiNccTeamNccLocationListNccTeamId["headerParams"], options: GetApiNccTeamNccLocationListNccTeamId["client"]["parameters"] = {}) {
    const queryKey = getApiNccTeamNccLocationListNccTeamIdQueryKey(nccTeamId);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiNccTeamNccLocationListNccTeamId["data"], GetApiNccTeamNccLocationListNccTeamId["error"]>({
                method: "get",
                url: `/api/ncc-team/ncc-location/list/${nccTeamId}`,
                headers: { ...headers, ...options.headers },
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Views all the NccLocations of the calling NccTeamAbort operation
 * @summary GetTeamNccLocations
 * @link /api/ncc-team/ncc-location/list/:ncc_team_id
 */
export function useGetApiNccTeamNccLocationListNccTeamIdHook<TData = GetApiNccTeamNccLocationListNccTeamId["response"], TQueryData = GetApiNccTeamNccLocationListNccTeamId["response"], TQueryKey extends QueryKey = GetApiNccTeamNccLocationListNccTeamIdQueryKey>(nccTeamId: GetApiNccTeamNccLocationListNccTeamIdPathParams["ncc_team_id"], headers?: GetApiNccTeamNccLocationListNccTeamId["headerParams"], options: {
    query?: Partial<QueryObserverOptions<GetApiNccTeamNccLocationListNccTeamId["response"], GetApiNccTeamNccLocationListNccTeamId["error"], TData, TQueryData, TQueryKey>>;
    client?: GetApiNccTeamNccLocationListNccTeamId["client"]["parameters"];
} = {}): UseQueryResult<TData, GetApiNccTeamNccLocationListNccTeamId["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiNccTeamNccLocationListNccTeamIdQueryKey(nccTeamId);
    const query = useQuery({
        ...getApiNccTeamNccLocationListNccTeamIdQueryOptions(nccTeamId, headers, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, GetApiNccTeamNccLocationListNccTeamId["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const getApiNccTeamNccLocationListNccTeamIdSuspenseQueryKey = (nccTeamId: GetApiNccTeamNccLocationListNccTeamIdPathParams["ncc_team_id"]) => ["/api/ncc-team/ncc-location/list/:ncc_team_id"] as const;
export type GetApiNccTeamNccLocationListNccTeamIdSuspenseQueryKey = ReturnType<typeof getApiNccTeamNccLocationListNccTeamIdSuspenseQueryKey>;
export function getApiNccTeamNccLocationListNccTeamIdSuspenseQueryOptions(nccTeamId: GetApiNccTeamNccLocationListNccTeamIdPathParams["ncc_team_id"], headers?: GetApiNccTeamNccLocationListNccTeamId["headerParams"], options: GetApiNccTeamNccLocationListNccTeamId["client"]["parameters"] = {}) {
    const queryKey = getApiNccTeamNccLocationListNccTeamIdSuspenseQueryKey(nccTeamId);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiNccTeamNccLocationListNccTeamId["data"], GetApiNccTeamNccLocationListNccTeamId["error"]>({
                method: "get",
                url: `/api/ncc-team/ncc-location/list/${nccTeamId}`,
                headers: { ...headers, ...options.headers },
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Views all the NccLocations of the calling NccTeamAbort operation
 * @summary GetTeamNccLocations
 * @link /api/ncc-team/ncc-location/list/:ncc_team_id
 */
export function useGetApiNccTeamNccLocationListNccTeamIdHookSuspense<TData = GetApiNccTeamNccLocationListNccTeamId["response"], TQueryKey extends QueryKey = GetApiNccTeamNccLocationListNccTeamIdSuspenseQueryKey>(nccTeamId: GetApiNccTeamNccLocationListNccTeamIdPathParams["ncc_team_id"], headers?: GetApiNccTeamNccLocationListNccTeamId["headerParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<GetApiNccTeamNccLocationListNccTeamId["response"], GetApiNccTeamNccLocationListNccTeamId["error"], TData, TQueryKey>>;
    client?: GetApiNccTeamNccLocationListNccTeamId["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, GetApiNccTeamNccLocationListNccTeamId["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiNccTeamNccLocationListNccTeamIdSuspenseQueryKey(nccTeamId);
    const query = useSuspenseQuery({
        ...getApiNccTeamNccLocationListNccTeamIdSuspenseQueryOptions(nccTeamId, headers, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, GetApiNccTeamNccLocationListNccTeamId["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
import { z } from "zod";


export const postApiTicketingTicketGetEventHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTicketingTicketGetEvent200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const postApiTicketingTicketGetEvent403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTicketingTicketGetEventMutationRequestSchema = z.object({ "team_id": z.number(), "event_id": z.number() });

 /**
 * @description Success
 */
export const postApiTicketingTicketGetEventMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import type { Drivers } from "@/routes/webpanel/drivers/_types";
import type { Veichles } from "@/routes/webpanel/veichles/_types";
import type { IPagination } from "@/types";
import type axiosClient from "api/templatesSDK/client";
import { z } from "zod";

export interface ResRides extends IPagination {
	data: Rides[];
}
export interface Rides {
	id: number;
	from_address: string;
	from_latitude: number;
	from_longitude: number;
	to_address: string;
	to_latitude: number;
	to_longitude: number;
	pickup_at: string;
	payed_at: string;
	accepted_at: Date;
	started_at: Date;
	finished_at: Date;
	refunded_at: Date;
	state: string;
	real_distance: number;
	linear_distance: number;
	extra_fee: number;
	km_price: number;
	party: string;
	price: string;
	user: UserRides;
	passengers: number;
	vehicle?: Veichles;
	driver?: Drivers;
}

interface UserRides {
	id: number;
	name: string;
	phone: string;
}

export interface AvaildableDrivers {
	data: Data;
}
export interface Data {
	id: number;
	name: string;
	description: string;
	company_country: string;
	company_fiscal_code: string;
	company_tax_code: string;
	opening_hours: OpeningHour[];
	active: boolean;
	role: string[];
	users: User[];
}

export interface OpeningHour {
	"@type": string;
	dayOfWeek: string;
	opens: string;
	closes: string;
}

export interface User {
	id: number;
	name: string;
	roles: string[];
}

export interface AvailableVehicles extends IPagination {
	data: AvailableVehiclesData[];
}

export interface AvailableVehiclesData {
	id: number;
	plaque: string;
	seats: number;
	registered_at: string;
	brand: string;
	model: string;
	active: boolean;
	available: boolean;
	vehicle_type: VehicleType;
	ncc_location: NccLocation;
	ncc_team: NccTeam;
}

export interface VehicleType {
	id: number;
	name: string;
	title: string;
}

export interface NccLocation {
	id: number;
	ncc_team_id: number;
	name: string;
	description: string;
	address: string;
	latitude: string;
	longitude: string;
	action_range: string;
	active: boolean;
	available: boolean;
}

export interface NccTeam {
	id: number;
	name: string;
	description: string;
}

export interface PaginatedRides extends IPagination {
	data: ResRides[];
	status?: string;
	message?: string;
}

type GetApiTransportNccTeamRideListFilteredClient = typeof axiosClient<
	PaginatedRides,
	{
		status?: string;
		message?: string;
	},
	never
>;

export type GetApiTransportNccTeamRideListFiltered = {
	data: PaginatedRides;
	error: {
		status?: string;
		message?: string;
	};
	request: never;
	pathParams: {
		states: string[];
		ncc_team_id: number;
	};
	queryParams: never;
	response: PaginatedRides;
	client: {
		parameters: Partial<Parameters<GetApiTransportNccTeamRideListFilteredClient>[0]>;
		return: Awaited<ReturnType<GetApiTransportNccTeamRideListFilteredClient>>;
	};
};

export const ridesSearchSchema = z.object({
	page: z.number().catch(1),
});

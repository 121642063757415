import { z } from "zod";


export const postApiTransportTierEditHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportTierEdit200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiTransportTierEdit403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportTierEditMutationRequestSchema = z.object({ "id": z.number(), "name": z.string().describe("Must not be greater than 32 characters."), "description": z.string().describe("Must not be greater than 100 characters."), "rules": z.string().describe("Must not be greater than 64 characters.").optional(), "activation_fee": z.string().describe("Activation fee"), "max_seats": z.number().optional(), "public": z.boolean(), "vehicle_type_id": z.number(), "ncc_team_id": z.number() });

 /**
 * @description Success
 */
export const postApiTransportTierEditMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";
import ApiErrorsItJSON from "./locales/it/common.json";
import zodItJSON from "./locales/it/zod.json";

/**
 * Object containing the translation resources for different languages.
 *
 * @returns {Object} The translation resources for different languages.
 */
export const resourcesTranslation = {
	it: {
		...zodItJSON,
		translation: ApiErrorsItJSON,
	},
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: resourcesTranslation,
		lng: "it",
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

z.setErrorMap(makeZodI18nMap());

export default i18n;

import { z } from "zod";


export const postApiTransportTierPriceVariantPricingEditHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportTierPriceVariantPricingEdit200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiTransportTierPriceVariantPricingEdit403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportTierPriceVariantPricingEditMutationRequestSchema = z.object({ "id": z.number(), "max_distance": z.number().optional(), "km_price": z.string().describe("KM price fee") });

 /**
 * @description Success
 */
export const postApiTransportTierPriceVariantPricingEditMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiTeamSubscriptionsBuyHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTeamSubscriptionsBuy200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiTeamSubscriptionsBuy403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTeamSubscriptionsBuyMutationRequestSchema = z.object({ "token": z.string().describe("Must not be greater than 64 characters."), "team_id": z.number(), "plan": z.number() });

 /**
 * @description Success
 */
export const postApiTeamSubscriptionsBuyMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiTransportNccTeamRideConfirmMutationRequest, PostApiTransportNccTeamRideConfirmMutationResponse, PostApiTransportNccTeamRideConfirmPathParams, PostApiTransportNccTeamRideConfirmHeaderParams, PostApiTransportNccTeamRideConfirm403 } from "../models/PostApiTransportNccteamRideConfirm";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiTransportNccTeamRideConfirmClient = typeof client<PostApiTransportNccTeamRideConfirmMutationResponse, PostApiTransportNccTeamRideConfirm403, PostApiTransportNccTeamRideConfirmMutationRequest>;
type PostApiTransportNccTeamRideConfirm = {
    data: PostApiTransportNccTeamRideConfirmMutationResponse;
    error: PostApiTransportNccTeamRideConfirm403;
    request: PostApiTransportNccTeamRideConfirmMutationRequest;
    pathParams: PostApiTransportNccTeamRideConfirmPathParams;
    queryParams: never;
    headerParams: PostApiTransportNccTeamRideConfirmHeaderParams;
    response: PostApiTransportNccTeamRideConfirmMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiTransportNccTeamRideConfirmClient>[0]>;
        return: Awaited<ReturnType<PostApiTransportNccTeamRideConfirmClient>>;
    };
};
/**
 * @description Confirms the ride, and assigned passed Rider and Vehicle to the ride. Passed ride must be owned by calling User of related NccTeam.Calling User must be Owner or Manager. Passed Rider must be Driver for the related NccTeam. Passed Vehicle must be related to NccTeam.Abort operation
 * @summary ConfirmNccTeamRide
 * @link /api/transport/ncc-team/ride/confirm
 */
export function usePostApiTransportNccTeamRideConfirmHook(rideId: PostApiTransportNccTeamRideConfirmPathParams["ride_id"], headers?: PostApiTransportNccTeamRideConfirm["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiTransportNccTeamRideConfirm["response"], PostApiTransportNccTeamRideConfirm["error"], PostApiTransportNccTeamRideConfirm["request"]>;
    client?: PostApiTransportNccTeamRideConfirm["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiTransportNccTeamRideConfirmRideIdHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiTransportNccTeamRideConfirm["data"], PostApiTransportNccTeamRideConfirm["error"], PostApiTransportNccTeamRideConfirm["request"]>({
                method: "post",
                url: `/api/transport/ncc-team/ride/confirm`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
import { OnOffSelect } from "@/components/ui/onOffSelect";
import { useNccTeamIdStore } from "@/hooks/store/main";
import { cn, formatDate } from "@/lib/utils";
import type { Drivers } from "@/routes/webpanel/drivers/_types";
import type { ColumnDef } from "@tanstack/react-table";
import { usePostApiTransportRiderStatusChangeHook } from "api/gen";
import toast from "react-hot-toast";
import { usePostApiRiderAvailabilityChangeHook } from "./_useChangeAvailabilityRider";

export const columnsDrivers: ColumnDef<Drivers>[] = [
	{
		accessorKey: "name",
		accessorFn: row => {
			const [name, surname] = row.user.name.split(" ");
			return name;
		},
		header: "Nome",
		cell: ({ row }) => <div className="text-foreground-50">{row.getValue("name")}</div>,
	},
	{
		accessorKey: "surname",
		accessorFn: row => {
			const [name, surname] = row.user.name.split(" ");
			return surname;
		},
		header: "Cognome",
		cell: ({ row }) => <div className="text-foreground-50">{row.getValue("surname")}</div>,
	},
	{
		accessorKey: "dob",
		accessorFn: row => row.user.dob,
		header: "Data di nascita",
		cell: ({ row }) => <div className="text-foreground-50">{formatDate(row.getValue("dob"))}</div>,
	},
	{
		accessorKey: "license_number",
		header: "Patente",
		cell: ({ row }) => <div className="text-foreground-50">{row.getValue("license_number")}</div>,
	},
	{
		accessorKey: "status",
		header: () => <div>Status</div>,
		cell: ({ row }) => {
			const active: boolean = row.original.available;
			const { mutate: ChangeDriverStatus } = usePostApiRiderAvailabilityChangeHook();
			const { nccTeamId } = useNccTeamIdStore();

			return (
				<div className="w-32 -my-2">
					<OnOffSelect
						title="Seleziona uno status"
						name="stato"
						size="sm"
						className={cn({ inputs: [!active && "bg-foreground-900 border-foreground-900", "w-full"] })}
						onChange={value => {
							if (nccTeamId)
								ChangeDriverStatus(
									{
										...row.original,
										available: value === "attivo",
										rider_id: row.original.id,
										ncc_team_id: nccTeamId,
									},
									{
										onSuccess() {
											toast.success("Stato del driver cambiato con successo");
										},
										onError() {
											toast.error("Errore durante il cambio di stato del driver");
										},
									},
								);
						}}
						options={[
							{ value: "attivo", label: "Attivo", status: "on" },
							{ value: "disattivo", label: "Disattivo", status: "off" },
						]}
						value={active ? "attivo" : "disattivo"}
					/>
				</div>
			);
		},
	},
];

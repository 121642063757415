import * as React from "react";

import { cn } from "@/lib/utils";

/**
 * A custom table component.
 *
 * @component
 * @param {React.HTMLAttributes<HTMLTableElement>} props - The table element attributes.
 * @param {React.Ref<HTMLTableElement>} ref - The table element ref.
 * @returns {JSX.Element} The rendered table component.
 *
 * @external https://ui.shadcn.com/docs/components/data-table
 */
const Table = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(
	({ className, ...props }, ref) => (
		<div className=" w-full overflow-auto rounded bg-background-content border-muted">
			<table
				ref={ref}
				className={cn({ inputs: ["w-full caption-bottom text-sm", className] })}
				{...props}
			/>
		</div>
	),
);
Table.displayName = "Table";

/**
 * The table header component.
 *
 * @component
 * @param {React.HTMLAttributes<HTMLTableSectionElement>} props - The table section element attributes.
 * @param {React.Ref<HTMLTableSectionElement>} ref - The table section element ref.
 * @returns {JSX.Element} The rendered table header component.
 */
const TableHeader = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
	({ className, ...props }, ref) => (
		<thead
			ref={ref}
			className={cn({ inputs: ["[&_tr]:border-b border-muted", className] })}
			{...props}
		/>
	),
);
TableHeader.displayName = "TableHeader";

/**
 * The table body component.
 *
 * @component
 * @param {React.HTMLAttributes<HTMLTableSectionElement>} props - The table section element attributes.
 * @param {React.Ref<HTMLTableSectionElement>} ref - The table section element ref.
 * @returns {JSX.Element} The rendered table body component.
 */
const TableBody = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
	({ className, ...props }, ref) => (
		<tbody
			ref={ref}
			className={cn({ inputs: ["[&_tr:last-child]:border-0", className] })}
			{...props}
		/>
	),
);
TableBody.displayName = "TableBody";

/**
 * The table footer component.
 *
 * @component
 * @param {React.HTMLAttributes<HTMLTableSectionElement>} props - The table section element attributes.
 * @param {React.Ref<HTMLTableSectionElement>} ref - The table section element ref.
 * @returns {JSX.Element} The rendered table footer component.
 */
const TableFooter = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
	({ className, ...props }, ref) => (
		<tfoot
			ref={ref}
			className={cn({ inputs: ["border-t bg-muted font-medium [&>tr]:last:border-b-0", className] })}
			{...props}
		/>
	),
);
TableFooter.displayName = "TableFooter";

/**
 * The table row component.
 *
 * @component
 * @param {React.HTMLAttributes<HTMLTableRowElement>} props - The table row element attributes.
 * @param {React.Ref<HTMLTableRowElement>} ref - The table row element ref.
 * @returns {JSX.Element} The rendered table row component.
 */
const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
	({ className, ...props }, ref) => (
		<tr
			ref={ref}
			className={cn({ inputs: ["border-b rounded border-muted transition-colors hover:bg-muted", className] })}
			{...props}
		/>
	),
);
TableRow.displayName = "TableRow";

/**
 * The table head component.
 *
 * @component
 * @param {React.ThHTMLAttributes<HTMLTableCellElement>} props - The table cell element attributes.
 * @param {React.Ref<HTMLTableCellElement>} ref - The table cell element ref.
 * @returns {JSX.Element} The rendered table head component.
 */
const TableHead = React.forwardRef<HTMLTableCellElement, React.ThHTMLAttributes<HTMLTableCellElement>>(
	({ className, ...props }, ref) => (
		<th
			ref={ref}
			className={cn({
				inputs: ["h-12 px-4 text-left align-middle font-bold [&:has([role=checkbox])]:pr-0", className],
			})}
			{...props}
		/>
	),
);
TableHead.displayName = "TableHead";

/**
 * The table cell component.
 *
 * @component
 * @param {React.TdHTMLAttributes<HTMLTableCellElement>} props - The table cell element attributes.
 * @param {React.Ref<HTMLTableCellElement>} ref - The table cell element ref.
 * @returns {JSX.Element} The rendered table cell component.
 */
const TableCell = React.forwardRef<HTMLTableCellElement, React.TdHTMLAttributes<HTMLTableCellElement>>(
	({ className, ...props }, ref) => (
		<td
			ref={ref}
			className={cn({ inputs: ["p-4 align-middle [&:has([role=checkbox])]:pr-0", className] })}
			{...props}
		/>
	),
);
TableCell.displayName = "TableCell";

/**
 * The table caption component.
 *
 * @component
 * @param {React.HTMLAttributes<HTMLTableCaptionElement>} props - The table caption element attributes.
 * @param {React.Ref<HTMLTableCaptionElement>} ref - The table caption element ref.
 * @returns {JSX.Element} The rendered table caption component.
 */
const TableCaption = React.forwardRef<HTMLTableCaptionElement, React.HTMLAttributes<HTMLTableCaptionElement>>(
	({ className, ...props }, ref) => (
		<caption
			ref={ref}
			className={cn({ inputs: ["mt-4 text-sm text-foreground-muted", className] })}
			{...props}
		/>
	),
);
TableCaption.displayName = "TableCaption";

export { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell };

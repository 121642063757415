import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiTransportTierPricingEditMutationRequest, PostApiTransportTierPricingEditMutationResponse, PostApiTransportTierPricingEditHeaderParams, PostApiTransportTierPricingEdit403 } from "../models/PostApiTransportTierPricingEdit";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiTransportTierPricingEditClient = typeof client<PostApiTransportTierPricingEditMutationResponse, PostApiTransportTierPricingEdit403, PostApiTransportTierPricingEditMutationRequest>;
type PostApiTransportTierPricingEdit = {
    data: PostApiTransportTierPricingEditMutationResponse;
    error: PostApiTransportTierPricingEdit403;
    request: PostApiTransportTierPricingEditMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiTransportTierPricingEditHeaderParams;
    response: PostApiTransportTierPricingEditMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiTransportTierPricingEditClient>[0]>;
        return: Awaited<ReturnType<PostApiTransportTierPricingEditClient>>;
    };
};
/**
 * @description Edites a RideTierPricing and assigns it to calling NccTeam user. Calling user must be Owner of the passed NccTeam.Abort operation
 * @summary EditRideTierPricing
 * @link /api/transport/tier/pricing/edit
 */
export function usePostApiTransportTierPricingEditHook(headers?: PostApiTransportTierPricingEdit["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiTransportTierPricingEdit["response"], PostApiTransportTierPricingEdit["error"], PostApiTransportTierPricingEdit["request"]>;
    client?: PostApiTransportTierPricingEdit["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiTransportTierPricingEditHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiTransportTierPricingEdit["data"], PostApiTransportTierPricingEdit["error"], PostApiTransportTierPricingEdit["request"]>({
                method: "post",
                url: `/api/transport/tier/pricing/edit`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
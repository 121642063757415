import client from "../../templatesSDK/client";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { GetApiTransportVehicleListNccteamIdAvailableQueryResponse, GetApiTransportVehicleListNccteamIdAvailablePathParams, GetApiTransportVehicleListNccteamIdAvailableHeaderParams, GetApiTransportVehicleListNccteamIdAvailable403 } from "../models/GetApiTransportVehicleListNccteamIdAvailable";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type GetApiTransportVehicleListNccteamIdAvailableClient = typeof client<GetApiTransportVehicleListNccteamIdAvailableQueryResponse, GetApiTransportVehicleListNccteamIdAvailable403, never>;
type GetApiTransportVehicleListNccteamIdAvailable = {
    data: GetApiTransportVehicleListNccteamIdAvailableQueryResponse;
    error: GetApiTransportVehicleListNccteamIdAvailable403;
    request: never;
    pathParams: GetApiTransportVehicleListNccteamIdAvailablePathParams;
    queryParams: never;
    headerParams: GetApiTransportVehicleListNccteamIdAvailableHeaderParams;
    response: GetApiTransportVehicleListNccteamIdAvailableQueryResponse;
    client: {
        parameters: Partial<Parameters<GetApiTransportVehicleListNccteamIdAvailableClient>[0]>;
        return: Awaited<ReturnType<GetApiTransportVehicleListNccteamIdAvailableClient>>;
    };
};
export const getApiTransportVehicleListNccteamIdAvailableQueryKey = (nccTeamId: GetApiTransportVehicleListNccteamIdAvailablePathParams["nccTeam_id"], available: GetApiTransportVehicleListNccteamIdAvailablePathParams["available"]) => ["/api/transport/vehicle/list/:nccTeam_id/:available"] as const;
export type GetApiTransportVehicleListNccteamIdAvailableQueryKey = ReturnType<typeof getApiTransportVehicleListNccteamIdAvailableQueryKey>;
export function getApiTransportVehicleListNccteamIdAvailableQueryOptions(nccTeamId: GetApiTransportVehicleListNccteamIdAvailablePathParams["nccTeam_id"], available: GetApiTransportVehicleListNccteamIdAvailablePathParams["available"], headers?: GetApiTransportVehicleListNccteamIdAvailable["headerParams"], options: GetApiTransportVehicleListNccteamIdAvailable["client"]["parameters"] = {}) {
    const queryKey = getApiTransportVehicleListNccteamIdAvailableQueryKey(nccTeamId, available);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiTransportVehicleListNccteamIdAvailable["data"], GetApiTransportVehicleListNccteamIdAvailable["error"]>({
                method: "get",
                url: `/api/transport/vehicle/list/${nccTeamId}/${available}`,
                headers: { ...headers, ...options.headers },
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Views calling Rider related VehiclesAbort operation
 * @summary GetNccTeamVehicles
 * @link /api/transport/vehicle/list/:nccTeam_id/:available
 */
export function useGetApiTransportVehicleListNccteamIdAvailableHook<TData = GetApiTransportVehicleListNccteamIdAvailable["response"], TQueryData = GetApiTransportVehicleListNccteamIdAvailable["response"], TQueryKey extends QueryKey = GetApiTransportVehicleListNccteamIdAvailableQueryKey>(nccTeamId: GetApiTransportVehicleListNccteamIdAvailablePathParams["nccTeam_id"], available: GetApiTransportVehicleListNccteamIdAvailablePathParams["available"], headers?: GetApiTransportVehicleListNccteamIdAvailable["headerParams"], options: {
    query?: Partial<QueryObserverOptions<GetApiTransportVehicleListNccteamIdAvailable["response"], GetApiTransportVehicleListNccteamIdAvailable["error"], TData, TQueryData, TQueryKey>>;
    client?: GetApiTransportVehicleListNccteamIdAvailable["client"]["parameters"];
} = {}): UseQueryResult<TData, GetApiTransportVehicleListNccteamIdAvailable["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiTransportVehicleListNccteamIdAvailableQueryKey(nccTeamId, available);
    const query = useQuery({
        ...getApiTransportVehicleListNccteamIdAvailableQueryOptions(nccTeamId, available, headers, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, GetApiTransportVehicleListNccteamIdAvailable["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const getApiTransportVehicleListNccteamIdAvailableSuspenseQueryKey = (nccTeamId: GetApiTransportVehicleListNccteamIdAvailablePathParams["nccTeam_id"], available: GetApiTransportVehicleListNccteamIdAvailablePathParams["available"]) => ["/api/transport/vehicle/list/:nccTeam_id/:available"] as const;
export type GetApiTransportVehicleListNccteamIdAvailableSuspenseQueryKey = ReturnType<typeof getApiTransportVehicleListNccteamIdAvailableSuspenseQueryKey>;
export function getApiTransportVehicleListNccteamIdAvailableSuspenseQueryOptions(nccTeamId: GetApiTransportVehicleListNccteamIdAvailablePathParams["nccTeam_id"], available: GetApiTransportVehicleListNccteamIdAvailablePathParams["available"], headers?: GetApiTransportVehicleListNccteamIdAvailable["headerParams"], options: GetApiTransportVehicleListNccteamIdAvailable["client"]["parameters"] = {}) {
    const queryKey = getApiTransportVehicleListNccteamIdAvailableSuspenseQueryKey(nccTeamId, available);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiTransportVehicleListNccteamIdAvailable["data"], GetApiTransportVehicleListNccteamIdAvailable["error"]>({
                method: "get",
                url: `/api/transport/vehicle/list/${nccTeamId}/${available}`,
                headers: { ...headers, ...options.headers },
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Views calling Rider related VehiclesAbort operation
 * @summary GetNccTeamVehicles
 * @link /api/transport/vehicle/list/:nccTeam_id/:available
 */
export function useGetApiTransportVehicleListNccteamIdAvailableHookSuspense<TData = GetApiTransportVehicleListNccteamIdAvailable["response"], TQueryKey extends QueryKey = GetApiTransportVehicleListNccteamIdAvailableSuspenseQueryKey>(nccTeamId: GetApiTransportVehicleListNccteamIdAvailablePathParams["nccTeam_id"], available: GetApiTransportVehicleListNccteamIdAvailablePathParams["available"], headers?: GetApiTransportVehicleListNccteamIdAvailable["headerParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<GetApiTransportVehicleListNccteamIdAvailable["response"], GetApiTransportVehicleListNccteamIdAvailable["error"], TData, TQueryKey>>;
    client?: GetApiTransportVehicleListNccteamIdAvailable["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, GetApiTransportVehicleListNccteamIdAvailable["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiTransportVehicleListNccteamIdAvailableSuspenseQueryKey(nccTeamId, available);
    const query = useSuspenseQuery({
        ...getApiTransportVehicleListNccteamIdAvailableSuspenseQueryOptions(nccTeamId, available, headers, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, GetApiTransportVehicleListNccteamIdAvailable["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
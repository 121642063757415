import Pagination from "@/components/Pagination";
import Page from "@/components/navigation/page";
import { Skeleton } from "@/components/ui/skeleton";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { ErrorRoutingComponent } from "@/root";
import type { GetMeData } from "@/types";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useRouterState } from "@tanstack/react-router";
import {
	type ColumnFiltersState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { getApiTransportVehicleListNccteamIdAvailableQueryOptions, getApiUserMeQueryOptions } from "api/gen";
import { useEffect, useMemo, useState } from "react";
import { RidesUtils } from "./_RidesUtils";
import { useColumnsRides } from "./_columns";
import { type Rides, ridesSearchSchema } from "./_types";
import { useGetApiTransportNccTeamRideListFilteredHook } from "./_useGetRidesList";

export const Route = createFileRoute("/webpanel/rides/")({
	errorComponent: ({ error, reset }) => ErrorRoutingComponent({ error, reset }),
	validateSearch: search => ridesSearchSchema.parse(search),
	loaderDeps: ({ search: { page } }) => {
		return { page };
	},
	loader: ({ context: { queryClient }, deps: { page } }) => {
		queryClient
			.ensureQueryData(getApiUserMeQueryOptions())
			.then(getMeCache =>
				queryClient.ensureQueryData(
					getApiTransportVehicleListNccteamIdAvailableQueryOptions(
						(getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number,
						"",
						undefined,
						{ params: { page } },
					),
				),
			);
	},
	component: ManagementRides,
});

function ManagementRides() {
	const currentUrlLocation = useRouterState();

	const { data, isPending: loadingUserData, isFetched } = useSuspenseQuery(getApiUserMeQueryOptions());
	const getMeData = data as GetMeData | undefined;
	const { data: rides, isPending: isPendingRidesList } = useGetApiTransportNccTeamRideListFilteredHook(
		(getMeData as GetMeData)?.data.nccteams[0]?.id as number,
		["Published", "Ready", "Started", "Finished", "Cancelled"],
		{
			query: {
				queryKey: [`/api/transport/ncc-team/ride/list/filtered?page=${currentUrlLocation.location.search.page}`],
				refetchIntervalInBackground: true,
				refetchInterval: 30000,
			},
			client: { params: { page: currentUrlLocation.location.search.page } },
		},
	);

	const [currentState, setCurrentState] = useState<"Offline" | "Online" | "Live" | undefined>(undefined);

	useEffect(() => {
		if (getMeData && isFetched && !loadingUserData) {
			setCurrentState(getMeData.data.nccteams[0]?.status);
		}
	}, [getMeData, isFetched, loadingUserData]);

	const tableData = useMemo<Rides[]>(
		() => (isPendingRidesList ? Array(30).fill({}) : rides?.data ?? []),
		[isPendingRidesList, rides?.data],
	);

	// State to handle editable data
	const [editableData, setEditableData] = useState<Rides[]>([]);

	// Effect to sync tableData from props to state when it changes
	useEffect(() => {
		setEditableData(tableData);
	}, [tableData]);

	const columnsRides = useColumnsRides({ setEditableData });

	const tableColumns = isPendingRidesList
		? columnsRides.map(column => ({
				...column,
				cell: () => <Skeleton className="h-2 w-full" />,
			}))
		: columnsRides;

	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

	const table = useReactTable({
		columns: tableColumns,
		data: editableData,
		getCoreRowModel: getCoreRowModel(),
		onColumnFiltersChange: setColumnFilters,
		getFilteredRowModel: getFilteredRowModel(),
		state: {
			columnFilters,
		},
	});

	return (
		<Page>
			<RidesUtils
				table={table}
				currentState={currentState}
				setCurrentState={setCurrentState}
			/>
			<Table>
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup, index) => {
						return (
							<TableRow key={`${headerGroup.id}_${index + 1}`}>
								{headerGroup.headers.map((header, indexHead) => {
									return (
										<TableHead key={`${headerGroup.id}_${indexHead}`}>
											{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
										</TableHead>
									);
								})}
							</TableRow>
						);
					})}
				</TableHeader>
				<TableBody>
					{table.getRowModel().rows?.length && currentState !== "Offline" ? (
						table.getRowModel().rows.map((row, rowIndex) => (
							<TableRow key={`${row.id}_${rowIndex}`}>
								{row.getVisibleCells().map((cell, cellIndex) => (
									<TableCell key={`${cell.id}_${cellIndex + 1}`}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell
								colSpan={columnsRides.length}
								className="h-24 text-center"
							>
								{!isPendingRidesList && currentState === "Offline" ? "Sei offline" : "Nessun risultato trovato"}
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<div className="flex items-center justify-between space-x-2 py-4">
				{!isPendingRidesList && rides ? (
					<Pagination
						currentPage={rides.meta.current_page}
						pageCount={rides.meta.last_page}
						key={rides.meta.current_page}
					/>
				) : null}
			</div>
		</Page>
	);
}

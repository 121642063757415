import { z } from "zod";


export const postApiEventSearchPathParamsSchema = z.object({ "event_id": z.number().describe("Event id to search for") });


export const postApiEventSearchHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiEventSearch200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const postApiEventSearch403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiEventSearchMutationRequestSchema = z.object({ "name": z.string().optional(), "tags": z.array(z.string()).describe("Must be one of <code>Live</code>, <code>Music</code>, or <code>Cose</code> Must be at least 1 characters. Must not be greater than 64 characters.").optional(), "latitude": z.string().describe("Latitude"), "longitude": z.string().describe("Longitude"), "radius": z.number().describe("Must be between 1 and 300000.").optional(), "when": z.string().describe("Must be a valid date. Must be a date after or equal to <code>today</code>.").optional() });

 /**
 * @description Success
 */
export const postApiEventSearchMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
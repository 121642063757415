import { z } from "zod";


export const postApiEventStateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiEventState200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiEventState403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiEventStateMutationRequestSchema = z.object({ "id": z.number(), "team_id": z.number(), "state": z.string().describe("Must be one of <code>ApprovalRequested</code> or <code>Published</code>.") });

 /**
 * @description Success
 */
export const postApiEventStateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const getApiTransportTierListNccteamIdPathParamsSchema = z.object({ "nccTeam_id": z.number().describe("The ID of the nccTeam.") });


export const getApiTransportTierListNccteamIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiTransportTierListNccteamId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const getApiTransportTierListNccteamId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiTransportTierListNccteamIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { z } from "zod";


export const postApiPartyCloseHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiPartyClose200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const postApiPartyClose403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiPartyCloseMutationRequestSchema = z.object({ "id": z.number(), "pay_all": z.boolean() });

 /**
 * @description Success
 */
export const postApiPartyCloseMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
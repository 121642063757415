import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import Tooltip from "@/components/ui/tooltip";
import toast from "react-hot-toast";

import { useSuspenseQuery } from "@tanstack/react-query";
import { getApiUserMeQueryOptions } from "api/gen";

import { postApiNccTeamNccLocationCreateMutationRequestSchema, usePostApiNccTeamNccLocationCreateHook } from "api/gen";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "../../../lib/onClickOutside";

import { Button } from "@/components/ui/button";
import { queryClient } from "@/main";
import type { GetMeData } from "@/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRouterState } from "@tanstack/react-router";
import { useState } from "react";
import { useForm } from "react-hook-form";
import type { z } from "zod";

export const GarargeUtilsForm = ({ closeDialog }: { closeDialog: () => void }) => {
	const currentUrlLocation = useRouterState();

	const [sliderValue, setSliderValue] = useState(1);
	const [cordinates, setCordinates] = useState<{ lat: string; lng: string; address: string } | undefined>();

	const { data } = useSuspenseQuery(getApiUserMeQueryOptions());
	const getMeCache = data as GetMeData | undefined;
	const { mutate: CreateGarage } = usePostApiNccTeamNccLocationCreateHook();
	const {
		ready,
		value,
		suggestions: { status, data: dataAddress },
		setValue,
		clearSuggestions,
	} = usePlacesAutocomplete({
		callbackName: "initMap",
		debounce: 300,
	});

	const refAddress = useOnclickOutside(() => {
		// When the user clicks outside of the component, we can dismiss
		// the searched suggestions by calling this method
		clearSuggestions();
	});

	const handleInput = (e: { target: { value: string } }) => {
		// Update the keyword of the input element
		setValue(e.target.value);
	};

	const handleSelect =
		({ description }: { description: string }) =>
		() => {
			// When the user selects a place, we can replace the keyword without request data from API
			// by setting the second parameter to "false"
			setValue(description, false);
			clearSuggestions();

			// Get latitude and longitude via utility functions
			getGeocode({ address: description }).then(results => {
				if (results[0]) {
					const { lat, lng } = getLatLng(results[0]);
					setCordinates({ lat: lat.toString(), lng: lng.toString(), address: description });
				}
			});
		};

	const renderSuggestions = () =>
		dataAddress.map(suggestion => {
			const {
				place_id,
				structured_formatting: { main_text, secondary_text },
			} = suggestion;

			return (
				<li
					key={place_id}
					onClick={handleSelect(suggestion)}
				>
					<strong>{main_text}</strong> <small>{secondary_text}</small>
				</li>
			);
		});

	const form = useForm<z.infer<typeof postApiNccTeamNccLocationCreateMutationRequestSchema>>({
		mode: "all",
		resolver: zodResolver(postApiNccTeamNccLocationCreateMutationRequestSchema),
		defaultValues: {
			ncc_team_id: (getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number,
			action_range: (sliderValue * 1000).toString(),
			longitude: cordinates?.lng.toString() ?? "0",
			latitude: cordinates?.lat.toString() ?? "0",
		},
	});

	function onSubmit(data: z.infer<typeof postApiNccTeamNccLocationCreateMutationRequestSchema>) {
		const dataProcessed: z.infer<typeof postApiNccTeamNccLocationCreateMutationRequestSchema> = {
			...data,
			ncc_team_id: (getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number,
			action_range: (sliderValue * 1000).toString(),
			address: cordinates?.address ?? value,
			longitude: cordinates?.lng.toString() ?? "0",
			latitude: cordinates?.lat.toString() ?? "0",
		};

		CreateGarage(dataProcessed, {
			onError() {
				toast.error("Errore durante la creazione dell'autorimessa");
			},
			onSuccess() {
				toast.success("Autorimessa creata con successo");
				queryClient.invalidateQueries({
					queryKey: [
						`/api/ncc-team/ncc-location/list/${
							(getMeCache as GetMeData | undefined)?.data.nccteams[0]?.id as number
						}?page=${currentUrlLocation.location.search.page}`,
						currentUrlLocation.location.search.page,
					],
				});
				closeDialog();
			},
		});
	}

	return (
		<Form {...form}>
			<form
				className="w-full items-center justify-center space-y-6"
				onSubmit={form.handleSubmit(onSubmit)}
			>
				<div className="grid md:grid-cols-2 gap-4 items-center justify-center ">
					<FormField
						control={form.control}
						name="name"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Nome</FormLabel>
								<FormControl>
									<Input
										placeholder="inserisci il nome"
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="description"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Descrizione</FormLabel>
								<FormControl>
									<Input
										type="text"
										placeholder="inserisci la descrizione"
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="address"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Indirizzo</FormLabel>
								<FormControl>
									<div
										{...field}
										ref={refAddress}
									>
										<Input
											type="text"
											placeholder="Inserisci l'indirizzo"
											value={value}
											onChange={handleInput}
											disabled={!ready}
										/>
										{/* We can use the "status" to decide whether we should display the dropdown or not */}
										{status === "OK" && <ul>{renderSuggestions()}</ul>}
									</div>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="action_range"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Range d'azione</FormLabel>
								<FormControl>
									<Tooltip message={`${sliderValue.toString()} km`}>
										<Input
											type="range"
											className={"accent-accent"}
											max={30}
											min={1}
											{...field}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
												setSliderValue(Number.parseFloat(event.target.value));
											}}
											value={sliderValue}
										/>
									</Tooltip>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<div className="flex justify-center items-center space-x-2">
					<Button
						type="submit"
						size={"xl"}
						disabled={getMeCache === undefined}
					>
						Salva
					</Button>
					<Button
						type="button"
						size={"xl"}
						variant={"outline"}
						onClick={closeDialog}
					>
						Annulla
					</Button>
				</div>
			</form>
		</Form>
	);
};

import { z } from "zod";


export const postApiTeamInvitesInviteHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTeamInvitesInvite200Schema = z.string();

 /**
 * @description Not available
 */
export const postApiTeamInvitesInvite403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTeamInvitesInviteMutationRequestSchema = z.object({ "id": z.number(), "role": z.string().describe("Must not be greater than 64 characters."), "email": z.string().email().describe("Must be a valid email address.") });

 /**
 * @description Success
 */
export const postApiTeamInvitesInviteMutationResponseSchema = z.string();
import { z } from "zod";


export const postApiTransportTierPriceVariantPricingCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportTierPriceVariantPricingCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiTransportTierPriceVariantPricingCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportTierPriceVariantPricingCreateMutationRequestSchema = z.object({ "ride_tier_id": z.number(), "max_distance": z.number().optional(), "km_price": z.number().describe("Must be between 0 and 99999999."), "extra_km_price": z.string().describe("KM price fee") });

 /**
 * @description Success
 */
export const postApiTransportTierPriceVariantPricingCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiTransportVehicleEditHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportVehicleEdit200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).optional() });

 /**
 * @description Not avaible
 */
export const postApiTransportVehicleEdit403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportVehicleEditMutationRequestSchema = z.object({ "id": z.number(), "plaque": z.string().describe("Must not be greater than 10 characters."), "seats": z.number().describe("Must be between 1 and 128."), "type": z.string().describe("Must not be greater than 16 characters."), "registered_at": z.string().describe("Must be a valid date."), "brand": z.string().describe("Must not be greater than 16 characters."), "model": z.string().describe("Must not be greater than 16 characters.") });

 /**
 * @description Success
 */
export const postApiTransportVehicleEditMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).optional() });
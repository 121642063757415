import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiUserAuthResetPasswordMutationRequest, PostApiUserAuthResetPasswordMutationResponse, PostApiUserAuthResetPasswordHeaderParams, PostApiUserAuthResetPassword404 } from "../models/PostApiUserAuthResetPassword";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiUserAuthResetPasswordClient = typeof client<PostApiUserAuthResetPasswordMutationResponse, PostApiUserAuthResetPassword404, PostApiUserAuthResetPasswordMutationRequest>;
type PostApiUserAuthResetPassword = {
    data: PostApiUserAuthResetPasswordMutationResponse;
    error: PostApiUserAuthResetPassword404;
    request: PostApiUserAuthResetPasswordMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiUserAuthResetPasswordHeaderParams;
    response: PostApiUserAuthResetPasswordMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiUserAuthResetPasswordClient>[0]>;
        return: Awaited<ReturnType<PostApiUserAuthResetPasswordClient>>;
    };
};
/**
 * @description Updates forgotten passwordAbort operation
 * @summary ResetPassword
 * @link /api/user/auth/reset-password
 */
export function usePostApiUserAuthResetPasswordHook(headers?: PostApiUserAuthResetPassword["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiUserAuthResetPassword["response"], PostApiUserAuthResetPassword["error"], PostApiUserAuthResetPassword["request"]>;
    client?: PostApiUserAuthResetPassword["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiUserAuthResetPasswordHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiUserAuthResetPassword["data"], PostApiUserAuthResetPassword["error"], PostApiUserAuthResetPassword["request"]>({
                method: "post",
                url: `/api/user/auth/reset-password`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
import { z } from "zod";


export const postApiTransportVehicleMoveHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportVehicleMove200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const postApiTransportVehicleMove403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportVehicleMoveMutationRequestSchema = z.object({ "id": z.number(), "ncc_team_id": z.number(), "ncc_location_id": z.number() });

 /**
 * @description Success
 */
export const postApiTransportVehicleMoveMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { useNccTeamIdStore } from "@/hooks/store/main";
import { queryClient } from "@/main";
import type { ColumnDef, Row } from "@tanstack/react-table";
import { usePostApiTransportTierPricingDeleteHook } from "api/gen";
import toast from "react-hot-toast";
import { DeleteDialog } from "./_DeleteDialog";
import { DistanceEditDialog } from "./_DistanceEditDialog";
import { RateTypeEditDialog } from "./_RateTypeEditDialog";
import type { IDistanceData, RideTier } from "./_types";

export const columnsRates: ColumnDef<RideTier>[] = [
	{
		accessorKey: "max_distance",
		header: "Distanza",
		accessorFn: row => (!row.max_distance ? "-" : row.max_distance),
		cell: ({ row }) => <div className="text-foreground-50"> {`> ${row.getValue("max_distance")} km`} </div>,
	},
	{
		accessorKey: "km_price",
		header: "Tariffa",
		accessorFn: (row: { km_price: string }) => (!row.km_price ? "-" : row.km_price),
		cell: ({ row }) => <div className="text-foreground-50"> {`${row.getValue("km_price")} €`} </div>,
	},
	{
		accessorKey: "active",
		header: "",
		cell: ({ row }) => {
			const { nccTeamId } = useNccTeamIdStore();
			const { mutate: tierPricingDelete } = usePostApiTransportTierPricingDeleteHook();

			return (
				<div className="flex">
					<RateTypeEditDialog row={row as unknown as Row<RideTier & { km_price_reason: string }>} />
					<DeleteDialog
						onSubmit={() =>
							tierPricingDelete(
								{ id: (row.original as unknown as RideTier).id, ncc_team_id: nccTeamId ?? 1 },
								{
									onSuccess: () => {
										toast.success("Tariffa eliminata con successo");
										queryClient.invalidateQueries({ queryKey: ["distancePricing"] });
									},
									onError: () => {
										toast.error("Errore durante l'eliminazione della tariffa");
									},
								},
							)
						}
					/>
				</div>
			);
		},
	},
];

export const columnsDistance: ColumnDef<IDistanceData>[] = [
	{
		accessorKey: "name",
		header: "Tipologia",
		accessorFn: (row: { name?: string }) => (!row.name ? "-" : row.name),
		cell: ({ row }) => <div className="text-foreground-50"> {row.getValue("name")} </div>,
	},
	{
		accessorKey: "extra_km_price",
		header: "Tariffa",
		accessorFn: (row: { extra_km_price?: string }) => (!row.extra_km_price ? "-" : row.extra_km_price),
		cell: ({ row }) => <div className="text-foreground-50"> {`${row.getValue("extra_km_price")} €`} </div>,
	},
	{
		accessorKey: "active",
		header: "",
		cell: ({ row }) => {
			return (
				<div className="flex">
					<DistanceEditDialog row={row} />
				</div>
			);
		},
	},
];

import { Select, type SelectOption } from "@/components/ui/select";
import Tooltip from "@/components/ui/tooltip";
import { cn, formatCurrency, formatDate, formatDateAndTime } from "@/lib/utils";
import type { GetMeData } from "@/types";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { ColumnDef } from "@tanstack/react-table";
import {
	getApiUserMeQueryOptions,
	useGetApiNccTeamGetNccteamIdHook,
	useGetApiTransportVehicleListNccteamIdAvailableHook,
} from "api/gen";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { ColumnsConfirm } from "./_columnsConfirm";
import type { AvailableVehicles, AvailableVehiclesData, AvaildableDrivers, Rides, User } from "./_types";

export const useColumnsRides = ({
	setEditableData,
}: { setEditableData: Dispatch<SetStateAction<Rides[]>> }): ColumnDef<Rides>[] => {
	const { data, isPending: loadingUserData } = useSuspenseQuery(getApiUserMeQueryOptions());
	const getMeData = data as GetMeData | undefined;

	const [drivers, setDrivers] = useState<SelectOption<number>[]>([]);
	const [vehicles, setVehicles] = useState<SelectOption<number>[]>([]);
	const { data: availableVehicles, isPending: availableVehiclesLoader } =
		useGetApiTransportVehicleListNccteamIdAvailableHook(
			(getMeData as GetMeData)?.data.nccteams[0]?.id as number,
			"true",
			undefined,
			{
				query: {
					enabled: getMeData !== undefined,
					queryKey: [`/api/transport/vehicle/list/${(getMeData as GetMeData)?.data.nccteams[0]?.id as number}/true`],
				},
			},
		);
	const availableVehiclesData = availableVehicles as AvailableVehicles | undefined;

	const { data: availableDrivers, isPending: availableDriversLoader } = useGetApiNccTeamGetNccteamIdHook(
		(getMeData as GetMeData)?.data.nccteams[0]?.id as number,
		undefined,
		{
			query: {
				enabled: !!getMeData?.data.nccteams[0]?.id && !loadingUserData,
				queryKey: [`/api/ncc-team/get/${(getMeData as GetMeData)?.data.nccteams[0]?.id as number}`],
			},
		},
	);
	const availableDriversData = availableDrivers as AvaildableDrivers | undefined;

	useEffect(() => {
		if (availableDriversData?.data?.users) {
			setDrivers(
				availableDriversData.data.users
					.filter(el => el.roles.includes("Driver"))
					.map(el => ({ value: el.id, label: el.name })),
			);
		}
	}, [availableDriversData]);

	useEffect(() => {
		if (availableVehiclesData?.data) {
			setVehicles(
				availableVehiclesData.data.map(el => ({
					value: el.id,
					label: `${el.model} (${el.plaque})`,
					id: el.id,
					name: el.model,
				})),
			);
		}
	}, [availableVehiclesData]);

	const updateData = (rowIndex: number, columnId: string, newValue: unknown) => {
		setEditableData(currentData =>
			currentData.map((row, index) => {
				if (index === rowIndex) {
					return {
						...row, // spread the current row
						[columnId]: newValue, // update the specific column with new value
					};
				}
				return row;
			}),
		);
	};

	return [
		{
			accessorKey: "id",
			header: "N° corsa",
			cell: ({ row }) => <div className="text-foreground-50">{row.getValue("id")}</div>,
		},
		{
			accessorKey: "price",
			header: "Prezzo",
			cell: ({ row }) => {
				const price: string | undefined = row.getValue("price");
				return price ? <div className="text-foreground-50">{formatCurrency(price)}</div> : "0 €";
			},
		},
		{
			accessorKey: "payed_at",
			header: "Ricezione",
			cell: ({ row }) => {
				const dayHour: string | undefined = row.getValue("payed_at");
				return dayHour ? <div className="text-foreground-50">{formatDate(dayHour)}</div> : "-";
			},
		},
		{
			accessorKey: "pickup_at",
			header: "Data e ora corsa",
			cell: ({ row }) => {
				const dayHour: string | undefined = row.getValue("pickup_at");
				return dayHour ? (
					<div className="text-foreground-50 w-[105px]">{formatDateAndTime(row.getValue("pickup_at"))}</div>
				) : (
					"-"
				);
			},
		},
		{
			accessorKey: "from_address",
			header: "Luogo pick-up",
			size: 20, //starting column size
			cell: ({ row }) => (
				<Tooltip message={row.getValue("from_address")}>
					<div className="w-[100px] -my-3">
						<div className="truncate py-3">{row.getValue("from_address")}</div>
					</div>
				</Tooltip>
			),
		},
		{
			accessorKey: "to_address",
			header: "Luogo arrivo",
			cell: ({ row }) => (
				<Tooltip message={row.getValue("to_address")}>
					<div className="w-[100px] -my-3">
						<div className="truncate py-3">{row.getValue("to_address")}</div>
					</div>
				</Tooltip>
			),
		},
		{
			accessorKey: "used_seats",
			header: "N° passeggeri",
			cell: ({ row }) => {
				const seats: string | undefined = row.getValue("used_seats");
				return seats ? <div className="text-foreground-50">{seats}</div> : 0;
			},
		},
		{
			accessorKey: "state",
			header: "Status",
			cell: ({ row }) => (
				<div
					className={cn({
						inputs: [
							row.getValue("state") === "Published" && "w-3 h-3 inline-block bg-yellow-500 rounded-full mr-2",
							row.getValue("state") === "Ready" && "w-3 h-3 inline-block bg-green-200 rounded-full mr-2",
							row.getValue("state") === "Started" && "w-3 h-3 inline-block bg-green-500 rounded-full mr-2",
							row.getValue("state") === "Finished" && "w-3 h-3 inline-block bg-red-600 rounded-full mr-2",
							row.getValue("state") === "Cancelled" && "w-3 h-3 inline-block bg-red-800 rounded-full mr-2",
						],
					})}
				/>
			),
		},
		{
			accessorKey: "vehicle_type",
			header: "Mezzo",
			cell: ({ row }) => {
				const vehicleType: {
					id: number;
					name: string;
					title: string;
				} = row.getValue("vehicle_type");

				return (
					<div className="text-foreground-50">
						<div>{vehicleType?.name ?? ""}</div>
					</div>
				);
			},
		},
		{
			accessorKey: "rider",
			header: "Driver",
			cell: params => {
				const { row, column } = params;
				if (row.getValue("state") === "Published") {
					return (
						<div className="-my-2">
							<Select
								options={drivers}
								onChange={value => {
									updateData(row.index, "rider", value);
								}}
								value={row.getValue("rider")}
								size="sm"
								title="Seleziona driver"
								name="driver"
							/>
						</div>
					);
				}
				if (
					row.getValue("state") === "Ready" ||
					row.getValue("state") === "Started" ||
					row.getValue("state") === "Finished"
				) {
					const rider = row.getValue("rider") as { user: User };
					return <div className="text-foreground-50 w-[200px]">{<div>{rider?.user?.name ?? ""}</div>}</div>;
				}
				return "-";
			},
		},
		{
			accessorKey: "vehicle",
			header: "Veicolo",
			cell: params => {
				const { row, column } = params;
				if (row.getValue("state") === "Published") {
					return (
						<div className="-my-2">
							<Select
								options={vehicles}
								onChange={value => {
									updateData(row.index, "vehicle", value);
								}}
								value={row.getValue("vehicle")}
								size="sm"
								title="Seleziona mezzo"
								name="veichle"
							/>
						</div>
					);
				}
				if (
					row.getValue("state") === "Ready" ||
					row.getValue("state") === "Started" ||
					row.getValue("state") === "Finished"
				) {
					const vehicle: AvailableVehiclesData = row.getValue("vehicle");
					return <div className="text-foreground-50 w-[200px]">{<div>{vehicle?.model ?? ""}</div>}</div>;
				}
				return "-";
			},
		},
		{
			accessorKey: "active",
			header: "Conferma",
			cell: ({ row }) => {
				if (row.getValue("state") === "Published") {
					const vehicleType: {
						id: number;
						name: string;
						title: string;
					} = row.getValue("vehicle_type");
					return (
						<ColumnsConfirm
							row={row}
							availableDriversLoader={availableDriversLoader}
							availableVehiclesLoader={availableVehiclesLoader}
							availableVehiclesData={availableVehiclesData}
							availableDriversData={availableDriversData}
							vehicleType={vehicleType}
							getMeData={getMeData}
						/>
					);
				}

				return "-";
			},
		},
	];
};

import { DataTablePagination } from "@/components/data-pagination";
import { DataTable } from "@/components/data-table";
import Page from "@/components/navigation/page";
import { Skeleton } from "@/components/ui/skeleton";
import { ErrorRoutingComponent } from "@/root";
import type { GetMeData, IPagination } from "@/types";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useRouterState } from "@tanstack/react-router";
import {
	type ColumnDef,
	type ColumnFiltersState,
	getCoreRowModel,
	getFilteredRowModel,
	useReactTable,
} from "@tanstack/react-table";
import {
	getApiTransportVehicleListNccteamIdAvailableQueryOptions,
	getApiUserMeQueryOptions,
	useGetApiTransportVehicleListNccteamIdAvailableHookSuspense,
} from "api/gen";
import { useMemo, useState } from "react";
import { VeichlesUtils } from "./_VeichlesUtils";
import { columnsVeichles } from "./_columns";
import { type ResVeichles, type Veichles, VeichlesearchSchema } from "./_types";

export const Route = createFileRoute("/webpanel/veichles/")({
	errorComponent: ({ error, reset }) => ErrorRoutingComponent({ error, reset }),
	validateSearch: search => VeichlesearchSchema.parse(search),
	loaderDeps: ({ search: { page } }) => {
		return { page };
	},
	loader: ({ context: { queryClient }, deps: { page } }) => {
		queryClient
			.ensureQueryData(getApiUserMeQueryOptions())
			.then(getMeData =>
				queryClient.ensureQueryData(
					getApiTransportVehicleListNccteamIdAvailableQueryOptions(
						(getMeData as GetMeData).data.nccteams[0]?.id as number,
						"",
						undefined,
						{ params: { page } },
					),
				),
			);
	},
	component: ManagementVeichles,
});

function ManagementVeichles() {
	const currentUrlLocation = useRouterState();

	const { data } = useSuspenseQuery(getApiUserMeQueryOptions());
	const getMeData = data as GetMeData | undefined;
	const { data: VeichlesQuery, isPending } = useGetApiTransportVehicleListNccteamIdAvailableHookSuspense(
		(getMeData as GetMeData | undefined)?.data.nccteams[0]?.id as number,
		"",
		undefined,
		{
			query: {
				queryKey: [
					`/api/transport/vehicle/list/${(getMeData as GetMeData | undefined)?.data.nccteams[0]?.id as number}`,
					`/api/transport/vehicle/list/${(getMeData as GetMeData | undefined)?.data.nccteams[0]?.id as number}?page=${
						currentUrlLocation.location.search.page
					}`,
					currentUrlLocation.location.search.page,
				],
			},
			client: {
				params: {
					page: currentUrlLocation.location.search.page,
				},
			},
		},
	);

	const veichles = VeichlesQuery as (ResVeichles | undefined) & IPagination;

	const tableData = useMemo<Veichles[]>(
		() => (isPending ? Array(30).fill({}) : veichles?.data ?? []),
		[isPending, veichles?.data],
	);

	const tableColumns = useMemo<ColumnDef<Veichles>[]>(
		() =>
			isPending
				? columnsVeichles.map(column => ({
						...column,
						cell: () => <Skeleton className="h-2 w-full" />,
					}))
				: columnsVeichles,
		[isPending, columnsVeichles],
	);

	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

	const table = useReactTable({
		columns: tableColumns,
		data: tableData,
		getCoreRowModel: getCoreRowModel(),
		onColumnFiltersChange: setColumnFilters,
		getFilteredRowModel: getFilteredRowModel(),
		state: {
			columnFilters,
		},
	});

	return (
		<Page>
			<VeichlesUtils table={table} />
			<DataTable
				columns={tableColumns}
				table={table}
				dataTable={tableData}
			/>
			<DataTablePagination
				isPendingPaginationData={isPending}
				dataPagination={veichles}
			/>
		</Page>
	);
}

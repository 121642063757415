import { z } from "zod";


export const postApiUserAuthResetPasswordHeaderParamsSchema = z.object({ "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiUserAuthResetPassword200Schema = z.object({ "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const postApiUserAuthResetPassword404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiUserAuthResetPasswordMutationRequestSchema = z.object({ "email": z.string().email().describe("Must be a valid email address. Must not be greater than 255 characters."), "password": z.string(), "token": z.string().describe("Must not be greater than 64 characters.") });

 /**
 * @description Success
 */
export const postApiUserAuthResetPasswordMutationResponseSchema = z.object({ "data": z.object({}).describe("Data").optional() });
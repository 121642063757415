import type { IPagination } from "@/types";
import { z } from "zod";

export interface ResVeichles extends IPagination {
	data: Veichles[];
}

export interface Veichles {
	id: number;
	plaque: string;
	seats: number;
	registered_at: string;
	brand: string;
	model: string;
	active: boolean;
	available: boolean;
	vehicle_type: VehicleType;
	ncc_location: NccLocation;
	ncc_team: NccTeam;
}

export interface VehicleType {
	id: number;
	name: string;
	title: string;
	seats: number;
}

export interface NccLocation {
	id: number;
	ncc_team_id: number;
	name: string;
	description: string;
	address: string;
	latitude: string;
	longitude: string;
	action_range: string;
	active: boolean;
	available: boolean;
}

export interface NccTeam {
	id: number;
	name: string;
	description: string;
}

export interface ITransportVehicleTypeAll {
	data: TransportVehicleTypeAllData[];
}

export interface TransportVehicleTypeAllData {
	id: number;
	name: string;
	title: string;
	seats: number;
}

export const VeichlesearchSchema = z.object({
	page: z.number().catch(1),
});

import { z } from "zod";


export const postApiAttachmentUploadphotoHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiAttachmentUploadphoto200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiAttachmentUploadphoto403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiAttachmentUploadphotoMutationRequestSchema = z.object({ "type_id": z.number(), "type": z.string().describe("Must be one of <code>Event</code>, <code>Location</code>, or <code>Rider</code>."), "file": z.string().describe("Must be a file. Must not be greater than 5120 kilobytes.") });

 /**
 * @description Success
 */
export const postApiAttachmentUploadphotoMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiUserAuthForgotPasswordHeaderParamsSchema = z.object({ "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiUserAuthForgotPassword200Schema = z.object({ "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const postApiUserAuthForgotPassword403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiUserAuthForgotPasswordMutationRequestSchema = z.object({ "email": z.string().email().describe("Must be a valid email address. Must not be greater than 255 characters.") });

 /**
 * @description Success
 */
export const postApiUserAuthForgotPasswordMutationResponseSchema = z.object({ "data": z.object({}).describe("Data").optional() });
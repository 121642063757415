import { z } from "zod";


export const getApiNccTeamGetNccteamIdPathParamsSchema = z.object({ "nccTeam_id": z.number().describe("NccTeam id to search issued invites for") });


export const getApiNccTeamGetNccteamIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiNccTeamGetNccteamId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const getApiNccTeamGetNccteamId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiNccTeamGetNccteamIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { z } from "zod";


export const postApiNccTeamStatusChangeHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiNccTeamStatusChange200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not avaible
 */
export const postApiNccTeamStatusChange403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiNccTeamStatusChangeMutationRequestSchema = z.object({ "status": z.string().describe("Must be one of <code>Offline</code>, <code>Online</code>, or <code>Live</code> Must not be greater than 8 characters."), "ncc_team_id": z.number() });

 /**
 * @description Success
 */
export const postApiNccTeamStatusChangeMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiNccTeamNccLocationEditHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiNccTeamNccLocationEdit200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiNccTeamNccLocationEdit403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiNccTeamNccLocationEditMutationRequestSchema = z.object({ "id": z.number(), "description": z.string().describe("Must not be greater than 512 characters."), "available": z.boolean() });

 /**
 * @description Success
 */
export const postApiNccTeamNccLocationEditMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const getApiTransportUserGetRidesPathParamsSchema = z.object({ "ride": z.number().describe("Ride id to search for") });


export const getApiTransportUserGetRidesHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiTransportUserGetRides200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const getApiTransportUserGetRides403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiTransportUserGetRidesQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { Button } from "@/components/ui/button";
import Dialog from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useNccTeamIdStore, useRideIdStore } from "@/hooks/store/main";
import { createZodFromType } from "@/lib/utils";
import { queryClient } from "@/main";
import { zodResolver } from "@hookform/resolvers/zod";
import type { Row } from "@tanstack/react-table";
import { type PostApiTransportTierPricingEditMutationRequest, usePostApiTransportTierPricingEditHook } from "api/gen";
import { useState } from "react";
import CurrencyInput, { type CurrencyInputProps } from "react-currency-input-field";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FiEdit2 as Edit } from "react-icons/fi";
import { z } from "zod";
import type { RideTier } from "./_types";

const FormSchema = createZodFromType<PostApiTransportTierPricingEditMutationRequest>({
	id: z.number(),
	max_distance: z.number().default(1),
	km_price: z.string(),
	km_price_reason: z.string(),
	ncc_team_id: z.number(),
});

export function RateTypeEditDialog({ row }: { row: Row<RideTier & { km_price_reason: string }> }) {
	const [isDialogOpenEditRateType, setIsDialogOpenEditRateType] = useState(false);
	const [kmPrice, setKmPrice] = useState<string | undefined>();
	const [maxDistance, setMaxDistance] = useState<number>(row.original.max_distance);
	const { rideId } = useRideIdStore();
	const { mutate: transportTierPricingEdit } = usePostApiTransportTierPricingEditHook(undefined, {
		mutation: { mutationKey: [{ rideTier_id: rideId }] },
	});
	const { nccTeamId } = useNccTeamIdStore();

	const form = useForm<z.infer<typeof FormSchema>>({
		mode: "all",
		resolver: zodResolver(FormSchema),
		defaultValues: {
			id: row.original.id,
			km_price: row.original.km_price,
			max_distance: row.original.max_distance,
			km_price_reason: "nuova tariffa",
			ncc_team_id: nccTeamId,
		},
	});

	function onSubmit(data: z.infer<typeof FormSchema>) {
		data.id = row.original.id;
		data.km_price = kmPrice?.replace(",", ".") as string;
		data.max_distance = maxDistance;
		transportTierPricingEdit(data, {
			onSuccess() {
				queryClient.invalidateQueries({ queryKey: ["distancePricing"] });
				toast.success("Tariffa modificata con successo");
				setIsDialogOpenEditRateType(false);
			},
			onError() {
				toast.error("Errore durante la modifica della tariffa");
				setIsDialogOpenEditRateType(false);
			},
		});
	}

	const handleKmPriceChange: CurrencyInputProps["onValueChange"] = value => setKmPrice(value);

	return (
		<>
			<Button
				onClick={() => setIsDialogOpenEditRateType(true)}
				variant={"ghost"}
				className="px-4 py-2 rounded-md"
			>
				<Edit />
			</Button>
			<Dialog
				isOpen={isDialogOpenEditRateType}
				onClose={() => setIsDialogOpenEditRateType(false)}
				title="Modifica"
			>
				<Form {...form}>
					<form
						className="w-full space-y-6"
						onSubmit={form.handleSubmit(onSubmit)}
					>
						<div className=" gap-4 content-center">
							{row.original.max_distance !== null ? (
								<FormField
									control={form.control}
									name="max_distance"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Distanza</FormLabel>
											<FormControl>
												<Input
													type="number"
													placeholder="inserisci la distanza in km"
													{...field}
													value={maxDistance}
													onChange={value => setMaxDistance(Number.parseFloat(value.target.value))}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							) : null}
							<FormField
								control={form.control}
								name="km_price"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Prezzo / km</FormLabel>
										<FormControl>
											<CurrencyInput
												customInput={Input}
												placeholder="inserisci il costo per km"
												defaultValue={row.original.km_price}
												decimalsLimit={2}
												{...field}
												value={kmPrice}
												onValueChange={handleKmPriceChange}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>

						<div className="flex justify-center items-center space-x-2">
							<Button
								type="submit"
								size={"xl"}
							>
								Modifica
							</Button>
							<Button
								type="button"
								size={"xl"}
								variant={"outline"}
								onClick={() => setIsDialogOpenEditRateType(false)}
							>
								Annulla
							</Button>
						</div>
					</form>
				</Form>
			</Dialog>
		</>
	);
}

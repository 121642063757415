import { z } from "zod";


export const postApiNccTeamUserRoleAddHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiNccTeamUserRoleAdd200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiNccTeamUserRoleAdd403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiNccTeamUserRoleAddMutationRequestSchema = z.object({ "id": z.number(), "user_id": z.number(), "role": z.string().describe("Must be one of <code>Driver</code>, <code>Manager</code>, <code>Owner</code>, or <code>User</code> Must not be greater than 64 characters.") });

 /**
 * @description Success
 */
export const postApiNccTeamUserRoleAddMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiTransportRideStopHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportRideStop200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiTransportRideStop403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportRideStopMutationRequestSchema = z.object({ "id": z.number() });

 /**
 * @description Success
 */
export const postApiTransportRideStopMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
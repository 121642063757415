import Loader from "@/components/Loader";
import { useSetLocaleLanguageHook } from "@/components/auth/_SetLocaleLanguageHook";
import { Button, buttonVariants } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import type { UseMutationResult } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";
import { createFileRoute } from "@tanstack/react-router";
import {
	type PostApiUserAuthResetPassword404,
	type PostApiUserAuthResetPasswordMutationRequest,
	type PostApiUserAuthResetPasswordMutationResponse,
	usePostApiUserAuthResetPasswordHook,
} from "api/gen";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

export const Route = createFileRoute("/resetPassword")({
	component: () => ResetPassword(),
	validateSearch: search => z.object({ email: z.string().email(), token: z.string() }).parse(search),
	loaderDeps({ search: { email, token } }) {
		return { email, token };
	},
});

const zodSchemaResetPswBody = z
	.object({
		password: z
			.string()
			.min(10, {
				message: "La password deve essere almeno di 10 caratteri.",
			})
			.regex(/.*[A-Z].*/, "Una lettera in maiuscolo")
			.regex(/.*[a-z].*/, "Una lettera in minuscolo")
			.regex(/.*\d.*/, "Un numero")
			.regex(/.*[`~<>?,.\/!@#$%^&*()\-_+=\"'|{}\[\];:\\].*/, "Una carattere speciale (esempio: !,? ecc...)"),
		password_confirmation: z.string(),
		email: z.string().email(),
		token: z.string(),
	})
	.refine(data => data.password === data.password_confirmation, {
		message: "le password non sono uguali",
		path: ["password_confirmation"],
	});

const ResetPassword = () => {
	const { mutate: resetPassword, isPending: loading } =
		usePostApiUserAuthResetPasswordHook() as unknown as UseMutationResult<
			PostApiUserAuthResetPasswordMutationResponse,
			PostApiUserAuthResetPassword404,
			PostApiUserAuthResetPasswordMutationRequest & { password_confirmation: string },
			unknown
		>;
	const { mutate: setLocaleLng } = useSetLocaleLanguageHook();
	const navigate = useNavigate();
	const { email, token } = Route.useSearch();

	function onSubmit(data: z.infer<typeof zodSchemaResetPswBody>) {
		resetPassword(
			{
				email: email,
				password: data.password,
				password_confirmation: data.password_confirmation,
				token: token,
			},
			{
				onSuccess: () => {
					setLocaleLng();
					navigate({ to: "/signIn" });
					toast.success("Password cambiata con successo");
				},
				onError(error, variables, context) {
					let errorMessage = "Richiesta non valida, contatta il servizio clienti";
					if (error instanceof Error && error.message !== "") {
						errorMessage = error.message;
					} else if (error instanceof Error && error.message !== "") {
						errorMessage = error.message;
					}
					console.error("🚀 ~ errorMessage:", errorMessage);
					toast.error(errorMessage);
				},
			},
		);
	}

	const form = useForm<z.infer<typeof zodSchemaResetPswBody>>({
		mode: "all",
		resolver: zodResolver(zodSchemaResetPswBody),
		defaultValues: {
			password: "",
			password_confirmation: "",
			email: email,
			token: token,
		},
	});

	return (
		<>
			<div className="container h-screen flex flex-row items-center justify-center max-w-full bg-background-content">
				<div className="drop-shadow-md absolute right-4 top-4 md:right-8 md:top-8">
					<Link
						to="/signIn"
						className={cn({ inputs: [buttonVariants({ variant: "default" })] })}
					>
						Reset password
					</Link>
				</div>
				<div className="p-8 mx-auto container items-center justify-center drop-shadow-md">
					<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px] rounded-2xl shadow-2xl  bg-background-base py-14 px-8">
						<div className="flex flex-col space-y-2 text-center">
							<h1 className="text-2xl font-semibold tracking-tight pb-5">Reimposta la password</h1>
							<Form {...form}>
								<form
									onSubmit={form.handleSubmit(onSubmit)}
									className="w-full space-y-6"
								>
									<FormField
										control={form.control}
										name="password"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Inserisci la nuova password</FormLabel>
												<FormControl>
													<Input
														className="w-full"
														id="password"
														type="password"
														autoCapitalize="none"
														autoComplete="password"
														autoCorrect="off"
														disabled={loading}
														{...field}
													/>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="password_confirmation"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Conferma la nuova password</FormLabel>
												<FormControl>
													<Input
														id="password_confirmation"
														type="password"
														className="w-full"
														autoCapitalize="none"
														autoComplete="password"
														autoCorrect="off"
														disabled={loading}
														{...field}
													/>
												</FormControl>

												<FormMessage />
											</FormItem>
										)}
									/>
									<Button
										type="submit"
										disabled={loading}
										variant={"default"}
										className="items-center place-content-center justify-center w-full"
									>
										{loading && <Loader />} Accedi
									</Button>
								</form>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ResetPassword;

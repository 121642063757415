import { z } from "zod";


export const getApiNccTeamNccLocationListNccTeamIdPathParamsSchema = z.object({ "ncc_team_id": z.number().describe("The ID of the location.") });


export const getApiNccTeamNccLocationListNccTeamIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiNccTeamNccLocationListNccTeamId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const getApiNccTeamNccLocationListNccTeamId404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiNccTeamNccLocationListNccTeamIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiNccTeamUserRoleAddMutationRequest, PostApiNccTeamUserRoleAddMutationResponse, PostApiNccTeamUserRoleAddHeaderParams, PostApiNccTeamUserRoleAdd403 } from "../models/PostApiNccteamUserRoleAdd";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiNccTeamUserRoleAddClient = typeof client<PostApiNccTeamUserRoleAddMutationResponse, PostApiNccTeamUserRoleAdd403, PostApiNccTeamUserRoleAddMutationRequest>;
type PostApiNccTeamUserRoleAdd = {
    data: PostApiNccTeamUserRoleAddMutationResponse;
    error: PostApiNccTeamUserRoleAdd403;
    request: PostApiNccTeamUserRoleAddMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiNccTeamUserRoleAddHeaderParams;
    response: PostApiNccTeamUserRoleAddMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiNccTeamUserRoleAddClient>[0]>;
        return: Awaited<ReturnType<PostApiNccTeamUserRoleAddClient>>;
    };
};
/**
 * @description Calling NccTeam Owner adds the specified Role of the userAbort operation
 * @summary AddUserRoleNccTeam
 * @link /api/ncc-team/user/role/add
 */
export function usePostApiNccTeamUserRoleAddHook(headers?: PostApiNccTeamUserRoleAdd["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiNccTeamUserRoleAdd["response"], PostApiNccTeamUserRoleAdd["error"], PostApiNccTeamUserRoleAdd["request"]>;
    client?: PostApiNccTeamUserRoleAdd["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiNccTeamUserRoleAddHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiNccTeamUserRoleAdd["data"], PostApiNccTeamUserRoleAdd["error"], PostApiNccTeamUserRoleAdd["request"]>({
                method: "post",
                url: `/api/ncc-team/user/role/add`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
import { z } from "zod";


export const getApiTeamInvitesIssuedTeamIdPathParamsSchema = z.object({ "team_id": z.number().describe("Team id to search issued invites for") });


export const getApiTeamInvitesIssuedTeamIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiTeamInvitesIssuedTeamId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const getApiTeamInvitesIssuedTeamId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiTeamInvitesIssuedTeamIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
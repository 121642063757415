import { z } from "zod";


export const postApiInvoiceBuyHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiInvoiceBuy200Schema = z.string();

 /**
 * @description Not available
 */
export const postApiInvoiceBuy403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiInvoiceBuyMutationRequestSchema = z.object({ "billable": z.string().describe("Must be one of <code>TableTier</code>, <code>Ride</code>, or <code>TicketTier</code>."), "billable_id": z.number() });

 /**
 * @description Success
 */
export const postApiInvoiceBuyMutationResponseSchema = z.string();
import { z } from "zod";


export const postApiUserUpdateLocaleHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiUserUpdateLocale200Schema = z.object({});

 /**
 * @description Not available
 */
export const postApiUserUpdateLocale404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiUserUpdateLocaleMutationRequestSchema = z.object({ "locale": z.string().describe("Must be one of <code>en</code> or <code>it</code> Must not be greater than 2 characters.").optional() });

 /**
 * @description Success
 */
export const postApiUserUpdateLocaleMutationResponseSchema = z.object({});
import { AUTH_CRED } from "@/hooks/auth/constants";
import Cookie from "js-cookie";
import type { THasType, TSetAuthCred } from "./types";

export const allowedRoles = ["Validator", "Admin", "ExternalValidator"] as const;

/**
 * Sets the authentication credentials in a cookie.
 * @param {TSetAuthCred} options - The options object containing the data and permission account.
 * @returns {void}
 */
export function setAuthCredentials({ data, permissionAccount }: TSetAuthCred): void {
	Cookie.set(AUTH_CRED, `${data.access_token + permissionAccount}`);
}

/**
 * Retrieves the authentication credentials from the cookie.
 * @returns An object containing the authentication token.
 */
export function getAuthCredentials(): { token: string | null } {
	const authCred = Cookie.get(AUTH_CRED);
	let dataReturned: {
		token: string | null;
	} = {
		token: null,
	};
	if (authCred) {
		dataReturned = { token: authCred };
	}
	return dataReturned;
}

/**
 * Checks if the user has access based on the input data and allowed roles.
 * @param input - The input object containing data and allowed roles.
 * @returns A boolean value indicating whether the user has access or not.
 */
export function hasAccess({ input }: { input: THasType }): boolean {
	if (input.data.permissions) {
		return Boolean(
			input.allowedRoles.find((aRole: { toString: () => string }) =>
				input.data.permissions?.toString().includes(aRole.toString()),
			),
		);
	}
	return false;
}

/**
 * Checks if the user is authenticated based on the provided cookies.
 * @param cookies - The cookies object containing the token and permissions.
 * @returns A boolean indicating whether the user is authenticated.
 */
export function isAuthenticated({
	cookies,
}: {
	cookies: {
		token: string | null;
		permissions: string | null;
	};
}): boolean {
	return !!cookies.token && Array.isArray(cookies.permissions) && !!cookies.permissions.length;
}

import { z } from "zod";


export const postApiUserUpdateProfileHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiUserUpdateProfile200Schema = z.string();

 /**
 * @description Not available
 */
export const postApiUserUpdateProfile404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiUserUpdateProfileMutationRequestSchema = z.object({ "name": z.string().describe("Must not be greater than 255 characters."), "email": z.string().email().describe("Must be a valid email address. Must not be greater than 255 characters."), "phone": z.string(), "city": z.string(), "city_latitude": z.string().describe("City latitude"), "city_longitude": z.string().describe("City longitude") });

 /**
 * @description Success
 */
export const postApiUserUpdateProfileMutationResponseSchema = z.string();
import { z } from "zod";


export const postApiTransportTierCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportTierCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiTransportTierCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportTierCreateMutationRequestSchema = z.object({ "name": z.string().describe("Must not be greater than 32 characters."), "description": z.string().describe("Must not be greater than 100 characters."), "rules": z.string().describe("Must not be greater than 64 characters.").optional(), "activation_fee": z.string().describe("Activation fee"), "activation_fee_reason": z.string().describe("Must not be greater than 128 characters."), "max_seats": z.number().optional(), "vehicle_type_id": z.number(), "ncc_team_id": z.number() });

 /**
 * @description Success
 */
export const postApiTransportTierCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
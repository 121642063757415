import { z } from "zod";


export const postApiTransportRideCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportRideCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const postApiTransportRideCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportRideCreateMutationRequestSchema = z.object({ "from_address": z.string().describe("Must not be greater than 100 characters."), "from_latitude": z.string().describe("From latitude"), "from_longitude": z.string().describe("From longitude"), "to_address": z.string().describe("Must not be greater than 100 characters."), "to_latitude": z.string().describe("To latitude"), "to_longitude": z.string().describe("To longitude"), "used_seats": z.number().describe("Must be between 1 and 128."), "pickup_at": z.string().describe("Must be a valid date. Must be a date after <code>yersterday</code>."), "vehicle_type_id": z.number() });

 /**
 * @description Success
 */
export const postApiTransportRideCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { z } from "zod";


export const getApiBackofficeTransportRideTierPricingGetRidetierpricingIdPathParamsSchema = z.object({ "rideTierPricing_id": z.number().describe("The ID of the RideTierPricing.") });


export const getApiBackofficeTransportRideTierPricingGetRidetierpricingIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description 0
 */
export const getApiBackofficeTransportRideTierPricingGetRidetierpricingId200Schema = z.object({ "data": z.object({}).optional() });

 /**
 * @description Not avaible
 */
export const getApiBackofficeTransportRideTierPricingGetRidetierpricingId404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description 0
 */
export const getApiBackofficeTransportRideTierPricingGetRidetierpricingIdQueryResponseSchema = z.object({ "data": z.object({}).optional() });
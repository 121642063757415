import { z } from "zod";


export const postApiBackofficeReviewValidatorProcessHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeReviewValidatorProcess200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeReviewValidatorProcess403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeReviewValidatorProcessMutationRequestSchema = z.object({ "id": z.number(), "action": z.string().describe("Must be one of <code>Approve</code>, <code>Hide</code>, <code>Archive</code>, or <code>Delete</code> Must not be greater than 16 characters.") });

 /**
 * @description Success
 */
export const postApiBackofficeReviewValidatorProcessMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
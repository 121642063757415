import { z } from "zod";


export const getApiUserAuthOauthDriverCallbackPathParamsSchema = z.object({ "driver": z.string().describe("Socialite driver name to use") });


export const getApiUserAuthOauthDriverCallbackHeaderParamsSchema = z.object({ "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiUserAuthOauthDriverCallback200Schema = z.object({});

 /**
 * @description Not avaible
 */
export const getApiUserAuthOauthDriverCallback404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiUserAuthOauthDriverCallbackQueryResponseSchema = z.object({});
import { z } from "zod";


export const postApiTransportVehicleTypeAllHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportVehicleTypeAll200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const postApiTransportVehicleTypeAll403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const postApiTransportVehicleTypeAllMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
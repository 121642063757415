import { z } from "zod";


export const postApiTransportRiderSearchHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Not avaible
 */
export const postApiTransportRiderSearch403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportRiderSearchMutationRequestSchema = z.object({ "latitude": z.string().describe("Latitude"), "longitude": z.string().describe("Longitude"), "radius": z.string().describe("Radius"), "from_latitude": z.string().describe("From latitude"), "from_longitude": z.string().describe("From longitude"), "to_latitude": z.string().describe("To latitude"), "to_longitude": z.string().describe("To longitude"), "when": z.string().describe("Must be a valid date. Must be a date after or equal to <code>today</code>."), "used_seats": z.number(), "reviews_range_begin": z.number().describe("Must be between 0 and 5.").optional(), "reviews_range_end": z.number().describe("Must be between 0 and 5.").optional() });


export const postApiTransportRiderSearchMutationResponseSchema = z.any();
import { z } from "zod";


export const getApiBackofficeUserDeleteUserIdPathParamsSchema = z.object({ "user_id": z.number().describe("Optional parameter. optional User id to look for") });


export const getApiBackofficeUserDeleteUserIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiBackofficeUserDeleteUserId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const getApiBackofficeUserDeleteUserId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiBackofficeUserDeleteUserIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
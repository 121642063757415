import type { PagedRideTiers } from "@/routes/webpanel/rates/_types";
import {
	type InfiniteData,
	type QueryKey,
	type UseInfiniteQueryOptions,
	type UseInfiniteQueryResult,
	keepPreviousData,
	useInfiniteQuery,
} from "@tanstack/react-query";
import type {
	GetApiTransportTierListNccteamId403,
	GetApiTransportTierListNccteamIdHeaderParams,
	GetApiTransportTierListNccteamIdPathParams,
	GetApiTransportTierListNccteamIdQueryResponse,
} from "api/gen";
import type client from "api/templatesSDK/client";
import axiosClient from "api/templatesSDK/client";

type GetApiTransportTierListNccteamIdClient = typeof client<
	GetApiTransportTierListNccteamIdQueryResponse,
	GetApiTransportTierListNccteamId403,
	never
>;
type GetApiTransportTierListNccteamId = {
	data: GetApiTransportTierListNccteamIdQueryResponse;
	error: GetApiTransportTierListNccteamId403;
	request: never;
	pathParams: GetApiTransportTierListNccteamIdPathParams;
	queryParams: never;
	headerParams: GetApiTransportTierListNccteamIdHeaderParams;
	response: GetApiTransportTierListNccteamIdQueryResponse;
	client: {
		parameters: Partial<Parameters<GetApiTransportTierListNccteamIdClient>[0]>;
		return: Awaited<ReturnType<GetApiTransportTierListNccteamIdClient>>;
	};
};

export const getApiTransportTierListNccteamIdQueryKey = (
	nccTeamId: GetApiTransportTierListNccteamIdPathParams["nccTeam_id"],
) => ["/api/transport/tier/list/:nccTeam_id"] as const;
export type GetApiTransportTierListNccteamIdQueryKey = ReturnType<typeof getApiTransportTierListNccteamIdQueryKey>;

export function useGetInfiniteScrollTransportTierListNccteamId<
	TData = InfiniteData<GetApiTransportTierListNccteamId["response"]>,
	TQueryData = GetApiTransportTierListNccteamId["response"],
	TQueryKey extends QueryKey = GetApiTransportTierListNccteamIdQueryKey,
>(
	nccTeamId: GetApiTransportTierListNccteamIdPathParams["nccTeam_id"],
	options: {
		query?: Partial<
			UseInfiniteQueryOptions<
				GetApiTransportTierListNccteamId["response"],
				GetApiTransportTierListNccteamId["error"],
				TData,
				TQueryData,
				TQueryKey
			>
		>;
		client?: GetApiTransportTierListNccteamId["client"]["parameters"];
	} = {},
): UseInfiniteQueryResult<TData, GetApiTransportTierListNccteamId["error"]> & {
	queryKey: TQueryKey;
} {
	const { query: queryOptions, client: clientOptions = {} } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getApiTransportTierListNccteamIdQueryKey(nccTeamId);
	const query = useInfiniteQuery({
		enabled: !!nccTeamId,
		queryKey,
		queryFn: async ({ pageParam }: { pageParam: number }) => {
			const res = await axiosClient<
				GetApiTransportTierListNccteamId["data"],
				GetApiTransportTierListNccteamId["error"]
			>({
				method: "get",
				url: `/api/transport/tier/list/${nccTeamId}?page=${pageParam ?? 1}`,
				...clientOptions,
			});
			return res.data;
		},
		initialPageParam: 1,
		getNextPageParam: (lastPage, allPages) => {
			const nextPage = lastPage.data?.length ? allPages.length + 1 : undefined;
			return nextPage;
		},
		refetchOnWindowFocus: false,
		placeholderData: keepPreviousData,
	}) as UseInfiniteQueryResult<TData, GetApiTransportTierListNccteamId["error"]> & {
		queryKey: TQueryKey;
	};
	query.queryKey = queryKey as TQueryKey;
	return query;
}

import { z } from "zod";


export const postApiTransportRiderLogHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportRiderLog200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not avaible
 */
export const postApiTransportRiderLog403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportRiderLogMutationRequestSchema = z.object({ "latitude": z.string().describe("Latitude"), "longitude": z.string().describe("Longitude") });

 /**
 * @description Success
 */
export const postApiTransportRiderLogMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const getApiUserLogoutHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiUserLogout200Schema = z.string();

 /**
 * @description Not available
 */
export const getApiUserLogout404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiUserLogoutQueryResponseSchema = z.string();
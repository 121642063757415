import client from "../../templatesSDK/client";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { GetApiTransportVehicleTypeAllQueryResponse, GetApiTransportVehicleTypeAllHeaderParams, GetApiTransportVehicleTypeAll403 } from "../models/GetApiTransportVehicleTypeAll";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type GetApiTransportVehicleTypeAllClient = typeof client<GetApiTransportVehicleTypeAllQueryResponse, GetApiTransportVehicleTypeAll403, never>;
type GetApiTransportVehicleTypeAll = {
    data: GetApiTransportVehicleTypeAllQueryResponse;
    error: GetApiTransportVehicleTypeAll403;
    request: never;
    pathParams: never;
    queryParams: never;
    headerParams: GetApiTransportVehicleTypeAllHeaderParams;
    response: GetApiTransportVehicleTypeAllQueryResponse;
    client: {
        parameters: Partial<Parameters<GetApiTransportVehicleTypeAllClient>[0]>;
        return: Awaited<ReturnType<GetApiTransportVehicleTypeAllClient>>;
    };
};
export const getApiTransportVehicleTypeAllQueryKey = () => ["/api/transport/vehicle/type/all"] as const;
export type GetApiTransportVehicleTypeAllQueryKey = ReturnType<typeof getApiTransportVehicleTypeAllQueryKey>;
export function getApiTransportVehicleTypeAllQueryOptions(headers?: GetApiTransportVehicleTypeAll["headerParams"], options: GetApiTransportVehicleTypeAll["client"]["parameters"] = {}) {
    const queryKey = getApiTransportVehicleTypeAllQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiTransportVehicleTypeAll["data"], GetApiTransportVehicleTypeAll["error"]>({
                method: "get",
                url: `/api/transport/vehicle/type/all`,
                headers: { ...headers, ...options.headers },
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Views all available VehicleTypesAbort operation
 * @summary GetVehicleTypes
 * @link /api/transport/vehicle/type/all
 */
export function useGetApiTransportVehicleTypeAllHook<TData = GetApiTransportVehicleTypeAll["response"], TQueryData = GetApiTransportVehicleTypeAll["response"], TQueryKey extends QueryKey = GetApiTransportVehicleTypeAllQueryKey>(headers?: GetApiTransportVehicleTypeAll["headerParams"], options: {
    query?: Partial<QueryObserverOptions<GetApiTransportVehicleTypeAll["response"], GetApiTransportVehicleTypeAll["error"], TData, TQueryData, TQueryKey>>;
    client?: GetApiTransportVehicleTypeAll["client"]["parameters"];
} = {}): UseQueryResult<TData, GetApiTransportVehicleTypeAll["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiTransportVehicleTypeAllQueryKey();
    const query = useQuery({
        ...getApiTransportVehicleTypeAllQueryOptions(headers, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, GetApiTransportVehicleTypeAll["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const getApiTransportVehicleTypeAllSuspenseQueryKey = () => ["/api/transport/vehicle/type/all"] as const;
export type GetApiTransportVehicleTypeAllSuspenseQueryKey = ReturnType<typeof getApiTransportVehicleTypeAllSuspenseQueryKey>;
export function getApiTransportVehicleTypeAllSuspenseQueryOptions(headers?: GetApiTransportVehicleTypeAll["headerParams"], options: GetApiTransportVehicleTypeAll["client"]["parameters"] = {}) {
    const queryKey = getApiTransportVehicleTypeAllSuspenseQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiTransportVehicleTypeAll["data"], GetApiTransportVehicleTypeAll["error"]>({
                method: "get",
                url: `/api/transport/vehicle/type/all`,
                headers: { ...headers, ...options.headers },
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Views all available VehicleTypesAbort operation
 * @summary GetVehicleTypes
 * @link /api/transport/vehicle/type/all
 */
export function useGetApiTransportVehicleTypeAllHookSuspense<TData = GetApiTransportVehicleTypeAll["response"], TQueryKey extends QueryKey = GetApiTransportVehicleTypeAllSuspenseQueryKey>(headers?: GetApiTransportVehicleTypeAll["headerParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<GetApiTransportVehicleTypeAll["response"], GetApiTransportVehicleTypeAll["error"], TData, TQueryKey>>;
    client?: GetApiTransportVehicleTypeAll["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, GetApiTransportVehicleTypeAll["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiTransportVehicleTypeAllSuspenseQueryKey();
    const query = useSuspenseQuery({
        ...getApiTransportVehicleTypeAllSuspenseQueryOptions(headers, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, GetApiTransportVehicleTypeAll["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
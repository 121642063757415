import { z } from "zod";


export const postApiNccTeamNccLocationCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiNccTeamNccLocationCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const postApiNccTeamNccLocationCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiNccTeamNccLocationCreateMutationRequestSchema = z.object({ "ncc_team_id": z.number(), "name": z.string().describe("Must not be greater than 64 characters."), "description": z.string().describe("Must not be greater than 512 characters.").optional(), "address": z.string().describe("Must not be greater than 100 characters."), "latitude": z.string().describe("Latitude"), "longitude": z.string().describe("Longitude"), "action_range": z.string().describe("Longitude") });

 /**
 * @description Success
 */
export const postApiNccTeamNccLocationCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
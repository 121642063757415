import { z } from "zod";


export const postApiBackofficeNccteamCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeNccteamCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeNccteamCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeNccteamCreateMutationRequestSchema = z.object({ "name": z.string().describe("Must contain only letters, numbers, dashes and underscores. Must not be greater than 64 characters."), "description": z.string().describe("Must not be greater than 255 characters.").optional(), "company_country": z.string().describe("Must not be greater than 2 characters."), "company_fiscal_code": z.string().describe("Must not be greater than 64 characters."), "company_tax_code": z.string().describe("Must not be greater than 64 characters."), "owner_user_id": z.number() });

 /**
 * @description Success
 */
export const postApiBackofficeNccteamCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
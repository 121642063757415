import { z } from "zod";


export const postApiUserAuthLoginHeaderParamsSchema = z.object({ "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiUserAuthLogin200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).optional() });

 /**
 * @description Not avaible
 */
export const postApiUserAuthLogin404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiUserAuthLoginMutationRequestSchema = z.object({ "email": z.string().email().describe("Must be a valid email address. Must not be greater than 255 characters."), "password": z.string(), "device_name": z.string().describe("Must not be greater than 255 characters.") });

 /**
 * @description Success
 */
export const postApiUserAuthLoginMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).optional() });
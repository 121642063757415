import { z } from "zod";


export const postApiBackofficeReportValidatorProcessHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeReportValidatorProcess200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeReportValidatorProcess403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeReportValidatorProcessMutationRequestSchema = z.object({ "id": z.number(), "reviewer_note": z.string().describe("Must not be greater than 255 characters.") });

 /**
 * @description Success
 */
export const postApiBackofficeReportValidatorProcessMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const getApiBackofficeTransportRideTierListHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description 0
 */
export const getApiBackofficeTransportRideTierList200Schema = z.object({ "data": z.array(z.object({})).optional() });

 /**
 * @description Not available
 */
export const getApiBackofficeTransportRideTierList403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description 0
 */
export const getApiBackofficeTransportRideTierListQueryResponseSchema = z.object({ "data": z.array(z.object({})).optional() });
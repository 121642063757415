import { z } from "zod";


export const postApiUserAuthOauthDriverCallbackTokenPathParamsSchema = z.object({ "driver": z.string().describe("Socialite driver name to use") });


export const postApiUserAuthOauthDriverCallbackTokenHeaderParamsSchema = z.object({ "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiUserAuthOauthDriverCallbackToken200Schema = z.object({});

 /**
 * @description Not avaible
 */
export const postApiUserAuthOauthDriverCallbackToken404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiUserAuthOauthDriverCallbackTokenMutationRequestSchema = z.object({ "token": z.string(), "secret": z.string().optional(), "first_name": z.string().optional(), "last_name": z.string().optional() });

 /**
 * @description Success
 */
export const postApiUserAuthOauthDriverCallbackTokenMutationResponseSchema = z.object({});
import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiUserAuthLoginMutationRequest, PostApiUserAuthLoginMutationResponse, PostApiUserAuthLoginHeaderParams, PostApiUserAuthLogin404 } from "../models/PostApiUserAuthLogin";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiUserAuthLoginClient = typeof client<PostApiUserAuthLoginMutationResponse, PostApiUserAuthLogin404, PostApiUserAuthLoginMutationRequest>;
type PostApiUserAuthLogin = {
    data: PostApiUserAuthLoginMutationResponse;
    error: PostApiUserAuthLogin404;
    request: PostApiUserAuthLoginMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiUserAuthLoginHeaderParams;
    response: PostApiUserAuthLoginMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiUserAuthLoginClient>[0]>;
        return: Awaited<ReturnType<PostApiUserAuthLoginClient>>;
    };
};
/**
 * @description Login that user. If everything is okay, you'll get a 200 OK response and the data.Otherwise, the request will fail with a 404 error, and a response listing the failed message.
 * @summary Login
 * @link /api/user/auth/login
 */
export function usePostApiUserAuthLoginHook(headers?: PostApiUserAuthLogin["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiUserAuthLogin["response"], PostApiUserAuthLogin["error"], PostApiUserAuthLogin["request"]>;
    client?: PostApiUserAuthLogin["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiUserAuthLoginHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiUserAuthLogin["data"], PostApiUserAuthLogin["error"], PostApiUserAuthLogin["request"]>({
                method: "post",
                url: `/api/user/auth/login`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
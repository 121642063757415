import { z } from "zod";


export const getApiBackofficeActivationGetActivablePathParamsSchema = z.object({ "activable": z.string().describe("Activable_type to search for") });


export const getApiBackofficeActivationGetActivableHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiBackofficeActivationGetActivable200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const getApiBackofficeActivationGetActivable403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiBackofficeActivationGetActivableQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
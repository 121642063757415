import { z } from "zod";


export const postApiTransportTierPricingPricingCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportTierPricingPricingCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiTransportTierPricingPricingCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportTierPricingPricingCreateMutationRequestSchema = z.object({ "ride_tier_id": z.number(), "max_distance": z.number().optional(), "km_price": z.string().describe("KM price fee") });

 /**
 * @description Success
 */
export const postApiTransportTierPricingPricingCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import Navbar from "@/components/navigation/Navbar";
import Sidebar from "@/components/navigation/Sidebar";
import { Button, buttonVariants } from "@/components/ui/button";
import { env } from "@/env";
import { getAuthCredentials } from "@/hooks/auth/auth-utils";
import { cn } from "@/lib/utils";
import { type QueryClient, useQueryErrorResetBoundary } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
	Link,
	Outlet,
	ScrollRestoration,
	createRootRouteWithContext,
	useRouter,
	useRouterState,
} from "@tanstack/react-router";
import { AxiosError } from "axios";
import { Suspense, lazy, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import ForgotPassword from "./forgotPassword";
import ResetPassword from "./resetPassword";
import SignIn from "./signIn";

export function ErrorRoutingComponent({ error, reset }: { error: Error; reset: () => void }) {
	const router = useRouter();
	const queryErrorResetBoundary = useQueryErrorResetBoundary();
	useEffect(() => {
		// Reset the query error boundary
		queryErrorResetBoundary.reset();
	}, [queryErrorResetBoundary]);
	return (
		<div className="flex ">
			<section className="container flex justify-center items-center p-6 py-12 mx-auto">
				<div>
					<p className="text-6xl">
						{(error instanceof AxiosError && error.status) ?? (error instanceof AxiosError && error.response?.status)}
					</p>
					<h1 className="mt-3 text-2xl mb-4 text-accent">{(error as Error).message}</h1>
					<Button
						type="button"
						onClick={() => {
							// Reset the router error boundary
							reset();
							// Invalidate the route to reload the loader
							router.invalidate();
						}}
					>
						Ritenta
					</Button>
				</div>
			</section>
		</div>
	);
}

const TanStackRouterDevtools =
	env.VITE_NODE_ENV === "production"
		? () => null // Render nothing in production
		: lazy(() =>
				// Lazy load in development
				import("@tanstack/router-devtools").then(res => ({
					default: res.TanStackRouterDevtools,
					// For Embedded Mode
					// default: res.TanStackRouterDevtoolsPanel
				})),
			);

export const Route = createRootRouteWithContext<{
	queryClient: QueryClient;
	auth: { token: string | null };
}>()({
	component: () => {
		const location = useRouterState({
			select: state => state.location,
		});

		if (
			getAuthCredentials().token === null &&
			location.pathname !== "/forgotPassword" &&
			location.pathname !== "/resetPassword"
		) {
			return <SignIn />;
		}

		if (location.pathname === "/forgotPassword") {
			return <ForgotPassword />;
		}

		if (location.pathname === "/resetPassword") {
			return <ResetPassword />;
		}

		return <RootComponent />;
	},
	errorComponent: ({ error, reset }) => ErrorRoutingComponent({ error, reset }),
	notFoundComponent: () => {
		return (
			<section className="container flex justify-center items-center p-6 py-12 mx-auto">
				<div>
					<p className="text-6xl">404</p> <h1 className="mt-3 text-2xl">Questa pagina non esiste</h1>
					<Link
						to={"/webpanel/veichles"}
						search={{ page: 1 }}
						className={cn({ inputs: [buttonVariants(), "text-2xl mt-3  hover:cursor-pointer"] })}
					>
						Torna alla home
					</Link>
				</div>
			</section>
		);
	},
});

/**
 * Root component of the application.
 * Renders the main layout of the application, including the navbar, sidebar, and content.
 * USING TANSTACK ROUTER
 * @external https://github.com/tanstack/router
 */
function RootComponent() {
	// const PUBLISHABLE_KEY = env.VITE_STRIPE_PUBLISHABLE_KEY;
	// const stripePromise = useMemo(() => loadStripe(PUBLISHABLE_KEY), [PUBLISHABLE_KEY]);

	return (
		// <Elements stripe={stripePromise}>
		<>
			<Toaster />
			<main className="grid grid-rows-[auto_1fr] w-screen h-screen bg-black overflow-hidden">
				<Navbar />
				<div className="grid grid-cols-[auto_1fr] overflow-hidden">
					<Sidebar />
					<Outlet />
				</div>
			</main>
			<ScrollRestoration />
			<ReactQueryDevtools buttonPosition="bottom-left" />
			<Suspense>
				<TanStackRouterDevtools position="bottom-right" />
			</Suspense>
		</>
		// </Elements>
	);
}

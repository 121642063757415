import { z } from "zod";


export const postApiNccTeamEditHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiNccTeamEdit200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiNccTeamEdit403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiNccTeamEditMutationRequestSchema = z.object({ "id": z.number(), "description": z.string().optional(), "opening_hours": z.string().describe("Must be a valid JSON string.") });

 /**
 * @description Success
 */
export const postApiNccTeamEditMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const getApiUserMeHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiUserMe200Schema = z.object({ "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const getApiUserMe404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiUserMeQueryResponseSchema = z.object({ "data": z.object({}).describe("Data").optional() });
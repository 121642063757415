import { z } from "zod";


export const postApiNccTeamUserRemoveHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiNccTeamUserRemove200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiNccTeamUserRemove403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiNccTeamUserRemoveMutationRequestSchema = z.object({ "id": z.number(), "user_id": z.number() });

 /**
 * @description Success
 */
export const postApiNccTeamUserRemoveMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
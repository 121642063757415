import { z } from "zod";


export const getApiTransportTierPriceVariantGetRidetierpricevariantIdPathParamsSchema = z.object({ "rideTierPriceVariant_id": z.number().describe("The ID of the RideTierPriceVariant.") });


export const getApiTransportTierPriceVariantGetRidetierpricevariantIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiTransportTierPriceVariantGetRidetierpricevariantId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const getApiTransportTierPriceVariantGetRidetierpricevariantId404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiTransportTierPriceVariantGetRidetierpricevariantIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { z } from "zod";


export const getApiAttachmentDeletephotoUuidPathParamsSchema = z.object({ "uuid": z.string().describe("Attachment id to search for") });


export const getApiAttachmentDeletephotoUuidHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiAttachmentDeletephotoUuid200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const getApiAttachmentDeletephotoUuid403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiAttachmentDeletephotoUuidQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
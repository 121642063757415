import { z } from "zod";


export const postApiAttachmentGetphotosurlHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiAttachmentGetphotosurl200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiAttachmentGetphotosurl403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiAttachmentGetphotosurlMutationRequestSchema = z.object({ "type_id": z.number(), "type": z.string().describe("Must be one of <code>Event</code>, <code>Location</code>, <code>Rider</code>, or <code>TicketTier</code>.") });

 /**
 * @description Success
 */
export const postApiAttachmentGetphotosurlMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiTransportTierPriceVariantCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportTierPriceVariantCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiTransportTierPriceVariantCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportTierPriceVariantCreateMutationRequestSchema = z.object({ "ride_tier_id": z.number(), "name": z.string().describe("Must not be greater than 32 characters."), "description": z.string().describe("Must not be greater than 100 characters."), "rules": z.string().describe("Must not be greater than 64 characters.").optional(), "extra_km_price": z.string().describe("KM price fee"), "extra_km_price_reason": z.string().describe("Must not be greater than 128 characters."), "ncc_team_id": z.number() });

 /**
 * @description Success
 */
export const postApiTransportTierPriceVariantCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const postApiBackofficeUserRoleRemoveHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeUserRoleRemove200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeUserRoleRemove403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeUserRoleRemoveMutationRequestSchema = z.object({ "user_id": z.number(), "role": z.string().describe("Must be one of <code>Taxi</code>, <code>Validator</code>, or <code>ExternalValidator</code> Must not be greater than 32 characters.") });

 /**
 * @description Success
 */
export const postApiBackofficeUserRoleRemoveMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
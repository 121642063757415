import { z } from "zod";


export const postApiTicketingTableTierSetpublicHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTicketingTableTierSetpublic200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not avaible
 */
export const postApiTicketingTableTierSetpublic403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTicketingTableTierSetpublicMutationRequestSchema = z.object({ "id": z.number(), "public": z.boolean() });

 /**
 * @description Success
 */
export const postApiTicketingTableTierSetpublicMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
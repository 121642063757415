import type { RefObject } from "react";
import { useCallback, useEffect, useRef, useState } from "react";

export const DEFAULT_IGNORE_CLASS = "ignore-onclickoutside";

/**
 * Checks if the browser supports passive event listeners.
 *
 * @returns {boolean} Returns true if the browser supports passive event listeners, false otherwise.
 */
function canUsePassiveEvents(): boolean {
	if (typeof window === "undefined" || typeof window.addEventListener !== "function") return false;

	let passive = false;
	const options = Object.defineProperty({}, "passive", {
		get() {
			passive = true;
		},
	});
	const noop = () => null;

	window.addEventListener("test", noop, options);
	window.removeEventListener("test", noop, options);

	return passive;
}

export type Callback<T extends Event = Event> = (event: T) => void;
type El = HTMLElement;
type Refs = RefObject<El>[];
/**
 * Options for the onClickOutside function.
 */
export interface Options {
	refs?: Refs;
	disabled?: boolean;
	eventTypes?: string[];
	excludeScrollbar?: boolean;
	ignoreClass?: string | string[];
	detectIFrame?: boolean;
}
type Return = (element: El | null) => void;

/**
 * Checks if an element has a specific class or an array of classes.
 *
 * @param el - The element to check.
 * @param cl - The class or classes to check for.
 * @returns `true` if the element has the specified class or classes, `false` otherwise.
 */
function checkClass(el: Element, cl: string | string[]): boolean {
	if (Array.isArray(cl)) {
		return cl.some(c => el.classList?.contains(c));
	}
	return el.classList?.contains(cl);
}

/**
 * Checks if an element or any of its ancestors has the specified ignore class(es).
 * @param el - The element to check.
 * @param ignoreClass - The ignore class(es) to check for. It can be a string or an array of strings.
 * @returns A boolean indicating whether the element or any of its ancestors has the ignore class(es).
 */
function hasIgnoreClass(el: Element, ignoreClass: string | string[]): boolean {
	while (el) {
		if (Array.isArray(ignoreClass) && el) {
			if (ignoreClass.some(c => checkClass(el, c))) return true;
		} else if (checkClass(el, ignoreClass)) {
			return true;
		}

		const parentEl = el.parentElement;
		el = parentEl as Element;
	}

	return false;
}

/**
 * Hook that detects clicks outside of a specified set of elements.
 *
 * @param callback - The callback function to be called when a click outside is detected.
 * @param options - The options for configuring the behavior of the hook.
 * @returns A ref that should be attached to the element(s) to be monitored for clicks outside.
 */
function useOnclickOutside(
	callback: Callback,
	{
		refs: refsOpt,
		disabled,
		eventTypes = ["mousedown", "touchstart"],
		excludeScrollbar,
		ignoreClass = DEFAULT_IGNORE_CLASS,
		detectIFrame = true,
	}: Options = {},
): Return {
	const [refsState, setRefsState] = useState<Refs>([]);
	const callbackRef = useRef(callback);
	callbackRef.current = callback;

	const ref: Return = useCallback(el => setRefsState(prevState => [...prevState, { current: el }]), []);

	useEffect(() => {
		if (!refsOpt?.length && !refsState.length) return;

		const getEls = () => {
			const els: El[] = [];
			for (const { current } of refsOpt || refsState) {
				if (current) {
					els.push(current);
				}
			}
			return els;
		};

		const handler = (e: unknown) => {
			if (
				!hasIgnoreClass(e as Element, ignoreClass) &&
				!(
					excludeScrollbar &&
					((e: MouseEvent): boolean =>
						document.documentElement.clientWidth <= e.clientX || document.documentElement.clientHeight <= e.clientY)(
						e as MouseEvent,
					)
				) &&
				getEls().every(el => !el.contains((e as Event).target as Node | null))
			)
				callbackRef.current(e as Event);
		};

		const blurHandler = (e: FocusEvent) =>
			// On firefox the iframe becomes document.activeElement in the next event loop
			setTimeout(() => {
				const { activeElement } = document;

				if (
					activeElement?.tagName === "IFRAME" &&
					!hasIgnoreClass(activeElement, ignoreClass) &&
					!getEls().includes(activeElement as HTMLIFrameElement)
				)
					callbackRef.current(e);
			}, 0);

		const removeEventListener = () => {
			for (const type of eventTypes) {
				const options = ((type: string): { passive: boolean } | boolean =>
					type.includes("touch") && canUsePassiveEvents() ? { passive: true } : false)(type);
				if (typeof options === "boolean") {
					document.removeEventListener(type, handler, options);
				} else {
					document.removeEventListener(type, handler, options.passive);
				}
			}

			if (detectIFrame) window.removeEventListener("blur", blurHandler);
		};

		for (const type of eventTypes) {
			document.addEventListener(
				type,
				handler,
				((type: string): { passive: boolean } | boolean =>
					type.includes("touch") && canUsePassiveEvents() ? { passive: true } : false)(type),
			);
		}

		if (detectIFrame) window.addEventListener("blur", blurHandler);

		return () => removeEventListener();
	}, [refsState, ignoreClass, excludeScrollbar, disabled, detectIFrame, JSON.stringify(eventTypes)]);

	return ref;
}

export default useOnclickOutside;

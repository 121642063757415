import { z } from "zod";


export const postApiUserAuthRegisterHeaderParamsSchema = z.object({ "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiUserAuthRegister200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not avaible
 */
export const postApiUserAuthRegister404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiUserAuthRegisterMutationRequestSchema = z.object({ "name": z.string().describe("Must not be greater than 255 characters."), "email": z.string().email().describe("Must be a valid email address. Must not be greater than 255 characters."), "password": z.string(), "gender": z.string().describe("Must be one of <code>M</code> or <code>F</code>."), "dob": z.string().describe("Must be a valid date. Must be a date before <code>today</code>."), "phone": z.string(), "city": z.string(), "city_latitude": z.string().describe("City latitude"), "city_longitude": z.string().describe("City longitude"), "max_budget": z.string().describe("Max budget"), "preferred_musics": z.string().describe("Must not be greater than 255 characters.").optional(), "preferred_location_category": z.string().describe("Must not be greater than 255 characters.").optional(), "password_confirmation": z.string().describe("Password confirmation") });

 /**
 * @description Success
 */
export const postApiUserAuthRegisterMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
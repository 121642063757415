import { z } from "zod";


export const postApiUserFcmRegisterHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiUserFcmRegister200Schema = z.object({});

 /**
 * @description Not available
 */
export const postApiUserFcmRegister404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiUserFcmRegisterMutationRequestSchema = z.object({ "token": z.string().describe("Must not be greater than 255 characters.") });

 /**
 * @description Success
 */
export const postApiUserFcmRegisterMutationResponseSchema = z.object({});
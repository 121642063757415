import { z } from "zod";


export const getApiTicketingTicketTierGetEventIdPathParamsSchema = z.object({ "event_id": z.number().describe("Event id to search for") });


export const getApiTicketingTicketTierGetEventIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiTicketingTicketTierGetEventId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const getApiTicketingTicketTierGetEventId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiTicketingTicketTierGetEventIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { z } from "zod";


export const postApiTicketingTableTierCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTicketingTableTierCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const postApiTicketingTableTierCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTicketingTableTierCreateMutationRequestSchema = z.object({ "team_id": z.number(), "event_id": z.number(), "name": z.string().describe("Must not be greater than 32 characters."), "description": z.string().describe("Must not be greater than 100 characters."), "rules": z.string().describe("Must not be greater than 64 characters.").optional(), "price": z.string().describe("Price"), "quantity": z.number(), "min_party_size": z.number(), "max_party_size": z.number(), "expires_at": z.string().describe("Must be a valid date. Must be a date after <code>yesterday</code>.").optional() });

 /**
 * @description Success
 */
export const postApiTicketingTableTierCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { z } from "zod";


export const postApiBackofficeTransportRideTierPricingEditHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeTransportRideTierPricingEdit200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeTransportRideTierPricingEdit403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeTransportRideTierPricingEditMutationRequestSchema = z.object({ "id": z.number(), "max_distance": z.number().optional(), "km_price": z.string().describe("KM price fee") });

 /**
 * @description Success
 */
export const postApiBackofficeTransportRideTierPricingEditMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const getApiTransportVehicleListNccteamIdAvailablePathParamsSchema = z.object({ "nccTeam_id": z.number().describe("The ID of the nccTeam."), "available": z.string().describe("Optional parameter. bool optional If specified finds only available or unavailable Vehicles") });


export const getApiTransportVehicleListNccteamIdAvailableHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiTransportVehicleListNccteamIdAvailable200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const getApiTransportVehicleListNccteamIdAvailable403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiTransportVehicleListNccteamIdAvailableQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { z } from "zod";


export const getApiTransportRiderGetRiderUserIdPathParamsSchema = z.object({ "rider_user_id": z.number().describe("The ID of the rider user.") });


export const getApiTransportRiderGetRiderUserIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Not avaible
 */
export const getApiTransportRiderGetRiderUserId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const getApiTransportRiderGetRiderUserId500Schema = z.object({ "message": z.string().optional(), "exception": z.string().optional(), "file": z.string().optional(), "line": z.number().optional(), "trace": z.array(z.object({})).optional() });


export const getApiTransportRiderGetRiderUserIdQueryResponseSchema = z.any();
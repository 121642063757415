import { z } from "zod";


export const postApiBackofficeTransportVehicleTypeCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeTransportVehicleTypeCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeTransportVehicleTypeCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeTransportVehicleTypeCreateMutationRequestSchema = z.object({ "name": z.string().describe("Must contain only letters and numbers. Must not be greater than 64 characters."), "title": z.string().describe("Must contain only letters and numbers. Must not be greater than 128 characters.") });

 /**
 * @description Success
 */
export const postApiBackofficeTransportVehicleTypeCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { cn } from "@/lib/utils";
import {
	FloatingArrow,
	FloatingFocusManager,
	FloatingPortal,
	type Placement,
	arrow,
	offset,
	useClientPoint,
	useFloating,
	useFocus,
	useHover,
	useInteractions,
	useTransitionStyles,
} from "@floating-ui/react";
import type { ReactNode } from "@tanstack/react-router";
import { useRef, useState } from "react";

/**
 * Tooltip component displays a message when hovered over.
 *
 * @param {string} message - The message to be displayed in the tooltip.
 * @param {React.ReactNode} children - The content to be wrapped by the tooltip.
 * @returns {React.ReactNode} The tooltip component.
 *
 * @example
 * ```tsx
 * const App: React.FC = () => {
 *  return (
 *       <Tooltip message={`Hello`}><p>Hello</p> <Tooltip/>
 *  );
 * };
 * ```
 */
const Tooltip = (props: {
	message: ReactNode | string;
	children?: ReactNode;
	className?: string;
	placement?: Placement;
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const arrowRef = useRef(null);

	const { refs, floatingStyles, context } = useFloating({
		open: isOpen,
		onOpenChange: setIsOpen,
		transform: false,
		middleware: [offset(16), arrow({ element: arrowRef })],
		placement: props.placement ?? "top",
	});

	const { isMounted, styles } = useTransitionStyles(context, {
		duration: 500,

		initial: ({ side }) => ({
			opacity: 0,
			transform: `scale(0.5) ${side === "top" ? "translateY(12px)" : ""}${
				side === "bottom" ? "translateY(-12px)" : ""
			}`,
		}),
		common: {
			// transitionTimingFunction: "ease-in-out",
			transitionTimingFunction: "cubic-bezier(.3,2,.1,1)",
			zIndex: 100,
			width: "inherit",
			border: "none",
		},
	});

	// Merge all the interactions into prop getters
	const { getReferenceProps, getFloatingProps } = useInteractions([
		useHover(context),
		useFocus(context),
		useClientPoint(context),
	]);

	return (
		<>
			<div
				ref={refs.setReference}
				{...getReferenceProps()}
			>
				{props.children}
			</div>
			<FloatingPortal>
				{isMounted ? (
					<FloatingFocusManager
						context={context}
						modal={false}
					>
						<div
							ref={refs.setFloating}
							style={{ ...floatingStyles, ...styles }}
							{...getFloatingProps()}
						>
							<FloatingArrow
								ref={arrowRef}
								context={context}
								className=" fill-background-content"
							/>
							<div
								{...props}
								className={cn({
									inputs: [
										"rounded-control border-none bg-background-content p-2 flex flex-col items-stretch shadow-xl shadow-background-base/25",
										props.className,
									],
								})}
							>
								{props.message}
							</div>
						</div>
					</FloatingFocusManager>
				) : null}
			</FloatingPortal>
		</>
	);
};

export default Tooltip;

import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiTransportTierEditMutationRequest, PostApiTransportTierEditMutationResponse, PostApiTransportTierEditHeaderParams, PostApiTransportTierEdit403 } from "../models/PostApiTransportTierEdit";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiTransportTierEditClient = typeof client<PostApiTransportTierEditMutationResponse, PostApiTransportTierEdit403, PostApiTransportTierEditMutationRequest>;
type PostApiTransportTierEdit = {
    data: PostApiTransportTierEditMutationResponse;
    error: PostApiTransportTierEdit403;
    request: PostApiTransportTierEditMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiTransportTierEditHeaderParams;
    response: PostApiTransportTierEditMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiTransportTierEditClient>[0]>;
        return: Awaited<ReturnType<PostApiTransportTierEditClient>>;
    };
};
/**
 * @description Edites a RideTier related to passed NccTeam. Calling user must be Owner of that Team.Abort operation
 * @summary EditRideTier
 * @link /api/transport/tier/edit
 */
export function usePostApiTransportTierEditHook(headers?: PostApiTransportTierEdit["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiTransportTierEdit["response"], PostApiTransportTierEdit["error"], PostApiTransportTierEdit["request"]>;
    client?: PostApiTransportTierEdit["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiTransportTierEditHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiTransportTierEdit["data"], PostApiTransportTierEdit["error"], PostApiTransportTierEdit["request"]>({
                method: "post",
                url: `/api/transport/tier/edit`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
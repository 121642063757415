import { z } from "zod";


export const postApiTransportRiderRidesGetFilteredHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportRiderRidesGetFiltered200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const postApiTransportRiderRidesGetFiltered403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportRiderRidesGetFilteredMutationRequestSchema = z.object({ "states": z.array(z.string()).describe("Must be one of <code>Ready</code>, <code>Started</code>, <code>Finished</code>, or <code>Cancelled</code> Must be at least 1 characters. Must not be greater than 64 characters.") });

 /**
 * @description Success
 */
export const postApiTransportRiderRidesGetFilteredMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { z } from "zod";


export const postApiPartyEditHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiPartyEdit200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiPartyEdit403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiPartyEditMutationRequestSchema = z.object({ "id": z.number(), "name": z.string().describe("Must not be greater than 64 characters."), "description": z.string().describe("Must not be greater than 128 characters.").optional() });

 /**
 * @description Success
 */
export const postApiPartyEditMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
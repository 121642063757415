import { z } from "zod";


export const postApiBackofficeReportSearchHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeReportSearch200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeReportSearch403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeReportSearchMutationRequestSchema = z.object({ "reviewed": z.boolean().optional(), "by": z.string().describe("Must be one of <code>Reporter</code>, <code>Reported</code>, or <code>Act</code>."), "model": z.string().describe("Must be one of <code>User</code>, <code>Rider</code>, <code>Ride</code>, <code>Team</code>, <code>Location</code>, <code>Event</code>, or <code>Party</code>.").optional(), "model_id": z.number().optional() });

 /**
 * @description Success
 */
export const postApiBackofficeReportSearchMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiTransportTierPriceVariantEditMutationRequest, PostApiTransportTierPriceVariantEditMutationResponse, PostApiTransportTierPriceVariantEditHeaderParams, PostApiTransportTierPriceVariantEdit403 } from "../models/PostApiTransportTierPriceVariantEdit";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiTransportTierPriceVariantEditClient = typeof client<PostApiTransportTierPriceVariantEditMutationResponse, PostApiTransportTierPriceVariantEdit403, PostApiTransportTierPriceVariantEditMutationRequest>;
type PostApiTransportTierPriceVariantEdit = {
    data: PostApiTransportTierPriceVariantEditMutationResponse;
    error: PostApiTransportTierPriceVariantEdit403;
    request: PostApiTransportTierPriceVariantEditMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiTransportTierPriceVariantEditHeaderParams;
    response: PostApiTransportTierPriceVariantEditMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiTransportTierPriceVariantEditClient>[0]>;
        return: Awaited<ReturnType<PostApiTransportTierPriceVariantEditClient>>;
    };
};
/**
 * @description Edites a RideTierPriceVariant and assigns it to calling NccTeam user. Calling user must be Owner of the passed NccTeam.Abort operation
 * @summary EditRideTierPriceVariant
 * @link /api/transport/tier/price-variant/edit
 */
export function usePostApiTransportTierPriceVariantEditHook(headers?: PostApiTransportTierPriceVariantEdit["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiTransportTierPriceVariantEdit["response"], PostApiTransportTierPriceVariantEdit["error"], PostApiTransportTierPriceVariantEdit["request"]>;
    client?: PostApiTransportTierPriceVariantEdit["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiTransportTierPriceVariantEditHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiTransportTierPriceVariantEdit["data"], PostApiTransportTierPriceVariantEdit["error"], PostApiTransportTierPriceVariantEdit["request"]>({
                method: "post",
                url: `/api/transport/tier/price-variant/edit`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
import { z } from "zod";


export const postApiBackofficeEventValidatorExternalProcessHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeEventValidatorExternalProcess200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeEventValidatorExternalProcess403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeEventValidatorExternalProcessMutationRequestSchema = z.object({ "id": z.number(), "action": z.string().describe("Must be one of <code>Approve</code>, <code>Publish</code>, or <code>Reject</code> Must not be greater than 16 characters."), "external_event_id": z.number().optional() });

 /**
 * @description Success
 */
export const postApiBackofficeEventValidatorExternalProcessMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
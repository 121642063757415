import { z } from "zod";


export const getApiNccTeamNccLocationGetLocationIdPathParamsSchema = z.object({ "location_id": z.number().describe("The ID of the location.") });


export const getApiNccTeamNccLocationGetLocationIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiNccTeamNccLocationGetLocationId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const getApiNccTeamNccLocationGetLocationId404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiNccTeamNccLocationGetLocationIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { Button } from "@/components/ui/button";
import Dropdown from "@/components/ui/dropdown";
import { cn } from "@/lib/utils";
import type { Placement } from "@floating-ui/react";
import { useEffect, useState } from "react";
import { GoChevronDown } from "react-icons/go";

/**
 * Represents an option in the select dropdown.
 */
export type SelectOption<OptionType> = {
	value: OptionType;
	label: string;
	disabled?: (currentValue: OptionType | undefined) => boolean;
};

/**
 * Represents the props for the Select component.
 */
export type SelectProps<OptionType> = {
	options: SelectOption<OptionType>[];
	value?: OptionType;
	onChange: (value: OptionType, name: string) => void;
	title: string;
	name: string;
	disabled?: boolean;
	size?: "md" | "sm" | "default";
	className?: string;
	placement?: Placement;
};

/**
 * A custom select dropdown component.
 * @param {SelectProps} props - The props for the Select component.
 * @returns {JSX.Element} The rendered Select component.
 *
 * @example
 * ```tsx
 * const App: React.FC = () => {
 *  const [selected, setSelected] = useState("");
 *  const options = [
 *       { value: "0", label: "Seleziona" },
 *       { value: "1", label: "Opzione 1" },
 *       { value: "2", label: "Opzione 2" },
 *       { value: "3", label: "Opzione 3" },
 *  ];
 *  const handleChange = (value: string, name: string) => {
 *       setSelected(value);
 *  };
 *  return (
 *       <Select
 *         title="Seleziona una opzione"
 *         name="opzione"
 *         value={selected}
 *         options={options}
 *         onChange={handleChange}
 *       />
 *  );
 * };
 */
const Select = <OptionType,>({
	title,
	name,
	value,
	options,
	disabled,
	size,
	className,
	placement,
	onChange,
}: SelectProps<OptionType>): JSX.Element => {
	const [selected, setSelected] = useState<SelectOption<OptionType> | undefined>(
		value ? options.find(option => option.value === value) : undefined,
	);

	useEffect(() => {
		if (value) setSelected(options.find(option => option.value === value));
	}, [value]);

	/**
	 * Handles the selection of an option in the select component.
	 * @param option - The selected option.
	 */
	const handleSelect = (option: SelectOption<OptionType>) => {
		onChange(option?.value, name);
		setSelected(option);
	};

	return (
		<>
			<Dropdown
				className={className}
				placement={placement}
				trigger={
					<Button
						size={size}
						type="button"
						disabled={disabled}
						className={cn({ inputs: ["w-full flex justify-between", className] })}
					>
						<span className="truncate">{selected?.label ?? title}</span>
						<GoChevronDown className="mt-1" />
					</Button>
				}
			>
				{options.map((option, index) => (
					<Button
						disabled={option.disabled?.(value)}
						onClick={() => handleSelect(option)}
						type="button"
						size={size}
						variant={"transparent"}
						align={"left"}
						key={`${option.label}${index}`}
						className={className}
					>
						{option.label}
					</Button>
				))}
			</Dropdown>
		</>
	);
};

export { Select };

import { z } from "zod";


export const postApiUserAuthOauthMobileNccAppleRedirectHeaderParamsSchema = z.object({ "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiUserAuthOauthMobileNccAppleRedirect200Schema = z.object({});

 /**
 * @description Not avaible
 */
export const postApiUserAuthOauthMobileNccAppleRedirect404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const postApiUserAuthOauthMobileNccAppleRedirectMutationResponseSchema = z.object({});
import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiTransportNccTeamRideCancelMutationRequest, PostApiTransportNccTeamRideCancelMutationResponse, PostApiTransportNccTeamRideCancelPathParams, PostApiTransportNccTeamRideCancelHeaderParams, PostApiTransportNccTeamRideCancel403 } from "../models/PostApiTransportNccteamRideCancel";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiTransportNccTeamRideCancelClient = typeof client<PostApiTransportNccTeamRideCancelMutationResponse, PostApiTransportNccTeamRideCancel403, PostApiTransportNccTeamRideCancelMutationRequest>;
type PostApiTransportNccTeamRideCancel = {
    data: PostApiTransportNccTeamRideCancelMutationResponse;
    error: PostApiTransportNccTeamRideCancel403;
    request: PostApiTransportNccTeamRideCancelMutationRequest;
    pathParams: PostApiTransportNccTeamRideCancelPathParams;
    queryParams: never;
    headerParams: PostApiTransportNccTeamRideCancelHeaderParams;
    response: PostApiTransportNccTeamRideCancelMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiTransportNccTeamRideCancelClient>[0]>;
        return: Awaited<ReturnType<PostApiTransportNccTeamRideCancelClient>>;
    };
};
/**
 * @description Cancels the ride and issues a refund (if applicable). Passed ride must be owned by calling User Team memmeber. Calling User must be Owner or Manager.Abort operation
 * @summary CancelNccTeamRide
 * @link /api/transport/ncc-team/ride/cancel
 */
export function usePostApiTransportNccTeamRideCancelHook(rideId: PostApiTransportNccTeamRideCancelPathParams["ride_id"], headers?: PostApiTransportNccTeamRideCancel["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiTransportNccTeamRideCancel["response"], PostApiTransportNccTeamRideCancel["error"], PostApiTransportNccTeamRideCancel["request"]>;
    client?: PostApiTransportNccTeamRideCancel["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiTransportNccTeamRideCancelRideIdHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiTransportNccTeamRideCancel["data"], PostApiTransportNccTeamRideCancel["error"], PostApiTransportNccTeamRideCancel["request"]>({
                method: "post",
                url: `/api/transport/ncc-team/ride/cancel`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiTransportVehicleAvailabilityMutationRequest, PostApiTransportVehicleAvailabilityMutationResponse, PostApiTransportVehicleAvailabilityHeaderParams, PostApiTransportVehicleAvailability403 } from "../models/PostApiTransportVehicleAvailability";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiTransportVehicleAvailabilityClient = typeof client<PostApiTransportVehicleAvailabilityMutationResponse, PostApiTransportVehicleAvailability403, PostApiTransportVehicleAvailabilityMutationRequest>;
type PostApiTransportVehicleAvailability = {
    data: PostApiTransportVehicleAvailabilityMutationResponse;
    error: PostApiTransportVehicleAvailability403;
    request: PostApiTransportVehicleAvailabilityMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiTransportVehicleAvailabilityHeaderParams;
    response: PostApiTransportVehicleAvailabilityMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiTransportVehicleAvailabilityClient>[0]>;
        return: Awaited<ReturnType<PostApiTransportVehicleAvailabilityClient>>;
    };
};
/**
 * @description Changes the Vehicle availability. Calling User must be Owner or Manager of the related NccTeamAbort operation
 * @summary ChangeVehicleAvailability
 * @link /api/transport/vehicle/availability
 */
export function usePostApiTransportVehicleAvailabilityHook(headers?: PostApiTransportVehicleAvailability["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiTransportVehicleAvailability["response"], PostApiTransportVehicleAvailability["error"], PostApiTransportVehicleAvailability["request"]>;
    client?: PostApiTransportVehicleAvailability["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiTransportVehicleAvailabilityHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiTransportVehicleAvailability["data"], PostApiTransportVehicleAvailability["error"], PostApiTransportVehicleAvailability["request"]>({
                method: "post",
                url: `/api/transport/vehicle/availability`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
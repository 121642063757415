import { z } from "zod";


export const postApiFeedbackReviewGetHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiFeedbackReviewGet200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const postApiFeedbackReviewGet403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiFeedbackReviewGetMutationRequestSchema = z.object({ "reviewable": z.string().describe("Must be one of <code>Rider</code>."), "reviewable_id": z.number() });

 /**
 * @description Success
 */
export const postApiFeedbackReviewGetMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
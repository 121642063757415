import { z } from "zod";


export const postApiBackofficeActivationVehicleToggleHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeActivationVehicleToggle200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiBackofficeActivationVehicleToggle403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiBackofficeActivationVehicleToggleMutationRequestSchema = z.object({ "id": z.number(), "active": z.boolean() });

 /**
 * @description Success
 */
export const postApiBackofficeActivationVehicleToggleMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const getApiEventViewLiveLocationLocationIdPathParamsSchema = z.object({ "location_id": z.number().describe("Location id to search for") });


export const getApiEventViewLiveLocationLocationIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiEventViewLiveLocationLocationId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const getApiEventViewLiveLocationLocationId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiEventViewLiveLocationLocationIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { z } from "zod";


export const postApiFeedbackReviewCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiFeedbackReviewCreate200Schema = z.string();

 /**
 * @description Not available
 */
export const postApiFeedbackReviewCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiFeedbackReviewCreateMutationRequestSchema = z.object({ "title": z.string().describe("Must not be greater than 128 characters."), "description": z.string(), "rating": z.number().describe("Must be between 1 and 10."), "reviewable": z.string().describe("Must be one of <code>Rider</code>."), "reviewable_id": z.number(), "reviewing": z.string().describe("Must be one of <code>Ride</code>.").optional(), "reviewing_id": z.number().optional() });

 /**
 * @description Success
 */
export const postApiFeedbackReviewCreateMutationResponseSchema = z.string();
import { z } from "zod";


export const postApiInvoiceViewfilteredHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiInvoiceViewfiltered200Schema = z.string();

 /**
 * @description Not available
 */
export const postApiInvoiceViewfiltered403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiInvoiceViewfilteredMutationRequestSchema = z.object({ "states": z.array(z.string()).describe("Must be one of <code>Actualized</code>, <code>Cancelled</code>, <code>Draft</code>, <code>Paid</code>, or <code>Refounded</code> Must be at least 1 characters. Must not be greater than 64 characters.") });

 /**
 * @description Success
 */
export const postApiInvoiceViewfilteredMutationResponseSchema = z.string();
import { z } from "zod";


export const postApiBackofficeInvoiceViewQueryParamsSchema = z.object({ "user_id": z.number().optional(), "party_id": z.number().optional(), "state": z.string().describe("Must be one of <code>Actualized</code>, <code>Cancelled</code>, <code>Draft</code>, <code>Paid</code>, or <code>Refounded</code>.").optional() }).optional();


export const postApiBackofficeInvoiceViewHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiBackofficeInvoiceView200Schema = z.string();

 /**
 * @description Not available
 */
export const postApiBackofficeInvoiceView403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const postApiBackofficeInvoiceViewMutationResponseSchema = z.string();
import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiNccTeamNccLocationEditMutationRequest, PostApiNccTeamNccLocationEditMutationResponse, PostApiNccTeamNccLocationEditHeaderParams, PostApiNccTeamNccLocationEdit403 } from "../models/PostApiNccteamNcclocationEdit";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiNccTeamNccLocationEditClient = typeof client<PostApiNccTeamNccLocationEditMutationResponse, PostApiNccTeamNccLocationEdit403, PostApiNccTeamNccLocationEditMutationRequest>;
type PostApiNccTeamNccLocationEdit = {
    data: PostApiNccTeamNccLocationEditMutationResponse;
    error: PostApiNccTeamNccLocationEdit403;
    request: PostApiNccTeamNccLocationEditMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiNccTeamNccLocationEditHeaderParams;
    response: PostApiNccTeamNccLocationEditMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiNccTeamNccLocationEditClient>[0]>;
        return: Awaited<ReturnType<PostApiNccTeamNccLocationEditClient>>;
    };
};
/**
 * @description Edites a NccLocation. Calling NccTeam must be Owner of the LocationAbort operation
 * @summary EditNccLocation
 * @link /api/ncc-team/ncc-location/edit
 */
export function usePostApiNccTeamNccLocationEditHook(headers?: PostApiNccTeamNccLocationEdit["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiNccTeamNccLocationEdit["response"], PostApiNccTeamNccLocationEdit["error"], PostApiNccTeamNccLocationEdit["request"]>;
    client?: PostApiNccTeamNccLocationEdit["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiNccTeamNccLocationEditHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiNccTeamNccLocationEdit["data"], PostApiNccTeamNccLocationEdit["error"], PostApiNccTeamNccLocationEdit["request"]>({
                method: "post",
                url: `/api/ncc-team/ncc-location/edit`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}
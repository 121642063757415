import { z } from "zod";


export const getApiTeamSubscriptionsCurrentTeamIdPathParamsSchema = z.object({ "team_id": z.number().describe("Team id to search for") });


export const getApiTeamSubscriptionsCurrentTeamIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiTeamSubscriptionsCurrentTeamId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const getApiTeamSubscriptionsCurrentTeamId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiTeamSubscriptionsCurrentTeamIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const getApiPartyInvitesIssuedPartyIdPathParamsSchema = z.object({ "party_id": z.number().describe("Party id to search issued invites for") });


export const getApiPartyInvitesIssuedPartyIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiPartyInvitesIssuedPartyId200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const getApiPartyInvitesIssuedPartyId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiPartyInvitesIssuedPartyIdQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { z } from "zod";


export const postApiTransportTierPriceVariantEditHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportTierPriceVariantEdit200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiTransportTierPriceVariantEdit403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportTierPriceVariantEditMutationRequestSchema = z.object({ "id": z.number(), "name": z.string().describe("Must not be greater than 32 characters."), "description": z.string().describe("Must not be greater than 100 characters."), "rules": z.string().describe("Must not be greater than 64 characters.").optional(), "public": z.boolean(), "extra_km_price": z.string().describe("KM price fee"), "extra_km_price_reason": z.string().describe("Must not be greater than 128 characters."), "ncc_team_id": z.number() });

 /**
 * @description Success
 */
export const postApiTransportTierPriceVariantEditMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
import { z } from "zod";


export const getApiEventViewPrivateLocationLocationIdStatePathParamsSchema = z.object({ "location_id": z.number().describe("location id to search for"), "state": z.string().describe("Optional parameter. optional event state name to search for") });


export const getApiEventViewPrivateLocationLocationIdStateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiEventViewPrivateLocationLocationIdState200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not available
 */
export const getApiEventViewPrivateLocationLocationIdState403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiEventViewPrivateLocationLocationIdStateQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
import { z } from "zod";


export const postApiUserUpdatePasswordHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiUserUpdatePassword200Schema = z.string();

 /**
 * @description Not available
 */
export const postApiUserUpdatePassword404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiUserUpdatePasswordMutationRequestSchema = z.object({ "old_password": z.string(), "password": z.string().describe("The value and <code>old_password</code> must be different.") });

 /**
 * @description Success
 */
export const postApiUserUpdatePasswordMutationResponseSchema = z.string();
import { z } from "zod";


export const postApiFeedbackReviewGetOwnHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiFeedbackReviewGetOwn200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });

 /**
 * @description Not avaible
 */
export const postApiFeedbackReviewGetOwn403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const postApiFeedbackReviewGetOwnMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional(), "data": z.object({}).describe("Data").optional() });
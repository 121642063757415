import { z } from "zod";


export const getApiNccTeamInvitesAcceptTokenPathParamsSchema = z.object({ "token": z.string().describe("Token id for finding the invite") });


export const getApiNccTeamInvitesAcceptTokenHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiNccTeamInvitesAcceptToken200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const getApiNccTeamInvitesAcceptToken403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiNccTeamInvitesAcceptTokenQueryResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
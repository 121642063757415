import { z } from "zod";


export const postApiInvoiceApplycouponInvoiceIdPathParamsSchema = z.object({ "invoice_id": z.number().describe("Invoice id to search for") });


export const postApiInvoiceApplycouponInvoiceIdHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiInvoiceApplycouponInvoiceId200Schema = z.string();

 /**
 * @description Not available
 */
export const postApiInvoiceApplycouponInvoiceId403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiInvoiceApplycouponInvoiceIdMutationRequestSchema = z.object({ "coupon": z.string().optional() });

 /**
 * @description Success
 */
export const postApiInvoiceApplycouponInvoiceIdMutationResponseSchema = z.string();
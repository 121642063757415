import { z } from "zod";


export const postApiTransportRiderCreateHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTransportRiderCreate200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not avaible
 */
export const postApiTransportRiderCreate403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTransportRiderCreateMutationRequestSchema = z.object({ "license_number": z.string().describe("Must not be greater than 16 characters."), "release_town_name": z.string().describe("Must not be greater than 64 characters."), "released_at": z.string().describe("Must be a valid date. Must be a date before <code>expires_at</code>."), "expires_at": z.string().describe("Must be a valid date. Must be a date after <code>today</code>.") });

 /**
 * @description Success
 */
export const postApiTransportRiderCreateMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
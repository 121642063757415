import type { IPagination } from "@/types";
import { z } from "zod";

export interface PagedRideTiers extends IPagination {
	data: RideTier[];
}

export interface RideTier {
	id: number;
	name: string;
	description: string;
	rules: string;
	activation_fee: string;
	max_seats?: number;
	max_distance: number;
	km_price: string;
	public: number;
	vehicle_type: VehicleType;
}

export interface VehicleType {
	id: number;
	name: string;
	title: string;
	created_at: string;
	updated_at: string;
	deleted_at: string;
}

export interface IDataDistanceTable extends IPagination {
	data: IDistanceData[];
}

export interface IDistanceData {
	id: number;
	name: string;
	description: string;
	rules: string;
	public: boolean;
	extra_km_price: string;
	extra_km_price_reason: string;
}

export interface IRateTypeTable<T> {
	dataRateTypeTable?: T;
	rideTiersPending: boolean;
	nccTeamId: number;
	rideTierId?: number;
	veichleRideId?: number;
	fetchNextPage: () => void;
}

export const RateSearchSchema = z.object({
	page: z.number().default(1),
});

import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";

export type Tab = { name: string; value: unknown; hook: string | React.ReactNode; component?: React.ReactNode };

/**
 * Renders a tab bar component with scrollable tabs.
 *
 * @component
 * @param {Object[]} tabs - An array of objects representing the tab data.
 * @param {string} tabs[].label - The unique label of the tab.
 * @param {string} tabs[].component - The title of the tab.
 * @returns {JSX.Element} The rendered tab bar component.
 *
 * @example
 * ```tsx
 * const App: React.FC = () => {
 *  const tabs = [
 *       { label: "tab1", component: <></> },
 *       { label: "tab2", component: <></> },
 *       { label: "tab3", component: <></> },
 *  ];
 *  const [activeTabName, setActiveTabName] = useState("tab1");
 *
 *  return (
 *       <TabBar tabs={tabs} activeTabName={activeTabName} onTabClick={setActiveTabName} />
 *  );
 * };
 * ```
 */
const TabBar = ({
	tabs,
	className,
	currentTab,
	renderTabContent,
	setCurrentTab,
}: {
	tabs: Tab[];
	className?: string;
	currentTab: Tab | undefined;
	renderTabContent?: boolean;
	setCurrentTab: (tab: Tab) => unknown;
}): JSX.Element => {
	return (
		<div className={cn({ inputs: ["relative w-full overflow-y-hidden overflow-x-auto ", className] })}>
			<div className="flex gap-4">
				{tabs.map(tab => {
					return (
						<Button
							key={tab.name + tab.value}
							variant={"ghost"}
							size={"sm"}
							onClick={() => setCurrentTab(tab)}
							className={cn({
								inputs: [
									"relative rounded-none pb-1 text-foreground-muted font-semibold text-md",
									typeof tab.hook === "string" &&
										"after:bg-foreground-muted after:h-0.5 after:w-full after:absolute after:top-full transition-all after:transition-all",
									typeof tab.hook === "string" &&
										tab.hook === currentTab?.hook &&
										"after:bg-accent text-accent after:h-1 after:-mt-0.5",
								],
							})}
						>
							{tab.hook}
						</Button>
					);
				})}
			</div>
			{currentTab && renderTabContent !== false ? (
				<div className="mt-8">{tabs.find(tab => tab.name === currentTab.name)?.component}</div>
			) : null}
		</div>
	);
};

export default TabBar;

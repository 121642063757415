import { z } from "zod";


export const getApiUserAuthOauthDriverRedirectPathParamsSchema = z.object({ "driver": z.string().describe("Socialite driver name to use") });


export const getApiUserAuthOauthDriverRedirectHeaderParamsSchema = z.object({ "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const getApiUserAuthOauthDriverRedirect200Schema = z.object({});

 /**
 * @description Not avaible
 */
export const getApiUserAuthOauthDriverRedirect404Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Success
 */
export const getApiUserAuthOauthDriverRedirectQueryResponseSchema = z.object({});
import { z } from "zod";


export const postApiPartyInvitesInviteHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiPartyInvitesInvite200Schema = z.string();

 /**
 * @description Not available
 */
export const postApiPartyInvitesInvite403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiPartyInvitesInviteMutationRequestSchema = z.object({ "id": z.number(), "email": z.string().email().describe("Must be a valid email address.") });

 /**
 * @description Success
 */
export const postApiPartyInvitesInviteMutationResponseSchema = z.string();
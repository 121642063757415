import { z } from "zod";


export const postApiTeamUserRoleRemoveHeaderParamsSchema = z.object({ "Authorization": z.string().optional(), "Content-Type": z.string().optional(), "Accept": z.string().optional() }).optional();

 /**
 * @description Success
 */
export const postApiTeamUserRoleRemove200Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });

 /**
 * @description Not available
 */
export const postApiTeamUserRoleRemove403Schema = z.object({ "status": z.string().optional(), "message": z.string().optional() });


export const postApiTeamUserRoleRemoveMutationRequestSchema = z.object({ "id": z.number(), "user_id": z.number(), "role": z.string().describe("Must be one of <code>Manager</code>, <code>Owner</code>, or <code>User</code> Must not be greater than 64 characters.") });

 /**
 * @description Success
 */
export const postApiTeamUserRoleRemoveMutationResponseSchema = z.object({ "status": z.string().optional(), "message": z.string().optional() });
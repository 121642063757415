import client from "../../templatesSDK/client";
import { useMutation } from "@tanstack/react-query";
import { useInvalidationForMutation } from "../../templatesSDK/useInvalidationForMutation";
import type { PostApiTransportVehicleCreateMutationRequest, PostApiTransportVehicleCreateMutationResponse, PostApiTransportVehicleCreateHeaderParams, PostApiTransportVehicleCreate403 } from "../models/PostApiTransportVehicleCreate";
import type { UseMutationOptions } from "@tanstack/react-query";

 type PostApiTransportVehicleCreateClient = typeof client<PostApiTransportVehicleCreateMutationResponse, PostApiTransportVehicleCreate403, PostApiTransportVehicleCreateMutationRequest>;
type PostApiTransportVehicleCreate = {
    data: PostApiTransportVehicleCreateMutationResponse;
    error: PostApiTransportVehicleCreate403;
    request: PostApiTransportVehicleCreateMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: PostApiTransportVehicleCreateHeaderParams;
    response: PostApiTransportVehicleCreateMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiTransportVehicleCreateClient>[0]>;
        return: Awaited<ReturnType<PostApiTransportVehicleCreateClient>>;
    };
};
/**
 * @description Creates a Vehicle and assigns it to calling Rider. Can only be executed by a riderAbort operation
 * @summary CreateVehicle
 * @link /api/transport/vehicle/create
 */
export function usePostApiTransportVehicleCreateHook(headers?: PostApiTransportVehicleCreate["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiTransportVehicleCreate["response"], PostApiTransportVehicleCreate["error"], PostApiTransportVehicleCreate["request"]>;
    client?: PostApiTransportVehicleCreate["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const invalidationOnSuccess = useInvalidationForMutation("usePostApiTransportVehicleCreateHook");
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiTransportVehicleCreate["data"], PostApiTransportVehicleCreate["error"], PostApiTransportVehicleCreate["request"]>({
                method: "post",
                url: `/api/transport/vehicle/create`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        onSuccess: (...args) => {
            if (invalidationOnSuccess)
                invalidationOnSuccess(...args);
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
        ...mutationOptions
    });
}